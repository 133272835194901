import styled from "@emotion/styled";

export const GridTitleStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;

  font-size: 0.75rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  padding: 2px;
`;
