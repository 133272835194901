import styled from "@emotion/styled";
import { TableCell, tableCellClasses } from "@mui/material";
import { Color } from "../../constants/colors";

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: "11px",
    lineHeight: "15px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14px",
    lineHeight: "16px",
  },
}));

export const StyledDangerCellContent = styled.span`
  color: ${Color.danger};
  font-weight: 500;
`;
