import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import { RootState } from "../../rootState";
import { EditApiAction } from "../../../api/ApiAction";
import { MarginNotes } from "../types/marginNotes";
import { fetchMarginNotes } from "./fetchMarginNotes";

export const editMarginNotes = createAsyncThunk<
  void,
  MarginNotes,
  { state: RootState }
>(
  "services/getMarginNotes",
  async ({ id, text, rate, active, hidden }: MarginNotes, thunkAPI) => {
    const s = thunkAPI.getState().services.selection;
    await endpoints.directory.editMarginNotes(
      {
        action: EditApiAction.editNotes,
        id,
        text,
        rate,
        active,
        hidden,
      },
      thunkAPI.signal,
    );

    if (s.operator && s.country) {
      thunkAPI.dispatch(
        fetchMarginNotes({ operator: s.operator.id, country: s.country.id }),
      );
    }
  },
);
