import { AssociateType } from "../../filter/types/AssociateType";

export const labelForType = (type: AssociateType, isFilter: boolean): string => {
  switch (type) {
    case AssociateType.hotels:
      return isFilter ? "Фильтр по курортам" : "Выберите отель из списка";
    case AssociateType.regions:
      return isFilter ? "Фильтр" : "Выберите регион из списка";
    case AssociateType.pansions:
      return isFilter ? "Фильтр по отелям" : "Выберите пансион из списка";
    case AssociateType.roomTypes:
      return isFilter ? "Фильтр по отелям" : "Выберите тип номера из списка";
    case AssociateType.tours:
      return isFilter ? "Фильтр" : "Выберите тур из списка";
    case AssociateType.createTours:
      return isFilter ? "Фильтр" : "Выберите курорт из списка";
    default:
      return isFilter ? "Фильтр" : "Выберите из списка";
  }
};
