import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiAction } from "../../../api/ApiAction";
import { endpoints } from "../../../api/endpoints";
import { IHotelExtended } from "../../../api/types/directory/IHotel";
import { AppDispatch } from "../../appDispatch";
import { RootState } from "../../rootState";
import { IAssociateParams } from "./types/IAssociateParams";

export const fetchAssociateHotels = createAsyncThunk<
  IHotelExtended[],
  IAssociateParams,
  { state: RootState; dispatch: AppDispatch }
>("associate/fetchHotels", async (params, thunkAPI) => {
  const result = await endpoints.directory.get(
    {
      action: ApiAction.getHotels,
      operator: params.operator,
      country: params.country,
      resort_id: params.resort,
      region_id: params.region_id,
      tour_id: params.tour_id,
      full: params.operator === 10,
    },
    thunkAPI.signal,
  );

  return result as IHotelExtended[];
});
