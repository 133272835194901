import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiAction } from "../../../api/ApiAction";
import { endpoints } from "../../../api/endpoints";
import { IRegion } from "../../../api/types/directory/IRegion";
import { IAssociateParams } from "./types/IAssociateParams";

export const fetchAssociateRegions = createAsyncThunk<IRegion[], IAssociateParams>(
  "associate/regions",
  async (params, thunkAPI) => {
    const result = await endpoints.directory.get(
      {
        action: ApiAction.getRegions,
        operator: params.operator,
        country: params.country,
      },
      thunkAPI.signal,
    );
    return result as IRegion[];
  },
);
