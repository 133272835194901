import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import { IJobStats } from "../../../api/types/jobs/IJobStats";
import { JobAction } from "../../../api/types/jobs/JobAction";

export const fetchJobStats = createAsyncThunk<IJobStats[], number>(
  "jobStats/fetch",
  async (param, thunkAPI) => {
    const response = await endpoints.job.getStats(
      {
        action: JobAction.getJobStats,
        id: param,
      },
      thunkAPI.signal,
    );
    if (response.error) throw new Error(`Не удалось получить статистику. ${response.error}`);
    if (!response.stats) throw new Error(`Неверный ответ от сервера`);
    return response.stats;
  },
);
