import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiAction } from "../../../api/ApiAction";
import { endpoints } from "../../../api/endpoints";
import { IDirectoryResponse } from "../../../api/types/directory/IResponse";
import { IJobInfo } from "../../../api/types/jobs/IJobInfo";
import { IJobStats } from "../../../api/types/jobs/IJobStats";
import { JobAction } from "../../../api/types/jobs/JobAction";
import { JobStatus } from "../../../api/types/jobs/JobStatus";

interface IDirectoryJobInfoResponse extends IDirectoryResponse {
  stats?: IJobStats[]
}

export const fetchDirectoryForJobInfo = createAsyncThunk<IDirectoryJobInfoResponse, IJobInfo>(
  "jobs/fetchDirectoryForJobInfo",
  async (params, thunkAPI) => {
    const departureCities = await endpoints.directory.get(
      {
        action: ApiAction.getDepartureCities,
        operator: params.operator_id,
      },
      thunkAPI.signal,
    );
    const destinationCountries = await endpoints.directory.get(
      {
        action: ApiAction.getDestinationCountries,
        operator: params.operator_id,
        departure: params.city_from,
      },
      thunkAPI.signal,
    );
    const destinationCities = await endpoints.directory.get(
      {
        action: ApiAction.getDestinationCities,
        operator: params.operator_id,
        departure: params.city_from,
        country: params.country,
      },
      thunkAPI.signal,
    );
    const resorts = await endpoints.directory.get(
      {
        action: ApiAction.getResorts,
        operator: params.operator_id,
        departure: params.city_from,
        country: params.country,
      },
      thunkAPI.signal,
    );
    const tourTypes = await endpoints.directory.get(
      {
        action: ApiAction.getTourTypes,
        operator: params.operator_id,
        departure: params.city_from,
        country: params.country,
      },
      thunkAPI.signal,
    );

    const hotels = await endpoints.directory.get(
      {
        action: ApiAction.getHotels,
        operator: params.operator_id,
        departure: params.city_from,
        country: params.country,
      },
      thunkAPI.signal,
    );

    // If we fail to fetch stats we don't need to fail the whole thunk. We can continue as it is.
    const st = parseInt(params.status, 10);
    const isStopped = st === JobStatus.stopped || st == JobStatus.finished;
    let stats: IJobStats[] | undefined;
    if (isStopped) {
      try {
        const response = await endpoints.job.getStats(
          {
            action: JobAction.getJobStats,
            id: params.id,
          },
          thunkAPI.signal,
        );
        stats = response.stats ?? [];
      } catch (e) {
        console.log("Failed to fetch stats for job", params.id);
      }
    }

    return {
      departureCities,
      destinationCountries,
      destinationCities,
      hotels,
      resorts,
      tourTypes,
      stats,
    } as IDirectoryJobInfoResponse;
  },
);
