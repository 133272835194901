import { IDirectoryResponse } from "../../../../api/types/directory/IResponse";

export const jobInfoLoadedKeys: (keyof IDirectoryResponse)[] = [
  "departureCities",
  "destinationCities",
  "destinationCountries",
  "resorts",
  "tourTypes",
  "hotels",
];
