import { ISelectItem } from "../../../../components/selectList/types/ISelectItem";
import { IAssociateState } from "../../types/IAssociateState";
import { getSuggestedForItems } from "../../utils/getSuggestedForItems";

export const updateSuggestedOnFetchComplete = (
  state: IAssociateState,
  operatorId: number,
  items: ISelectItem[],
) => {
  if (state.tezTargets.length > 0) {
    const isTez = operatorId === 10;
    const selected = isTez ? state.tezTargets : state.associated[operatorId] ?? [];
    state.suggested[operatorId] = getSuggestedForItems(state.tezTargets, items, selected);
  }
};
