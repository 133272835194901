import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IActionErrorInfo } from "../../jobs/types/IActionErrorInfo";
import { postRoomChanges } from "../thunks/postRoomChanges";
import { IAssociateState } from "../types/IAssociateState";

export const addPostRoomChanges = (builder: ActionReducerMapBuilder<IAssociateState>) => {
  builder.addCase(postRoomChanges.pending, (state) => {
    state.loadingKeys = [...state.loadingKeys, `room`];
  });
  builder.addCase(postRoomChanges.rejected, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `room`);

    const error: IActionErrorInfo = {
      id: Date.now(),
      message: `Не удалось сохранить примечание "${action.meta.arg.note}"!"`,
    };
    state.errors = [...state.errors, error];
  });
  builder.addCase(postRoomChanges.fulfilled, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `room`);
    const tezRooms = state.rooms[10];
    if (tezRooms) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.rooms[10] = tezRooms.map((room) => {
        if (action.payload.localIds.includes(room.id)) {
          return { ...room, note: action.payload.note };
        }
        return { ...room };
      });
    }
    const targets = [...state.tezTargets];
    state.tezTargets = targets.map((room) => ({ ...room, note: action.payload.note }));
  });
};
