import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiAction } from "../../../api/ApiAction";
import { endpoints } from "../../../api/endpoints";
import { IHotelExtended } from "../../../api/types/directory/IHotel";
import { AppDispatch } from "../../appDispatch";
import { IAssociateParams } from "../../associate/thunks/types/IAssociateParams";
import { RootState } from "../../rootState";

export const fetchServicesHotels = createAsyncThunk<
  IHotelExtended[],
  IAssociateParams,
  { state: RootState; dispatch: AppDispatch }
>("services/fetchHotels", async (params, thunkAPI) => {
  const result = await endpoints.directory.get(
    {
      action: ApiAction.getHotels,
      operator: params.operator,
      country: params.country,
      region_id: params.region_id,
      tour_id: params.tour_id,
      full: false,
    },
    thunkAPI.signal,
  );

  return result as IHotelExtended[];
});
