import { SnackbarProvider } from "notistack";
import React, { useEffect } from "react";
import "./App.css";
import { ModalProvider } from "./components/modal/ModalProvider";
import { SnackBarManager } from "./components/snackbars/SnackBarManager";
import { AppContainerStyled } from "./components/styled/AppContainer.styled";
import { Header } from "./components/header/Header";
import { AppVersionStyled } from "./components/styled/AppVersion.styled";
import { BodyContainerStyled } from "./components/styled/BodyContainer.styled";
import { Route, Routes } from "react-router-dom";
import { PagePath } from "./navigation/routes";
import { Users } from "./pages/admin/Users";
import { Login } from "./pages/auth/Login";
import { AssociateFields } from "./pages/data/AssociateFields";
import { ImportAndManageData } from "./pages/data/ImportAndManageData";
import { ManageTransfers } from "./pages/data/ManageTransfers";
import { GrossEditor } from "./pages/data/services/GrossEditor/GrossEditor";
import { MarginEditor } from "./pages/data/services/MarginEditor";
import { Initial } from "./pages/Initial";
import { CreateReportTemplate } from "./pages/reports/CreateReportTemplate";
import { ReportResults } from "./pages/reports/ReportResults";
import { ReportTemplates } from "./pages/reports/ReportTemplates";
import { Templates } from "./pages/templates/Templates";
import { CreateTemplate } from "./pages/templates/CreateTemplate";
import { NotFound } from "./pages/NotFound";
import { Backdrop, CircularProgress, ThemeProvider } from "@mui/material";
import {
  addAuthStatusSubscriber,
  addUserStatusSubscriber,
  removeAuthStatusSubscriber,
  removeUserStatusSubscriber,
} from "./publishers/AuthStatusPublisher";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { refreshUserInfo } from "./store/user/thunks/refreshUserInfo";
import { triggerUserLogout } from "./store/user/userSlice";
import { theme } from "./theme";

console.log(`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`);

function App() {
  const dispatch = useAppDispatch();

  const loading = useAppSelector((state) => state.app.loading);
  const isLoggedIn = useAppSelector((s) => s.user.isLoggedIn);

  useEffect(() => {
    const triggerLogout = () => dispatch(triggerUserLogout());
    const updateUserInfo = () => dispatch(refreshUserInfo());
    addAuthStatusSubscriber(triggerLogout);
    addUserStatusSubscriber(updateUserInfo);
    return () => {
      removeAuthStatusSubscriber(triggerLogout);
      removeUserStatusSubscriber(updateUserInfo);
    };
  }, []);

  return (
    <SnackbarProvider maxSnack={5} hideIconVariant={false}>
      <ThemeProvider theme={theme}>
        <AppContainerStyled>
          {isLoggedIn ? (
            <>
              <Header />
              <BodyContainerStyled>
                <Routes>
                  <Route path="/" element={<Initial />} />

                  <Route path={PagePath.parsing.jobs} element={<Templates mode={"jobs"} />} />
                  <Route
                    path={PagePath.parsing.imported}
                    element={<Templates mode={"imported"} />}
                  />
                  <Route
                    path={PagePath.parsing.templates}
                    element={<Templates mode={"templates"} />}
                  />
                  <Route path={PagePath.parsing.createTemplate} element={<CreateTemplate />} />

                  <Route path={PagePath.reports.results} element={<ReportResults />} />
                  <Route path={PagePath.reports.templates} element={<ReportTemplates />} />
                  <Route
                    path={PagePath.reports.createTemplate}
                    element={<CreateReportTemplate />}
                  />

                  <Route
                    path={PagePath.dataImport.association.root}
                    element={<AssociateFields />}
                  />
                  <Route path={PagePath.dataImport.manage} element={<ImportAndManageData />} />
                  <Route path={PagePath.dataImport.transfers} element={<ManageTransfers />} />
                  <Route path={PagePath.dataImport.gross} element={<GrossEditor />} />
                  <Route path={PagePath.dataImport.margin} element={<MarginEditor />} />

                  <Route path={PagePath.login} element={<Login />} />
                  <Route path={PagePath.users} element={<Users />} />
                  <Route element={<NotFound />} />
                </Routes>
              </BodyContainerStyled>
            </>
          ) : (
            <Login />
          )}

          <AppVersionStyled>Ver. {process.env.REACT_APP_VERSION}</AppVersionStyled>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
            onClick={() => console.log("backdrop clicked")}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <ModalProvider />
          <SnackBarManager />
        </AppContainerStyled>
      </ThemeProvider>
    </SnackbarProvider>
  );
}

export default App;
