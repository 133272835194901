import { Button } from "@mui/material";
import React from "react";
import { IoApps, IoAppsOutline } from "react-icons/io5";
import { DateObject } from "react-multi-date-picker";
import { GridBlockStyled } from "./styled/GridBlock.styled";
import { GridCellStyled } from "./styled/GridCell.styled";
import { GridColumnStyled } from "./styled/GridColumn.styled";
import { GridPickerContainerStyled } from "./styled/GridPickerContainer.styled";
import { GridTitleStyled } from "./styled/GridTitle.styled";
import { NightType } from "./types/NightType";
import { dateToString } from "./utils/dateToString";
import { hasCurrentDate } from "./utils/hasCurrentDate";
import { hasWarning } from "./utils/hasWarning";

interface Props {
  nights: NightType[]
  dates: DateObject[]
  previousData?: Record<string, NightType[]>
  label?: string
  selected: Record<string, NightType[]>
  onChange: (val: Record<string, NightType[]>) => void
}

export const GridPicker = ({ nights, dates, previousData, label, selected, onChange }: Props) => {
  const selectAll = () => {
    const updatedSelected: Record<string, NightType[]> = {};
    for (const d of dates) {
      const dateKey = d.toDate().toLocaleDateString("ru", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      updatedSelected[dateKey] = [...nights];
    }

    onChange(updatedSelected);
  };
  const clearAll = () => {
    onChange({});
  };

  const toggleSelection = (nightRow: NightType, dateItem: string) => {
    const nights = selected[dateItem];
    const updatedSelected = { ...selected };
    console.log("updatedSelected", updatedSelected);
    if (!nights) {
      updatedSelected[dateItem] = [nightRow];
      onChange(updatedSelected);
    } else {
      const hasNight = nights.includes(nightRow);
      updatedSelected[dateItem] = hasNight
        ? nights.filter((di) => di !== nightRow)
        : [...nights, nightRow];
      onChange(updatedSelected);
    }
  };

  return (
    <>
      {label ? <GridTitleStyled>{label}</GridTitleStyled> : null}
      <GridPickerContainerStyled>
        <GridColumnStyled>
          <GridCellStyled>Ночей</GridCellStyled>
          {nights.map((itm, index) => (
            <GridCellStyled key={`night_cell_${index}`}>{itm}</GridCellStyled>
          ))}
        </GridColumnStyled>
        {dates.map((d, index) => {
          const dateKey = d.toDate().toLocaleDateString("ru", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });
          return (
            <GridColumnStyled key={`column${d}_${index}`}>
              <GridCellStyled>{dateToString(d)}</GridCellStyled>
              {nights.map((itm, index) => (
                <GridCellStyled
                  selectable={true}
                  warning={hasWarning(itm, dateKey, previousData)}
                  selected={hasCurrentDate(selected, itm, dateKey)}
                  key={`cell_${dateKey}_${index}`}
                  onClick={() => toggleSelection(itm, dateKey)}
                />
              ))}
            </GridColumnStyled>
          );
        })}
      </GridPickerContainerStyled>
      <GridBlockStyled>
        <Button variant={"text"} fullWidth startIcon={<IoAppsOutline />} onClick={clearAll}>
          Очистить все
        </Button>
        <Button variant={"text"} fullWidth startIcon={<IoApps />} onClick={selectAll}>
          Выбрать все
        </Button>
      </GridBlockStyled>
    </>
  );
};
