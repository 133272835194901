import { Autocomplete, Button, TextField } from "@mui/material";
import React from "react";
import { MdClear } from "react-icons/md";
import { useSelector } from "react-redux";
import { FilterRowStyled } from "../../../../../components/filter/styled/FilterRow.styled";
import { inputStyleVariant } from "../../../../../constants/interface";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { RootState } from "../../../../../store/rootState";
import {
  setServiceDestinationCountry,
  setServiceOperator,
} from "../../../../../store/services/servicesSlice";

export const MarginNoteFilter = () => {
  const dispatch = useAppDispatch();

  const operator = useAppSelector((s) => s.services.selection.operator);
  const operators = useSelector(
    (state: RootState) => state.directory.operators ?? [],
  );
  const country = useAppSelector((s) => s.services.selection.country);
  const countries = useAppSelector((s) => s.services.countries);

  const loading = useAppSelector((s) => s.services.loadingKeys);

  const resetFilter = () => {
    dispatch(setServiceDestinationCountry(null));
    dispatch(setServiceOperator(null));
  };

  return (
    <FilterRowStyled>
      <Autocomplete
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={inputStyleVariant}
            label="Оператор"
            placeholder="Оператор"
            required
          />
        )}
        fullWidth={true}
        isOptionEqualToValue={(lhs, rhs) => lhs.id === rhs.id}
        onChange={(e, val) => {
          dispatch(setServiceOperator(val));
        }}
        value={operator}
        options={operators}
      />

      <Autocomplete
        loading={loading.includes("countries")}
        disabled={operator === null}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={inputStyleVariant}
            label="Страна"
            placeholder="Странf"
            required
          />
        )}
        fullWidth={true}
        isOptionEqualToValue={(lhs, rhs) => lhs.id === rhs.id}
        onChange={(e, val) => {
          dispatch(setServiceDestinationCountry(val));
        }}
        value={country}
        options={countries}
      />
      <Button
        variant={"outlined"}
        onClick={resetFilter}
        disabled={!operator && !country}
      >
        <MdClear />
      </Button>
    </FilterRowStyled>
  );
};
