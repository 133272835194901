import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IDirectoryState } from "../directorySlice";
import { fetchTourTypes } from "../thunks/fetchTourTypes";

export const addFetchTourTypes = (builder: ActionReducerMapBuilder<IDirectoryState>) => {
  builder.addCase(fetchTourTypes.pending, (state) => {
    state.loadingKeys = [...state.loadingKeys, "tourTypes"];
  });
  builder.addCase(fetchTourTypes.rejected, (state) => {
    state.loadingKeys = [...state.loadingKeys.filter((i) => i !== "tourTypes")];
  });
  builder.addCase(fetchTourTypes.fulfilled, (state, action) => {
    state.tourTypes = [...action.payload];
    state.loadingKeys = [...state.loadingKeys.filter((i) => i !== "tourTypes")];
  });
};
