import React from "react";
import {
  IoFileTrayStackedOutline,
  IoLogOutOutline,
  IoNewspaperOutline,
  IoPeopleOutline,
} from "react-icons/io5";
import { Route, Routes } from "react-router-dom";
import { PagePath } from "../../navigation/routes";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { logoutUser } from "../../store/user/thunks/logoutUser";
import { HeaderItem, LogoutHeaderItem, HeaderSeparator } from "./HeaderItem";
import { HeaderRowStyled, HeaderStyled, HeaderSubPlaceholder } from "./styled/Header.styled";
import { DataImportSubNav } from "./subNav/DataImportSubNav";
import { ParsingSubNav } from "./subNav/ParsingSubNav";
import { ReportsSubNav } from "./subNav/ReportsSubNav";

export function Header() {
  const dispatch = useAppDispatch();
  const isAdmin = useAppSelector((s) => s.user.info?.type === "admin");

  return (
    <HeaderStyled>
      <HeaderRowStyled>
        <HeaderItem path={PagePath.parsing.jobs}>
          <IoNewspaperOutline />
          Сбор данных
        </HeaderItem>

        <HeaderItem path={PagePath.reports.results} notReady={false}>
          <IoFileTrayStackedOutline />
          Отчеты
        </HeaderItem>
        <HeaderItem path={PagePath.dataImport.association.hotels}>
          <IoFileTrayStackedOutline />
          База данных
        </HeaderItem>
        {isAdmin ? (
          <>
            <HeaderSeparator />
            <HeaderItem path={PagePath.users}>
              <IoPeopleOutline />
              Пользователи
            </HeaderItem>
          </>
        ) : null}
        <HeaderSeparator />
        <LogoutHeaderItem path={PagePath.login} onClick={() => dispatch(logoutUser())}>
          <IoLogOutOutline />
          Выйти
        </LogoutHeaderItem>
      </HeaderRowStyled>

      <Routes>
        <Route path={PagePath.parsing.root} element={<ParsingSubNav />} />
        <Route path={PagePath.reports.root} element={<ReportsSubNav />} />
        <Route path={PagePath.dataImport.root} element={<DataImportSubNav />} />
        <Route path={"/*"} element={<HeaderSubPlaceholder />} />
      </Routes>
    </HeaderStyled>
  );
}
