import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import { IEditJobParams } from "../../../api/types/jobs/IEditJobParams";
import { IJobResponse } from "../../../api/types/jobs/IJobResponse";
import { AppDispatch } from "../../appDispatch";

export const editJob = createAsyncThunk<IJobResponse, IEditJobParams, { dispatch: AppDispatch }>(
  "jobs/editJob",
  async (params, thunkAPI) => {
    const response = await endpoints.job.edit({ ...params }, thunkAPI.signal);
    if (response.error) throw response.error;
    if (response.status === "OK") return response;
    else throw new Error(`Invalid response from the server. ${JSON.stringify(response, null, 2)}`);
  },
);
