import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AssociateType } from "../../../components/filter/types/AssociateType";
import { fetchAssociateDestinationCities } from "../thunks/fetchAssociateDestinationCities";
import { IAssociateState } from "../types/IAssociateState";
import { selectMatchingItems } from "../utils/selectMatchingItems";
import { updateSuggestedOnFetchComplete } from "./utils/updateSuggestedOnFetchComplete";

export const addFetchAssociateDestinationCities = (
  builder: ActionReducerMapBuilder<IAssociateState>,
) => {
  builder.addCase(fetchAssociateDestinationCities.pending, (state, action) => {
    state.loadingKeys = [...state.loadingKeys, `${action.meta.arg.operator}`];
  });
  builder.addCase(fetchAssociateDestinationCities.rejected, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter(
      (itm) => itm !== `${action.meta.arg.operator}`,
    );
  });
  builder.addCase(
    fetchAssociateDestinationCities.fulfilled,
    (state, action) => {
      state.loadingKeys = state.loadingKeys.filter(
        (itm) => itm !== `${action.meta.arg.operator}`,
      );
      state.destinationCities[action.meta.arg.operator] = [...action.payload];

      if (action.meta.arg.noSuggestions !== true) {
        selectMatchingItems(
          state.operators.map((itm) => itm.id),
          state,
          state.tezTargets,
          action.meta.arg.operator,
          AssociateType.regions,
        );
        updateSuggestedOnFetchComplete(
          state,
          action.meta.arg.operator,
          action.payload,
        );
      }
    },
  );
};
