import { JobStatus } from "../../../api/types/jobs/JobStatus";
import { restartJob } from "../thunks/restartJob";
import { IActionErrorInfo } from "../types/IActionErrorInfo";
import { JobsExtraBuilder } from "./types/jobsExtraBuilder";

export const addRestartJob = (builder: JobsExtraBuilder) => {
  builder.addCase(restartJob.pending, (state, action) => {
    state.loadingJobKeys = [...state.loadingJobKeys, action.meta.arg.id];
  });
  builder.addCase(restartJob.rejected, (state, action) => {
    if (state.loadingJobKeys) {
      state.loadingJobKeys = state.loadingJobKeys.filter((id) => id !== action.meta.arg.id);
    }

    const error: IActionErrorInfo = {
      id: Date.now(),
      message: `Ошибка перезапуска задачи ID: ${action.meta.arg}!: ${action.error.message}`,
    };
    state.jobErrors = state.jobErrors ? [...state.jobErrors, error] : [error];
  });

  builder.addCase(restartJob.fulfilled, (state, action) => {
    if (state.loadingJobKeys) {
      state.loadingJobKeys = state.loadingJobKeys.filter((id) => id !== action.meta.arg.id);
    }
    state.allJobs = state.allJobs.map((j) => {
      if (j.id == action.meta.arg.id) {
        return { ...j, status: `${JobStatus.pending}` };
      }
      return j;
    });
  });
};
