import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchMarginNotes } from "../thunks/fetchMarginNotes";
import { IServicesState } from "../types/IServicesState";

export const MARGIN_NOTES_KEY = "marginNotes";

export const addFetchMarginNotes = (
  builder: ActionReducerMapBuilder<IServicesState>,
) => {
  builder.addCase(fetchMarginNotes.pending, (state) => {
    state.loadingKeys = [...state.loadingKeys, MARGIN_NOTES_KEY];
  });
  builder.addCase(fetchMarginNotes.fulfilled, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== MARGIN_NOTES_KEY);
    state.marginNotes = action.payload;
  });
  builder.addCase(fetchMarginNotes.rejected, (state) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== MARGIN_NOTES_KEY);
  });
};
