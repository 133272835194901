import { IconButton } from "@mui/material";
import React from "react";
import {
  MdClear,
  MdContentCopy,
  MdCreate,
  MdLaunch,
  MdListAlt,
} from "react-icons/md";
import { endpoints } from "../../../api/endpoints";
import { IReportInfo } from "../../../api/types/reports/IReportInfo";
import { ReportAction } from "../../../api/types/reports/ReportAction";
import { ControlButtonStyled } from "../../../components/styled/ControlButton.styled";
import { ControlButtonContainerStyled } from "../../../components/styled/ControlButtonContainer.styled";
import { useAppDispatch } from "../../../store/hooks";
import { showAlert, showModal } from "../../../store/modals/modalsSlice";

interface Props {
  disabled: boolean;
  isStopped: boolean;
  isTemplate: boolean;
  info: IReportInfo;
}

export const ReportRowControlButtons = ({
  disabled,
  isStopped,
  isTemplate,
  info,
}: Props) => {
  const dispatch = useAppDispatch();

  const copyTemplate = () => {
    dispatch(
      showModal({
        type: "clone",
        info,
        id: info.id,
        kind: "report",
      }),
    );
  };

  const edit = () => {
    dispatch(
      showModal({
        type: "edit",
        info,
        id: info.id,
        kind: "report",
      }),
    );
  };

  const remove = () => {
    const kind = isTemplate ? "Шаблон" : "Отчет";
    const kindTitle = kind.toLowerCase();
    dispatch(
      showAlert({
        title: `Удалить ${kindTitle}?`,
        message: `${kind} "${info.name}" и все связанные данные будут удалены. Это действие нельзя будет отменить.`,
        id: info.id,
        actions: [
          { title: "Отмена", kind: "standard" },
          {
            title: "Удалить",
            kind: "danger",
            onPress: {
              action: ReportAction.deleteReport,
              params: {
                action: ReportAction.deleteReport,
                id: info.id,
              },
            },
          },
        ],
      }),
    );
  };

  return (
    <ControlButtonContainerStyled>
      {isStopped && isTemplate ? (
        <IconButton
          href={endpoints.report.getGenerateReportLink({
            action: ReportAction.generateReport,
            id: info.id,
          })}
          size={"small"}
          color={"primary"}
          disabled={disabled}
          title={"Сгенерировать отчет"}
        >
          <MdLaunch />
        </IconButton>
      ) : null}

      {isTemplate ? null : (
        <ControlButtonStyled
          title={"Скачать отчет"}
          onClick={() => null}
          disabled={disabled}
        >
          <MdListAlt />
        </ControlButtonStyled>
      )}

      {isTemplate ? (
        <ControlButtonStyled
          title={"Клонировать"}
          onClick={() => copyTemplate()}
          disabled={disabled}
        >
          <MdContentCopy />
        </ControlButtonStyled>
      ) : null}
      {isTemplate ? (
        <ControlButtonStyled
          title={"Редактировать"}
          onClick={() => edit()}
          disabled={disabled}
        >
          <MdCreate />
        </ControlButtonStyled>
      ) : null}
      <ControlButtonStyled
        variant={"danger"}
        title={"Удалить"}
        disabled={disabled}
        onClick={() => remove()}
      >
        <MdClear />
      </ControlButtonStyled>
    </ControlButtonContainerStyled>
  );
};
