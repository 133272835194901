import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import { IJobResponse } from "../../../api/types/jobs/IJobResponse";
import { JobAction } from "../../../api/types/jobs/JobAction";

export const resumeJob = createAsyncThunk<IJobResponse, number>(
  "jobs/resume",
  async (param, thunkAPI) => {
    const response = await endpoints.job.start(
      { action: JobAction.startJob, id: param },
      thunkAPI.signal,
    );
    if (response.error) throw response.error;
    if (response.status === "OK") return response;
    else throw new Error(`Invalid response from the server. ${JSON.stringify(response, null, 2)}`);
  },
);
