import { ApiAction } from "../ApiAction";
import { IDirectoryResponse } from "../types/directory/IResponse";

const normalizeApiAction = (type: ApiAction): keyof IDirectoryResponse => {
  const clean = type.slice(3);
  return (clean.charAt(0).toLowerCase() +
    clean.slice(1)) as keyof IDirectoryResponse;
};

export const apiActionToResultKey = (
  type: ApiAction,
): keyof IDirectoryResponse => {
  if (type === ApiAction.getCountries) {
    return normalizeApiAction(ApiAction.getDestinationCountries);
  }
  if (type === ApiAction.getToursByTez) {
    return normalizeApiAction(ApiAction.getTourFilters);
  }
  return normalizeApiAction(type);
};
