export enum PageSection {
  root = "/",
  parsing = "/parsing",
  reports = "/reports",
  dataImport = "/data-import",
  login = "/login",
  all = "/*",
}

export enum PageSlug {
  jobs = "/jobs",
  imported = "/imported",
  templates = "/templates",
  createTemplate = "/create-template",
  associations = "/associations",
  associateHotels = "/hotels",
  associatePansions = "/pansions",
  associateRoomTypes = "/roomTypes",
  associateRegions = "/associateRegions",
  associateTours = "/associateTours",
  associateCities = "/associateCities",
  gross = "/gross",
  margin = "/margin",
  manage = "/manage",
  transfers = "/transfers",
}

export const PagePath = {
  reports: {
    root: `${PageSection.reports}/:page`,
    results: `${PageSection.reports}${PageSlug.jobs}`,
    templates: `${PageSection.reports}${PageSlug.templates}`,
    createTemplate: `${PageSection.reports}${PageSlug.createTemplate}`,
  },
  parsing: {
    root: `${PageSection.parsing}/:page`,
    jobs: `${PageSection.parsing}${PageSlug.jobs}`,
    imported: `${PageSection.parsing}${PageSlug.imported}`,
    templates: `${PageSection.parsing}${PageSlug.templates}`,
    createTemplate: `${PageSection.parsing}${PageSlug.createTemplate}`,
  },
  dataImport: {
    root: `${PageSection.dataImport}/:page/*`,
    association: {
      root: `${PageSection.dataImport}${PageSlug.associations}/:type`,
      hotels: `${PageSection.dataImport}${PageSlug.associations}${PageSlug.associateHotels}`,
      pansions: `${PageSection.dataImport}${PageSlug.associations}${PageSlug.associatePansions}`,
      roomTypes: `${PageSection.dataImport}${PageSlug.associations}${PageSlug.associateRoomTypes}`,
      regions: `${PageSection.dataImport}${PageSlug.associations}${PageSlug.associateRegions}`,
      tours: `${PageSection.dataImport}${PageSlug.associations}${PageSlug.associateTours}`,
      cities: `${PageSection.dataImport}${PageSlug.associations}${PageSlug.associateCities}`,
    },
    manage: `${PageSection.dataImport}${PageSlug.manage}`,
    transfers: `${PageSection.dataImport}${PageSlug.transfers}`,
    gross: `${PageSection.dataImport}${PageSlug.gross}`,
    margin: `${PageSection.dataImport}${PageSlug.margin}`,
  },
  users: "/users",
  login: "/login",
};
