import { createSlice } from "@reduxjs/toolkit";
import { IDepartureCity } from "../../api/types/directory/IDepartureCity";
import { IDestinationCountry } from "../../api/types/directory/IDestinationCountry";
import { IDirectoryResponse } from "../../api/types/directory/IResponse";
import { createJobTemplate } from "../jobs/thunks/createJobTemplate";
import { createReportTemplate } from "../reports/thunks/createReportTemplate";
import { addFetchCurrency } from "./extraReducers/addFetchCurrency";
import { addFetchDepartureCity } from "./extraReducers/addFetchDepartureCity";
import { addFetchDestinationCities } from "./extraReducers/addFetchDestinationCities";
import { addFetchDestinationCountries } from "./extraReducers/addFetchDestinationCountries";
import { addFetchDestinationResorts } from "./extraReducers/addFetchDestinationResorts";
import { addFetchDirectoryForJobInfo } from "./extraReducers/addFetchDirectoryForJobInfo";
import { addFetchHotels } from "./extraReducers/addFetchHotels";
import { addFetchOperators } from "./extraReducers/addFetchOperators";
import { addFetchTourFilters } from "./extraReducers/addFetchTourFilters";
import { addFetchTourTypes } from "./extraReducers/addFetchTourTypes";

interface IFiltersState {
  departureCities?: IDepartureCity[];
  destinationCountries?: IDestinationCountry[];
}

interface IAssociateFilterState {
  countries: IDestinationCountry[];
}

export interface IDirectoryState extends IDirectoryResponse {
  loadingKeys: (keyof IDirectoryResponse)[];
  filterData: IFiltersState;
  associateData: IAssociateFilterState;
}

const initialState: IDirectoryState = {
  loadingKeys: [],
  filterData: {},
  associateData: {
    countries: [],
  },
};

export const directorySlice = createSlice({
  name: "directory",
  initialState,
  reducers: {
    resetDirectoryState: () => initialState,
  },
  extraReducers: (builder) => {
    addFetchOperators(builder);
    addFetchDepartureCity(builder);
    addFetchDestinationCountries(builder);
    addFetchDestinationCities(builder);
    addFetchDestinationResorts(builder);
    addFetchTourTypes(builder);
    addFetchHotels(builder);
    addFetchDirectoryForJobInfo(builder);
    addFetchCurrency(builder);
    addFetchTourFilters(builder);

    builder.addCase(createJobTemplate.fulfilled, (state) => {
      resetState(state);
    });

    builder.addCase(createReportTemplate.fulfilled, (state) => {
      resetState(state);
    });
  },
});

const resetState = (state: IDirectoryState) => {
  state.departureCities = undefined;
  state.destinationCities = undefined;
  state.destinationCountries = undefined;
  state.hotelCategories = undefined;
  state.pansions = undefined;
  state.resorts = undefined;
  state.tourTypes = undefined;
  state.hotels = undefined;
};

export const { resetDirectoryState } = directorySlice.actions;
export default directorySlice.reducer;
