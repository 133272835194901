import { IBaseItem } from "../../../api/types/directory/IBaseItem";
import { IHotel } from "../../../api/types/directory/IHotel";
import { IRegion } from "../../../api/types/directory/IRegion";
import { ITourFilter } from "../../../api/types/directory/ITourFilter";
import { hasTezID } from "../../../utils/hasTezID";
import { IAssociateState } from "../types/IAssociateState";

type MatchKind = "hotel" | "region" | "tour";
function sourceItemsFor(
  operatorId: number,
  type: MatchKind,
  state: IAssociateState,
): IBaseItem[] | undefined | null {
  if (type === "hotel") return state.hotels[operatorId];
  if (type === "region") return state.regions[operatorId];
  if (type === "tour") return state.tourFilters[operatorId];
  return undefined;
}

function setFilterFor(
  operatorId: number,
  type: MatchKind,
  state: IAssociateState,
  found: IBaseItem,
) {
  if (type === "hotel") state.filterHotel[operatorId] = found as IHotel;
  if (type === "region") state.filterRegion[operatorId] = found as IRegion;
  if (type === "tour") state.filterTour[operatorId] = found as ITourFilter;
}

function resetSelectedFor(operatorId: number, state: IAssociateState) {
  if (operatorId === 10) {
    state.tezTargets = [];
  } else {
    delete state.associated[operatorId];
  }
}

function getFilterFor(
  operatorId: number,
  type: MatchKind,
  state: IAssociateState,
): IBaseItem | undefined {
  if (type === "hotel") return state.filterHotel[operatorId] as IHotel;
  if (type === "region") return state.filterRegion[operatorId] as IRegion;
  if (type === "tour") return state.filterTour[operatorId] as ITourFilter;
}
export function setMatchingItemFilter(
  operatorID: number,
  item: IBaseItem | null,
  state: IAssociateState,
  target: MatchKind,
) {
  if (!item) return;
  if (hasTezID(item.tez_id) || operatorID === 10) {
    const tezID =
      operatorID === 10
        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          item?.external_id ?? item.id
        : item.tez_id;
    const operatorIDs = state.operators.map((o) => o.id);
    // Add tez as it not listed in operators
    operatorIDs.push(10);
    for (const id of operatorIDs) {
      // skip current operator as we've already set it
      if (id === operatorID) continue;
      const sourceItems = sourceItemsFor(id, target, state);
      if (!sourceItems) continue;
      const found = sourceItems.find((h) => {
        return id === 10 ? h.id === tezID : h.tez_id === tezID;
      });
      const existing = getFilterFor(id, target, state);
      if (found && found.id !== existing?.id) {
        setFilterFor(id, target, state, found);
        resetSelectedFor(id, state);
      } else {
        delete state.filterHotel[id];
        delete state.pansions[id];
      }
    }
  }
}
