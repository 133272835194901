import { RootState } from "../../../store/rootState";
import { AssociateType } from "../../filter/types/AssociateType";

export function selectListItemsFrom(
  s: RootState,
  operatorID: number,
  type: AssociateType,
) {
  if (type === AssociateType.hotels) {
    return s.associate.hotels[operatorID] ?? [];
  }
  if (type === AssociateType.pansions) {
    return s.associate.pansions[operatorID] ?? [];
  }
  if (type === AssociateType.regions) {
    return s.associate.regions[operatorID] ?? [];
  }
  if (type === AssociateType.tours) {
    return s.associate.tourFilters[operatorID] ?? [];
  }
  if (type === AssociateType.createTours) {
    return s.associate.resorts[operatorID] ?? [];
  }
  if (type === AssociateType.roomTypes) {
    return s.associate.rooms[operatorID] ?? [];
  }
  if (type === AssociateType.cities) {
    return s.associate.destinationCities[operatorID] ?? [];
  }
  return [];
}
