import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IHotelExtended } from "../../../api/types/directory/IHotel";
import { IActionErrorInfo } from "../../jobs/types/IActionErrorInfo";
import { IHotelEditParams, postHotelChanges } from "../thunks/postHotelChanges";
import { IAssociateState } from "../types/IAssociateState";

export const addPostHotelChanges = (builder: ActionReducerMapBuilder<IAssociateState>) => {
  builder.addCase(postHotelChanges.pending, (state) => {
    state.loadingKeys = [...state.loadingKeys, `hotel`];
  });
  builder.addCase(postHotelChanges.rejected, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `hotel`);

    const error: IActionErrorInfo = {
      id: Date.now(),
      message: `Не удалось сохранить изменения для "${action.meta.arg.name}"`,
    };
    state.errors = [...state.errors, error];
  });
  builder.addCase(postHotelChanges.fulfilled, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `hotel`);
    const tezHotels = state.hotels[10];
    if (tezHotels) {
      state.hotels[10] = tezHotels.map((hotel) =>
        updateHotel(hotel as IHotelExtended, action.payload),
      );
    }
    const targets = [...state.tezTargets];
    state.tezTargets = targets.map((hotel) => updateHotel(hotel as IHotelExtended, action.payload));
  });
};

const updateHotel = (hotel: IHotelExtended, payload: IHotelEditParams): IHotelExtended => {
  if (hotel.id == payload.id) {
    return {
      ...hotel,
      name: payload.name,
      city_id: payload.city_id,
      resort_id: payload.resort_id,
      category_id: payload.category_id,
      note: payload.note,
      stars: payload.stars,
    };
  } else return { ...hotel };
};
