import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { MdAdd, MdRemove } from "react-icons/md";
import { DateObject } from "react-multi-date-picker";
import { IDestinationCity } from "../../api/types/directory/IDestinationCity";
import { IHotel } from "../../api/types/directory/IHotel";
import { IResort } from "../../api/types/directory/IResort";
import { ITourType } from "../../api/types/directory/ITourType";
import { IJobData } from "../../api/types/jobs/IJobInfo";
import { IJobParams } from "../../api/types/jobs/IJobParams";
import { IJobStats } from "../../api/types/jobs/IJobStats";
import { JobAction } from "../../api/types/jobs/JobAction";
import { JobStatus } from "../../api/types/jobs/JobStatus";
import { inputStyleVariant } from "../../constants/interface";
import { templateTypeDataSource } from "../../constants/TemplateTypeDataSource";
import { fetchCountryDependencies } from "../../store/directory/thunks/fetchCountryDependencies";
import { fetchCurrency } from "../../store/directory/thunks/fetchCurrency";
import { fetchDepartureCity } from "../../store/directory/thunks/fetchDepartureCity";
import { fetchDestinationCountries } from "../../store/directory/thunks/fetchDestinationCountries";
import { fetchOperators } from "../../store/directory/thunks/fetchOperators";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { createJobFromTemplate } from "../../store/jobs/thunks/createJobFromTemplate";
import { createJobTemplate } from "../../store/jobs/thunks/createJobTemplate";
import { editJob } from "../../store/jobs/thunks/editJob";
import { DayPicker } from "../picker/DayPicker";
import { GridPicker } from "../picker/GridPicker";
import { MultiDateSelect } from "../select/MultiDateSelect";
import { SelectField } from "../select/SelectField";
import { SpacedContainerStyled } from "../styled/SpacedContainerStyled";
import { nightsDataSource } from "./data/nightsDataSource";
import { EditorMode } from "./types/EditorMode";
import { jobStatsToGridSource } from "./utils/jobStatsToGridSource";
import { titleForMode } from "./utils/TitleForMode";

interface Props {
  mode: EditorMode;
  source?: IJobData;
  jobStats?: IJobStats[];
  onClose: () => void;
}

export const TemplateEditor = ({ mode, source, onClose, jobStats }: Props) => {
  const dispatch = useAppDispatch();

  const loadingKeys = useAppSelector((state) => state.directory.loadingKeys);

  const operators = useAppSelector((state) => state.directory.operators ?? []);
  const departureCities = useAppSelector(
    (state) => state.directory.departureCities ?? [],
  );
  const destinationCountries = useAppSelector(
    (state) => state.directory.destinationCountries ?? [],
  );
  const destinationCities: IDestinationCity[] = useAppSelector(
    (state) => state.directory.destinationCities ?? [],
  );
  const destinationResorts = useAppSelector(
    (state) => state.directory.resorts ?? [],
  );
  const tourTypes = useAppSelector((state) => state.directory.tourTypes ?? []);
  const hotels = useAppSelector((state) => state.directory.hotels ?? []);
  const currencies = useAppSelector((s) => s.directory.currency ?? []);

  const jobCreated = useAppSelector(
    (state) => state.jobs.jobsUpdated,
    (lhs, rhs) => lhs.join(",") === rhs.join(","),
  );

  const loading = useAppSelector((s) => s.jobs.jobCreating);

  const [name, setName] = useState(source?.name ?? "");
  const [templateType, setTemplateType] = useState(source?.type ?? 0);
  const [operator, setOperator] = useState<number | undefined>(
    source?.operator_id,
  );
  const [departureCity, setDepartureCity] = useState<number | undefined>(
    source?.city_from,
  );
  const [destinationCountry, setDestinationCountry] = useState<
    number | undefined
  >(source?.country);
  const [destinationCity, setDestinationCity] = useState<IDestinationCity[]>(
    source?.sDestinationCity ?? [],
  );
  const [resorts, setResorts] = useState<IResort[]>(source?.sResorts ?? []);
  const [tourType, setTourType] = useState<ITourType[]>(
    source?.sTourType ?? [],
  );
  const [hotel, setHotel] = useState<IHotel[]>(source?.sHotel ?? []);
  const [tourDates, setTourDates] = useState<DateObject[]>(
    source?.dates.split(",").map((d) => {
      const dObj = new DateObject(d);
      dObj.setFormat("DD.MM.YYYY");
      return dObj.parse(d);
    }) ?? [],
  );
  const [currency, setCurrency] = useState(source?.currency ?? "USD");
  const [nights, setNights] = useState<number[]>(
    source?.nights.split(",").map((n) => parseInt(n, 10)) ?? [],
  );
  const [dbl, setDbl] = useState(source?.DBL === "1");
  const [adjustment, setAdjustment] = useState(
    source
      ? parseInt(source.adjustment.replace("-", "").replace("+", ""), 10)
      : 0,
  );
  const [adjAdd, setAdjAdd] = useState(
    source ? source.adjustment.includes("+") : true,
  );
  const [extraBed, setExtraBed] = useState(source?.CHD === "1");
  const [runTime, setRunTime] = useState(source?.schedule_time ?? "");
  const [runDays, setRunDays] = useState<number[]>(
    source
      ? source.schedule_days
          .split(",")
          .map((d) => parseInt(d, 10))
          .filter((val) => !isNaN(val))
      : [],
  );
  const [specialOrder, setSpecialOrder] = useState<Record<string, number[]>>(
    source?.special_order ? JSON.parse(source.special_order) ?? {} : {},
  );
  useEffect(() => {
    if (mode === "create") dispatch(fetchOperators());
    if (currencies) dispatch(fetchCurrency());
  }, []);

  useEffect(() => {
    if (jobCreated.length > 0) {
      clearForm();
      if (onClose) onClose();
    }
  }, [jobCreated]);

  const clearOperatorDependencies = () => {
    setDepartureCity(undefined);
    clearDepartureCityDependencies();
  };

  const clearDepartureCityDependencies = () => {
    setDestinationCountry(undefined);
    clearDestinationCountryDependencies();
  };

  const clearDestinationCountryDependencies = () => {
    setDestinationCity([]);
    setTourType([]);
    setResorts([]);
    setHotel([]);
  };

  const clearForm = () => {
    setName("");
    setTemplateType(0);
    setOperator(undefined);
    clearOperatorDependencies();
    setTourDates([]);
    setNights([]);
    setDbl(false);
    setExtraBed(false);
    setRunTime("");
    setRunDays([]);
    setSpecialOrder({});
  };

  const onOperatorSelect = (id: number) => {
    setOperator(id);
    if (operator !== id) {
      dispatch(fetchDepartureCity({ operatorId: id }));
      clearOperatorDependencies();
    }
  };

  const onDepartureCitySelect = (id: number) => {
    if (departureCity !== id) {
      if (operator !== undefined)
        dispatch(fetchDestinationCountries({ operator, departureCity: id }));
      clearDepartureCityDependencies();
    }
    setDepartureCity(id);
  };

  const onDestinationCountrySelect = (id: number) => {
    if (destinationCountry !== id) {
      if (operator !== undefined && departureCity !== undefined) {
        dispatch(
          fetchCountryDependencies({
            operator,
            departureCity,
            destinationCountry: id,
          }),
        );
      }
      clearDestinationCountryDependencies();
    }
    setDestinationCountry(id);
  };

  const operatorsLoading = loadingKeys.includes("operators");
  const departureCitiesDisabled =
    operatorsLoading || departureCities.length === 0;
  const destCountriesDisabled =
    departureCitiesDisabled || destinationCountries.length === 0;
  const destinationCitiesDisabled =
    departureCitiesDisabled || destinationCities.length === 0;
  const resortsDisabled =
    destCountriesDisabled || destinationResorts.length === 0;
  const tourTypesDisabled = destCountriesDisabled || tourTypes.length === 0;
  const hotelsDisabled = destCountriesDisabled || hotels.length === 0;

  const canSave =
    name.length > 0 &&
    operator !== undefined &&
    departureCity !== undefined &&
    destinationCountry !== undefined &&
    tourType.length > 0 &&
    nights.length > 0 &&
    tourDates.length > 0 &&
    mode !== "view";

  const onPreDateChange = (list: DateObject[]) => {
    const dates = tourDates.map((d) => `${d}`);
    const updatedDates = list.map((d) => `${d}`);
    const newDates = updatedDates.filter((d) => !dates.includes(d));
    const missingDates = dates.filter((d) => !updatedDates.includes(d));

    if (newDates.length > 0 || missingDates.length > 0) {
      const updatedSpecialOrder = { ...specialOrder };
      for (const date of missingDates) {
        delete updatedSpecialOrder[`${date}`];
      }
      for (const date of newDates) {
        updatedSpecialOrder[`${date}`] = [...nights];
      }
      setSpecialOrder(updatedSpecialOrder);
    }

    setTourDates([...list]);
  };

  const onPreNightsChange = (n: number[]) => {
    const newNights = n.filter((itm) => !nights.includes(itm));
    const missingNights = nights.filter((itm) => !n.includes(itm));
    if (newNights.length > 0 || missingNights.length > 0) {
      const updatedSpecialOrder = { ...specialOrder };
      for (const [key, value] of Object.entries(updatedSpecialOrder)) {
        updatedSpecialOrder[key] = [
          ...value.filter((night) => !missingNights.includes(night)),
          ...newNights,
        ];
      }
      setSpecialOrder(updatedSpecialOrder);
    }
    setNights([...n]);
  };

  const submitTemplate = () => {
    if (!departureCity || !destinationCountry || !operator) {
      // Show alert about missing departure city
      return;
    }
    const params: IJobParams = {
      CHD: extraBed ? "1" : "0",
      DBL: dbl ? "1" : "0",
      action: mode === "edit" ? JobAction.editJob : JobAction.createJob,
      city_from: departureCity,
      city_to: destinationCity.map((c) => c.id).join(","),
      country: destinationCountry,
      dates: tourDates.join(","),
      name: name,
      nights: nights.join(","),
      operator: operator,
      resort: resorts.map((r) => r.id).join(","),
      schedule_days: runDays.join(","),
      schedule_time: runTime,
      special_order:
        Object.values(specialOrder).length > 0
          ? JSON.stringify(specialOrder, null, 0)
          : "",
      tour_types: tourType.map((t) => t.id).join(","),
      type: templateType,
      hotels: hotel.map((h) => h.id).join(","),
      adjustment: adjAdd ? adjustment : -adjustment,
      currency: currency,
    };

    if (mode === "create") dispatch(createJobTemplate(params));
    if (mode === "clone") {
      if (source?.status === `${JobStatus.template}`)
        dispatch(createJobTemplate(params));
      else dispatch(createJobFromTemplate(params));
    }

    if (mode === "edit" && source) {
      dispatch(editJob({ id: source.id, ...params }));
    }
  };

  const onCancelPress = () => {
    if (mode === "create") clearForm();
    if (mode === "edit") onClose();
    if (mode === "clone") onClose();
    else onClose();
  };

  return (
    <Container maxWidth="md" component={"div"}>
      <Grid container spacing={1} rowSpacing={2} alignItems={"flex-end"}>
        {mode === "create" ? (
          <Grid item xs={12}>
            <h1>{titleForMode(mode)}</h1>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <TextField
            label="Название шаблона"
            required
            variant={inputStyleVariant}
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value ?? "")}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectField
            items={templateTypeDataSource}
            required
            label={"Тип шаблона"}
            value={templateType}
            onSelect={(val) => setTemplateType(val as number)}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectField
            items={operators}
            required
            label={"Оператор"}
            value={operator}
            loading={loadingKeys.includes("operators")}
            onSelect={(val) => onOperatorSelect(val as number)}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectField
            items={departureCities}
            required
            loading={loadingKeys.includes("departureCities")}
            label={"Город вылета"}
            value={departureCity}
            disabled={departureCitiesDisabled}
            onSelect={(val) => onDepartureCitySelect(val as number)}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectField
            items={destinationCountries}
            required
            disabled={destCountriesDisabled}
            label={"Страна назначения"}
            value={destinationCountry}
            loading={loadingKeys.includes("destinationCountries")}
            onSelect={(val) => onDestinationCountrySelect(val as number)}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            multiple
            options={destinationCities}
            disabled={destinationCitiesDisabled}
            loading={loadingKeys.includes("destinationCities")}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(lhs, rhs) => lhs.id === rhs.id}
            onChange={(_event, newValue) => {
              const allItem = newValue.find((i) => i.id === -1);
              if (allItem) setDestinationCity([allItem]);
              else {
                const uniqueMap = new Map<number, IDestinationCity>();
                newValue.forEach((i) => uniqueMap.set(i.id, i));
                setDestinationCity([...uniqueMap.values()]);
              }
            }}
            value={destinationCity}
            renderInput={(params) => (
              <TextField
                {...params}
                variant={inputStyleVariant}
                label="Города назначения"
                required
                placeholder="Города назначения"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            multiple
            options={destinationResorts}
            loading={loadingKeys.includes("resorts")}
            disabled={resortsDisabled}
            getOptionLabel={(option) => option.name}
            value={resorts}
            isOptionEqualToValue={(lhs, rhs) => lhs.id === rhs.id}
            onChange={(_event, newValue) => {
              setResorts(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant={inputStyleVariant}
                label="Курорты"
                placeholder="Курорты"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            multiple
            options={tourTypes}
            getOptionLabel={(option) => option.name}
            value={tourType}
            isOptionEqualToValue={(lhs, rhs) => lhs.id === rhs.id}
            onChange={(e, val) => {
              const allItem = val.find((i) => i.id == -1);
              if (allItem) setTourType([allItem]);
              else {
                const uniqueMap = new Map<number, ITourType>();
                val.forEach((i) => uniqueMap.set(i.id, i));
                setTourType([...uniqueMap.values()]);
              }
            }}
            loading={loadingKeys.includes("tourTypes")}
            disabled={tourTypesDisabled}
            renderInput={(params) => (
              <TextField
                {...params}
                variant={inputStyleVariant}
                label="Тип тура"
                placeholder="Тип тура"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <MultiDateSelect
            required
            value={tourDates}
            onChange={onPreDateChange}
            label={"Даты тура *"}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            multiple
            options={nightsDataSource}
            isOptionEqualToValue={(option, value) => option === value}
            value={nights.map((n) => `${n}`)}
            onChange={(e, v) =>
              onPreNightsChange(
                v.map((n) => parseInt(n, 10)).sort((a, b) => a - b),
              )
            }
            getOptionLabel={(option) => option}
            defaultValue={[]}
            renderInput={(params) => (
              <TextField
                {...params}
                variant={inputStyleVariant}
                label="Количество ночей"
                placeholder="Количество ночей"
                required
              />
            )}
          />
        </Grid>
        {tourDates.length > 0 || nights.length > 0 ? (
          <Grid item xs={12}>
            <GridPicker
              selected={specialOrder}
              previousData={jobStatsToGridSource(jobStats)}
              onChange={(val) => setSpecialOrder(val)}
              dates={tourDates.sort((a, b) => a.toUnix() - b.toUnix())}
              nights={nights.sort((a, b) => a - b)}
              label={"Специальный порядок дат и ночей"}
            />
          </Grid>
        ) : null}

        <Grid item xs={5}>
          <Autocomplete
            multiple
            options={hotels}
            getOptionLabel={(option) =>
              `${option.name} (${option.stars}) [${option.id}]`
            }
            value={hotel}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, val) => setHotel(val)}
            disabled={hotelsDisabled}
            loading={loadingKeys.includes("hotels")}
            renderInput={(params) => (
              <TextField
                {...params}
                variant={inputStyleVariant}
                label="Отели"
                placeholder="Отели"
              />
            )}
          />
        </Grid>

        <Grid item xs={2}>
          <TextField
            label={"+/- от стоимости"}
            value={adjustment}
            onChange={(e) =>
              setAdjustment(parseInt(e.target.value.replace(/\D/g, ""), 10))
            }
            variant={inputStyleVariant}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              maxLength: 5,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() => setAdjAdd(!adjAdd)}
                    edge="start"
                    color={"primary"}
                    size={"small"}
                    title={"Нажмите, чтобы изменить"}
                  >
                    {adjAdd ? <MdAdd /> : <MdRemove />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              marginRight: "auto",
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <SelectField
            items={currencies}
            required
            label={"Валюта"}
            value={currency}
            maxWidth={100}
            loading={loadingKeys.includes("currency")}
            onSelect={(val) => setCurrency(val as string)}
          />
        </Grid>

        <Grid item xs={3} justifyContent="flex-end">
          <FormGroup row={true} sx={{ justifyContent: "flex-end" }}>
            <FormControlLabel
              control={
                <Checkbox
                  size={"small"}
                  checked={dbl}
                  onChange={(e, val) => setDbl(val)}
                />
              }
              label="DBL"
            />
            <FormControlLabel
              control={
                <Checkbox
                  size={"small"}
                  checked={extraBed}
                  onChange={(e, val) => setExtraBed(val)}
                />
              }
              label="Доп. номер"
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12}>
          <h5>Параметры запуска</h5>
          <DayPicker
            selected={runDays}
            onChange={(val) => setRunDays(val)}
            time={runTime}
            onTimeChange={(val) => setRunTime(val)}
          />
        </Grid>
        {runTime.length === 0 && runDays.length === 0 ? (
          <Grid item xs={12}>
            <Alert severity="info">
              Задача будет запущена сразу после сохранения шаблона!
            </Alert>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <SpacedContainerStyled>
            <Button variant="text" onClick={onCancelPress}>
              {mode === "create" ? "Очистить" : "Отмена"}
            </Button>
            <LoadingButton
              loading={loading}
              disabled={!canSave || loading}
              variant="contained"
              onClick={() => submitTemplate()}
            >
              {mode === "clone" ? "Клонировать" : "Сохранить"}
            </LoadingButton>
          </SpacedContainerStyled>
        </Grid>
      </Grid>
    </Container>
  );
};
