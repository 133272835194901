export enum BindAction {
  bindHotel = "bindHotel",
  bindPansion = "bindPansion",
  bindRegions = "bindRegions",
  bindTour = "bindTour",
  bindTourFilter = "bindTourFilter",
  bindRooms = "bindRooms",
  bindCity = "bindCity",
}

export const UnbindAction = {
  unbindHotel: "unbindHotel",
  unbindPansion: "unbindPansion",
  unbindRegion: "unbindRegion",
  unbindTour: "unbindTour",
  unbindRoom: "unbindRoom",
  unbindCity: "unbindCity",
};

export type UnbindActionKeys = keyof typeof UnbindAction;
export type UnbindActionType = typeof UnbindAction[UnbindActionKeys];
