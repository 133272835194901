import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchAssociateHotelCategories } from "../thunks/fetchAssociateHotelCategories";
import { IAssociateState } from "../types/IAssociateState";

export const addFetchAssociateHotelCategories = (
  builder: ActionReducerMapBuilder<IAssociateState>,
) => {
  builder.addCase(fetchAssociateHotelCategories.pending, (state, action) => {
    state.loadingKeys = [...state.loadingKeys, `${action.meta.arg.operator}`];
  });
  builder.addCase(fetchAssociateHotelCategories.rejected, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `${action.meta.arg.operator}`);
  });
  builder.addCase(fetchAssociateHotelCategories.fulfilled, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `${action.meta.arg.operator}`);
    state.tezHotelCategories = [...action.payload];
  });
};
