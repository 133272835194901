import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import { BindAction, UnbindActionType } from "../../../api/types/BindAction";
import { IBindParams, IUnbindParams } from "../../../api/types/IBindParams";

interface Params {
  action: BindAction
  tezID: number | string
  localIDs: (string | number)[]
}
export const bindDirectoryItems = createAsyncThunk<void, Params>(
  "associate/bind",
  async (params, thunkAPI) => {
    const data: IBindParams = {
      action: params.action,
      id: params.tezID,
    };
    console.log("bindDirectoryItems: ", params);
    if (params.action === BindAction.bindHotel) {
      data.hotels = params.localIDs.join(",");
    } else {
      data.ids = params.localIDs.join(",");
    }

    await endpoints.directory.bind(data, thunkAPI.signal);
  },
);

interface UnbindParams {
  action: UnbindActionType
  localIDs: (string | number)[]
}
export const unbindDirectoryItems = createAsyncThunk<void, UnbindParams>(
  "associate/unbind",
  async (params, thunkAPI) => {
    const data: IUnbindParams = {
      action: params.action,
      ids: params.localIDs.join(","),
    };

    await endpoints.directory.unbind(data, thunkAPI.signal);
  },
);
