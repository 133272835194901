import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchAssociateTourTypes } from "../thunks/fetchAssociateTourTypes";
import { IAssociateState } from "../types/IAssociateState";

export const addFetchAssociateTourTypes = (builder: ActionReducerMapBuilder<IAssociateState>) => {
  builder.addCase(fetchAssociateTourTypes.pending, (state, action) => {
    state.loadingKeys = [...state.loadingKeys, `${action.meta.arg.operator}`];
  });
  builder.addCase(fetchAssociateTourTypes.rejected, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `${action.meta.arg.operator}`);
  });
  builder.addCase(fetchAssociateTourTypes.fulfilled, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `${action.meta.arg.operator}`);
    state.tourTypes[action.meta.arg.operator] = [...action.payload];
  });
};
