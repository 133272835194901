import { TableHead, TableRow } from "@mui/material";
import React from "react";
import { TemplateListMode } from "../../../pages/types/TemplateListMode";
import { StyledTableCell } from "../../styled/TableCell.styled";
import { lastColMaxWidth } from "./constants/lastColMaxWidth";

interface Props {
  mode: TemplateListMode;
  isJobs: boolean;
}
export const RowHeader = ({ mode, isJobs }: Props) => {
  return (
    <TableHead>
      <TableRow>
        <StyledTableCell>
          Название
          <br />
          <small>Тип </small>
        </StyledTableCell>
        <StyledTableCell>Оператор</StyledTableCell>
        <StyledTableCell>Город вылета</StyledTableCell>
        <StyledTableCell>Страна назначения</StyledTableCell>
        <StyledTableCell>Описание</StyledTableCell>
        {isJobs && mode !== "imported" ? (
          <StyledTableCell sx={{ width: lastColMaxWidth }}>
            Посл. запуск
          </StyledTableCell>
        ) : null}
        {isJobs && mode !== "imported" ? (
          <StyledTableCell sx={{ width: lastColMaxWidth }}>
            Время старта
          </StyledTableCell>
        ) : null}
        {isJobs && mode !== "imported" ? (
          <StyledTableCell sx={{ width: lastColMaxWidth }}>
            Время окончания
          </StyledTableCell>
        ) : null}

        {isJobs ? (
          <StyledTableCell sx={{ width: lastColMaxWidth }}>
            Загружено <br />
            <small>
              {mode === "imported" ? "%.Строк.Записей" : "%.Строк.Загружено"}
              {mode === "imported" ? (
                <>
                  <br /> Дата импорта
                </>
              ) : null}
            </small>
          </StyledTableCell>
        ) : null}

        {isJobs && mode !== "imported" ? (
          <StyledTableCell sx={{ width: lastColMaxWidth }}>
            Статус
          </StyledTableCell>
        ) : null}
        <StyledTableCell align={"right"}>Управление</StyledTableCell>
      </TableRow>
    </TableHead>
  );
};
