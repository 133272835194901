import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import { IGetUsersResponse } from "../../../api/types/user/IGetUsersResponse";

export const fetchUsers = createAsyncThunk<IGetUsersResponse, void>(
  "users/fetch",
  async (param, thunkAPI) => {
    return await endpoints.user.getAll(thunkAPI.signal);
  },
);
