import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch } from "../../appDispatch";
import { RootState } from "../../rootState";
import { fetchDestinationCities } from "./fetchDestinationCities";
import { fetchDestinationResorts } from "./fetchDestinationResorts";
import { fetchHotels } from "./fetchHotels";
import { fetchTourTypes } from "./fetchTourTypes";

interface Params {
  operator: number
  departureCity: number
  destinationCountry: number
}

export const fetchCountryDependencies = createAsyncThunk<
  void,
  Params,
  { state: RootState; dispatch: AppDispatch }
>("directory/fetchCountryDependencies", async (param, thunkAPI) => {
  thunkAPI.dispatch(
    fetchDestinationCities({
      operator: param.operator,
      departureCity: param.departureCity,
      destinationCountry: param.destinationCountry,
    }),
  );

  thunkAPI.dispatch(
    fetchDestinationResorts({
      operator: param.operator,
      departureCity: param.departureCity,
      destinationCountry: param.destinationCountry,
    }),
  );

  thunkAPI.dispatch(
    fetchTourTypes({
      operator: param.operator,
      departureCity: param.departureCity,
      destinationCountry: param.destinationCountry,
    }),
  );

  thunkAPI.dispatch(
    fetchHotels({
      operator: param.operator,
      departureCity: param.departureCity,
      destinationCountry: param.destinationCountry,
    }),
  );
});
