import { IAssociateState } from "../types/IAssociateState";

export const cleanupOperatorData = (
  operatorID: number,
  state: IAssociateState,
  withFilters: boolean,
) => {
  delete state.suggested[operatorID];
  delete state.associated[operatorID];
  if (withFilters) {
    delete state.filterHotel[operatorID];
    delete state.filterResort[operatorID];
  }
};
