import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { logoutUser } from "../user/thunks/logoutUser";

interface IAppSlice {
  loading: boolean
}

const initialState: IAppSlice = {
  loading: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setAppLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(logoutUser.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(logoutUser.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export const { setAppLoading } = appSlice.actions;
export default appSlice.reducer;
