import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiAction } from "../../../api/ApiAction";
import { endpoints } from "../../../api/endpoints";

export interface IHotelEditParams {
  id: number | string;
  name: string;
  city_id: number;
  resort_id: number;
  category_id: number;
  note: string;
  stars: string;
}
export const postHotelChanges = createAsyncThunk<
  IHotelEditParams,
  IHotelEditParams
>("associate/saveHotel", async (params, thunkAPI) => {
  console.log("save hotel ", params);
  await endpoints.directory.edit(
    {
      action: ApiAction.editHotel,
      ...params,
    },
    thunkAPI.signal,
  );
  return params;
});
