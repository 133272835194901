import styled from "@emotion/styled";
import { globalBorderWidth, hPaddingValue, vPaddingValue } from "../../../constants/sizes";
import { Color } from "../../../constants/colors";
import { adjustColor } from "../../../utils/adjustColor";

export const HeaderStyled = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  //background-color: ${Color.background};

  //border-bottom: ${globalBorderWidth}px solid ${Color.border};

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  box-sizing: border-box;
`;
export const HeaderRowStyled = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  background-color: ${Color.background};
  padding: ${vPaddingValue / 2}px ${hPaddingValue / 2}px;
`;
export const HeaderSubRowStyled = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  background-color: ${adjustColor(Color.brand, -15)};
  padding: ${vPaddingValue / 4}px ${hPaddingValue / 2}px;
`;
export const HeaderSubPlaceholder = styled.div`
  display: flex;
  width: 100%;
  height: 3px;
  background-color: ${adjustColor(Color.border, -15)};
`;
