import styled from "@emotion/styled";
import { Color } from "../../../constants/colors";
import { globalRadius, hPaddingValue } from "../../../constants/sizes";

export const EmptyListStateStyled = styled.div`
  display: flex;
  margin: auto;
  border-radius: ${globalRadius}px;
  padding: ${hPaddingValue}px;
  background-color: ${Color.background};
`;
