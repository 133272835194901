import styled from "@emotion/styled";
import { Color } from "../../../constants/colors";
import { globalRadius } from "../../../constants/sizes";

export const DayPickerContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  background-color: ${Color.background};

  border: 1px solid ${Color.secondaryBackground};

  border-radius: ${globalRadius}px;
`;
