export enum AccommodationType {
  All = -1,
  SGL = 1,
  DBL = 2,
  DBLPlusCHD = 3,
}
// Oh, how I hate JS/TS. Why make enums with no way to enumerate their cases in a way that can
// be useful, why?
export const accommodationList = [
  AccommodationType.All,
  AccommodationType.SGL,
  AccommodationType.DBL,
  AccommodationType.DBLPlusCHD,
];

export const titleForAccommodationType = (type: AccommodationType): string => {
  switch (type) {
    case AccommodationType.All:
      return "Все";
    case AccommodationType.SGL:
      return "SGL";
    case AccommodationType.DBL:
      return "DBL";
    case AccommodationType.DBLPlusCHD:
      return "DBL+CHD";
  }
};

export const accommodationTypeFromSource = (
  num: number | string,
): AccommodationType | undefined => {
  if (num == AccommodationType.All) return AccommodationType.All;
  if (num == AccommodationType.SGL) return AccommodationType.SGL;
  if (num == AccommodationType.DBL) return AccommodationType.DBL;
  if (num == AccommodationType.DBLPlusCHD) return AccommodationType.DBLPlusCHD;
  return undefined;
};
