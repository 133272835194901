import { IJobInfo } from "../../../../api/types/jobs/IJobInfo";
import { IJobParams } from "../../../../api/types/jobs/IJobParams";
import { JobAction } from "../../../../api/types/jobs/JobAction";

export const jobParamsFromInfo = (template: IJobInfo, isTemplate: boolean): IJobParams => {
  return {
    action: JobAction.createJob,
    name: template.name,
    type: template.type,
    operator: template.operator_id,
    city_from: template.city_from,
    country: template.country,
    city_to: template.city_to,
    tour_types: template.tour_types,
    dates: template.dates,
    nights: template.nights,
    resort: template.resort,
    DBL: template.DBL,
    CHD: template.CHD,
    schedule_time: template.schedule_time,
    schedule_days: template.schedule_days,
    special_order: template.special_order,
    hotels: template.hotels,
    status: isTemplate ? 0 : 1,
    adjustment: parseInt(template.adjustment, 10),
    currency: template.currency.length === 0 ? "USD" : template.currency,
  };
};
