import { AssociateType } from "../../../components/filter/types/AssociateType";
import { ISelectItem } from "../../../components/selectList/types/ISelectItem";
import { IAssociateState } from "../types/IAssociateState";

export const getAllForOperator = (
  id: number,
  type: AssociateType,
  state: IAssociateState,
): ISelectItem[] => {
  let all: ISelectItem[] = [];
  switch (type) {
    case AssociateType.hotels:
      all = state.hotels[id];
      break;
    case AssociateType.pansions:
      all = state.pansions[id];
      break;
    case AssociateType.tours:
      all = state.tourFilters[id];
      break;
    case AssociateType.regions:
      all = state.regions[id];
      break;
    case AssociateType.roomTypes:
      all = state.rooms[id];
      break;
    case AssociateType.createRegions:
      all = state.resorts[id];
      break;
    case AssociateType.createTours:
      all = state.regions[id];
      break;
    case AssociateType.cities:
      all = state.destinationCities[id];
  }

  return all ?? [];
};
