import styled from "@emotion/styled";
import { Color } from "../../../constants/colors";
import { globalBorderWidth, globalRadius } from "../../../constants/sizes";
import { adjustColor } from "../../../utils/adjustColor";

interface Props {
  selectable?: boolean
  selected?: boolean
  warning?: boolean
}

export const GridCellStyled = styled.div<Props>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  padding: 16px 16px;
  font-size: 12px;
  font-weight: 500;
  background-color: ${Color.inputBackground};

  box-sizing: border-box;

  transition: all 0.1s ease-in-out;
  white-space: nowrap;

  &:hover,
  &:focus,
  &:active {
    cursor: ${(props) => (props.selectable ? "pointer" : "auto")};
    background-color: ${(props) =>
      props.selectable ? adjustColor(Color.background, -5) : Color.inputBackground};
    &:after {
      opacity: ${(props) => (props.selectable ? "1" : props.selected ? "1" : "0")};
      background-color: ${(props) => (props.selected ? Color.border : Color.secondaryBackground)};
    }
  }

  &:after {
    content: "";
    min-width: 12px;
    min-height: 12px;
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => (props.warning ? Color.danger : Color.brand)};

    border-radius: ${globalRadius}px;

    opacity: ${(props) => (props.selected ? "1" : "0")};
    transition: all 0.1s ease-in-out;
  }

  &:first-of-type {
    flex-grow: 0;
    background-color: ${Color.secondaryBackground};
    border-bottom: ${globalBorderWidth}px solid ${Color.border};
  }
`;
