import React, { PropsWithChildren } from "react";
import { IoBuildOutline } from "react-icons/io5";
import { IconBadgeStyled } from "../styled/IconBadge.styled";
import {
  HeaderItemRightStyled,
  HeaderItemSeparatorStyled,
  HeaderItemStyled,
  HeaderSubItemStyled,
} from "./styled/HeaderItem.styled";
import { Link, useLocation, useMatch } from "react-router-dom";

const inSameRootPath = (path: string, locationPath: string): boolean => {
  const lhs = path.split("/").filter((itm) => itm.length > 0);
  const rhs = locationPath.split("/").filter((itm) => itm.length > 0);
  if (rhs.length < 2) return false;
  return lhs[0] === rhs[0];
};

interface Props extends PropsWithChildren {
  path: string
  subItem?: boolean
  notReady?: boolean
}
export const HeaderItem = ({ children, path, subItem, notReady }: Props) => {
  const match = useMatch(path);
  const location = useLocation();

  const sameRoot = inSameRootPath(path, location.pathname);

  const Wrapper = subItem ? HeaderSubItemStyled : HeaderItemStyled;
  return (
    <Wrapper isActive={subItem ? match?.pathname === path : sameRoot}>
      <Link to={subItem ? path : sameRoot ? location.pathname : path}>{children}</Link>
      {notReady ? (
        <IconBadgeStyled>
          <IoBuildOutline />
        </IconBadgeStyled>
      ) : null}
    </Wrapper>
  );
};

export const HeaderSeparator = () => {
  return (
    <HeaderItemStyled>
      <HeaderItemSeparatorStyled>|</HeaderItemSeparatorStyled>
    </HeaderItemStyled>
  );
};

interface RightProps extends Props {
  onClick?: () => void
}
export const LogoutHeaderItem = ({ children, onClick, path }: RightProps) => {
  const match = useMatch(path);
  const pathname = match?.pathname;
  const location = useLocation();

  return (
    <HeaderItemRightStyled isActive={pathname === path && pathname !== "/"}>
      <Link onClick={onClick} to={location.pathname}>
        {children}
      </Link>
    </HeaderItemRightStyled>
  );
};
