import { JobStatus } from "../../../api/types/jobs/JobStatus";
import { mapJobParamsToJobInfo } from "../../../api/utils/MapJobParamsToJobInfo";
import { editJob } from "../thunks/editJob";
import { IActionErrorInfo } from "../types/IActionErrorInfo";
import { JobsExtraBuilder } from "./types/jobsExtraBuilder";

export const addEditJob = (builder: JobsExtraBuilder) => {
  builder.addCase(editJob.pending, (state, action) => {
    state.loadingJobKeys = [...state.loadingJobKeys, action.meta.arg.id];
    state.jobCreating = true;
  });
  builder.addCase(editJob.rejected, (state, action) => {
    if (state.loadingJobKeys) {
      state.loadingJobKeys = state.loadingJobKeys.filter((id) => id !== action.meta.arg.id);
    }
    state.jobCreating = false;
    const error: IActionErrorInfo = {
      id: Date.now(),
      message: `Ошибка редактирования ${
        action.meta.arg.status === JobStatus.template ? "шаблона" : "задачи"
      } ID: ${action.meta.arg.id}!: ${action.error.message}`,
    };
    state.jobErrors = state.jobErrors ? [...state.jobErrors, error] : [error];
  });
  builder.addCase(editJob.fulfilled, (state, action) => {
    if (state.loadingJobKeys) {
      state.loadingJobKeys = state.loadingJobKeys.filter((id) => id !== action.meta.arg.id);
    }
    state.jobCreating = false;
    state.allJobs = state.allJobs.map((j) => {
      if (j.id == action.meta.arg.id) {
        return mapJobParamsToJobInfo(j, action.meta.arg);
      }
      return j;
    });
  });
};
