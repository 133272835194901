import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchInsuranceInfo } from "../thunks/fetchInsuranceInfo";
import { IServicesState } from "../types/IServicesState";

export const addFetchInsuranceInfo = (
  builder: ActionReducerMapBuilder<IServicesState>,
) => {
  builder.addCase(fetchInsuranceInfo.pending, (state) => {
    state.loadingKeys = [...state.loadingKeys, `insurance`];
  });
  builder.addCase(fetchInsuranceInfo.rejected, (state) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `insurance`);
  });
  builder.addCase(fetchInsuranceInfo.fulfilled, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `insurance`);
    action.payload.forEach((item) => {
      state.insurance[item.hotels] = item;
    });
  });
};
