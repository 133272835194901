import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import { IJobResponse } from "../../../api/types/jobs/IJobResponse";
import { IRestartJobParams } from "../../../api/types/jobs/IRestartJobParams";

export const restartJob = createAsyncThunk<IJobResponse, IRestartJobParams>(
  "jobs/restart",
  async (param, thunkAPI) => {
    const response = await endpoints.job.restart(
      {
        ...param,
      },
      thunkAPI.signal,
    );
    if (response.error) throw response.error;
    if (response.status === "OK") return response;
    else throw new Error(`Invalid response from the server. ${JSON.stringify(response, null, 2)}`);
  },
);
