import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { MdClear } from "react-icons/md";
import { useSelector } from "react-redux";
import { templateTypeDataSource } from "../../constants/TemplateTypeDataSource";
import { TemplateListMode } from "../../pages/types/TemplateListMode";
import { DirectoryTarget } from "../../store/directory/thunks/directoryTarget";
import { fetchDepartureCity } from "../../store/directory/thunks/fetchDepartureCity";
import { fetchDestinationCountries } from "../../store/directory/thunks/fetchDestinationCountries";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  resetFilters,
  setJobFilters,
  setJobListMode,
} from "../../store/jobs/jobsSlice";
import { getJobList, refreshJobList } from "../../store/jobs/thunks/getJobList";
import { IJobFilters } from "../../store/jobs/types/IJobFilters";
import { RootState } from "../../store/rootState";
import { SelectField } from "../select/SelectField";
import { FilterRowStyled } from "./styled/FilterRow.styled";
import { statusSelectItems } from "./utils/statusSelectItems";

interface Props {
  mode: TemplateListMode;
}

export const TemplateFilter = ({ mode }: Props) => {
  const dispatch = useAppDispatch();

  const isLoggedIn = useAppSelector(
    (s) => s.user.isLoggedIn && s.user.info !== null,
  );

  const type = useAppSelector((state: RootState) => state.jobs.filters.type);
  const operator = useAppSelector((s) => s.jobs.filters.operator);
  const departureCity = useAppSelector((s) => s.jobs.filters.city);
  const destinationCountry = useAppSelector((s) => s.jobs.filters.country);
  const status = useAppSelector((s) => s.jobs.filters.status);

  const operators = useSelector(
    (state: RootState) => state.directory.operators ?? [],
  );

  const departureCities = useSelector(
    (state: RootState) => state.directory.filterData.departureCities ?? [],
  );
  const destinationCountries = useSelector((state: RootState) => {
    const result = state.directory.filterData.destinationCountries;
    return result ? [{ id: -1, name: "Все" }, ...result] : [];
  });

  const loadingKeys = useSelector(
    (state: RootState) => state.directory.loadingKeys,
  );

  useEffect(() => {
    if (isLoggedIn) {
      const token = dispatch(getJobList({ mode }));
      return () => token.abort();
    }
  }, [
    type,
    operator,
    departureCity,
    destinationCountry,
    status,
    mode,
    isLoggedIn,
  ]);

  useEffect(() => {
    dispatch(setJobListMode(mode));
    if (mode == "jobs") {
      const interval = setInterval(() => dispatch(refreshJobList()), 60000);
      return () => clearInterval(interval);
    }
  }, [mode]);

  useEffect(() => {
    const tCity = dispatch(fetchDepartureCity({ forFilter: true }));
    const tCountry = dispatch(
      fetchDestinationCountries({ target: DirectoryTarget.filter }),
    );
    return () => {
      tCity.abort();
      tCountry.abort();
    };
  }, []);

  const updateFilters = (data: Partial<IJobFilters>) => {
    dispatch(
      setJobFilters({
        operator: Object.hasOwn(data, "operator") ? data.operator : operator,
        city: Object.hasOwn(data, "city") ? data.city : departureCity,
        country: Object.hasOwn(data, "country")
          ? data.country
          : destinationCountry,
        status: data.status ?? status,
        type: data.type,
      }),
    );
  };
  const onTypeSelect = (id: number) => {
    updateFilters({
      type: id,
    });
  };
  const onOperatorSelect = (id: number) => {
    updateFilters({
      operator: id,
    });
  };

  const onDepartureCitySelect = (id: string) => {
    updateFilters({
      city: id,
    });
  };

  const onDestinationCountrySelect = (id: string) => {
    updateFilters({
      country: id,
    });
  };

  const resetFilter = () => {
    dispatch(resetFilters());
  };

  const resetButtonDisabled = () => {
    const noFilters =
      destinationCountry === undefined &&
      departureCity === undefined &&
      operator === undefined &&
      type === undefined;
    return mode === "jobs" ? noFilters && status === -1 : noFilters;
  };

  const operatorsLoading = loadingKeys.includes("operators");
  const departureCitiesDisabled = departureCities.length === 0;
  const destCountriesDisabled = destinationCountries.length === 0;
  return (
    <FilterRowStyled>
      <SelectField
        items={templateTypeDataSource}
        label={"Тип задачи"}
        value={type}
        onSelect={(val) => onTypeSelect(val as number)}
        canClear={true}
      />
      <SelectField
        items={operators}
        label={"Оператор"}
        value={operator}
        loading={operatorsLoading}
        onSelect={(val) => onOperatorSelect(val as number)}
        canClear={true}
      />
      <SelectField
        items={departureCities}
        loading={loadingKeys.includes("departureCities")}
        label={"Город вылета"}
        value={departureCity}
        disabled={departureCitiesDisabled}
        onSelect={(val) => onDepartureCitySelect(val as string)}
        canClear={true}
      />
      <SelectField
        items={destinationCountries}
        disabled={destCountriesDisabled}
        label={"Страна назначения"}
        value={destinationCountry}
        loading={loadingKeys.includes("destinationCountries")}
        onSelect={(val) => onDestinationCountrySelect(val as string)}
        canClear={true}
      />
      {mode === "jobs" ? (
        <SelectField
          items={statusSelectItems}
          label={"Статус"}
          value={status}
          defaultValue={-1}
          onSelect={(val) => updateFilters({ status: val as number })}
        />
      ) : null}
      <Button
        variant={"outlined"}
        onClick={() => resetFilter()}
        disabled={resetButtonDisabled()}
      >
        <MdClear />
      </Button>
    </FilterRowStyled>
  );
};
