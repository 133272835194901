import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchTransfersInfo } from "../thunks/fetchTransfersInfo";
import { IServicesState } from "../types/IServicesState";

export const addFetchTransfersInfo = (
  builder: ActionReducerMapBuilder<IServicesState>,
) => {
  builder.addCase(fetchTransfersInfo.pending, (state) => {
    state.loadingKeys = [...state.loadingKeys, `transfers`];
  });
  builder.addCase(fetchTransfersInfo.rejected, (state) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `transfers`);
  });
  builder.addCase(fetchTransfersInfo.fulfilled, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `transfers`);
    action.payload.forEach((item) => {
      state.transfers[item.hotels] = item;
    });
  });
};
