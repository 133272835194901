import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchServicesHotels } from "../thunks/fetchServicesHotels";
import { IServicesState } from "../types/IServicesState";

export const addFetchServiceHotels = (builder: ActionReducerMapBuilder<IServicesState>) => {
  builder.addCase(fetchServicesHotels.pending, (state) => {
    state.loadingKeys = [...state.loadingKeys, `hotels`];
  });
  builder.addCase(fetchServicesHotels.rejected, (state) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `hotels`);
  });
  builder.addCase(fetchServicesHotels.fulfilled, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `hotels`);
    state.hotels = [...action.payload];
  });
};
