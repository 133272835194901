import styled from "@emotion/styled";
import { Color } from "../../constants/colors";
import { globalRadius, hPaddingValue, vPaddingValue } from "../../constants/sizes";

interface ColumnBodyStyledProps {
  minHeight?: number;
  maxHeight?: number;
  marginBottom?: number | "auto";
}
export const ColumnBodyStyled = styled.div<ColumnBodyStyledProps>`
  display: flex;
  flex-direction: column;
  border-radius: ${globalRadius}px;
  background-color: ${Color.secondaryBackground};
  padding: ${hPaddingValue / 2}px;
  font-size: 13px;

  overflow-y: auto;
  min-height: ${(props) => props.minHeight ?? 300}px;
  max-height: ${(props) => (props.maxHeight ? `${props.maxHeight}px` : "70vh")};
  row-gap: 4px;
  margin-bottom: ${(props) =>
    props.marginBottom ? (props.marginBottom === "auto" ? "auto" : `${props.marginBottom}px`) : 0};
  h3 {
    font-weight: 500;
    font-size: 12px;
    margin-left: 0;
    margin-right: 0;
    &:first-of-type {
      margin-top: 0;
    }
  }
`;

export const CenterContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${vPaddingValue}px;
`;

interface Props {
  fullWidth?: boolean;
  marginBottom?: number | "auto";
}
export const ColumnStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: stretch;
  width: ${(props) => (props.fullWidth ? `100%` : "300px")};
  margin-right: ${hPaddingValue / 2}px;
  padding-bottom: ${vPaddingValue}px;
  margin-bottom: ${(props) =>
    props.marginBottom ? (props.marginBottom === "auto" ? "auto" : `${props.marginBottom}px`) : 0};

  h3 {
    margin-bottom: ${hPaddingValue / 2}px;
  }
`;
