import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchServiceDepartureCities } from "../thunks/fetchServiceDepartureCities";
import { IServicesState } from "../types/IServicesState";

export const addFetchServiceDepartureCities = (
  builder: ActionReducerMapBuilder<IServicesState>,
) => {
  builder.addCase(fetchServiceDepartureCities.pending, (state) => {
    state.loadingKeys = [...state.loadingKeys, `cities`];
  });
  builder.addCase(fetchServiceDepartureCities.rejected, (state) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `cities`);
  });
  builder.addCase(fetchServiceDepartureCities.fulfilled, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `cities`);
    state.cities = [...action.payload];
  });
};
