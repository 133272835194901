import { createAsyncThunk } from "@reduxjs/toolkit";
import { removeSessionToken } from "../../../api/api";
import { endpoints } from "../../../api/endpoints";
import { IUserResponse } from "../../../api/types/user/IUserResponse";

export const logoutUser = createAsyncThunk<IUserResponse, void>(
  "user/logout",
  async (param, thunkAPI) => {
    const result = await endpoints.auth.logout(thunkAPI.signal);
    if (result.status === "OK") {
      removeSessionToken();
    }
    return result;
  },
);
