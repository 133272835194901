import styled from "@emotion/styled";

export const InfoCellStyled = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 11px;
  text-align: center;
`;
