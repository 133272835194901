import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import { DirectoryCreateAction } from "../../../api/types/DirectoryCreateAction";
import { DirectoryEditAction } from "../../../api/types/DirectoryEditAction";

interface Params {
  name: string;
  operator: number;
  country: string;
  regions: string;
}

export const createTour = createAsyncThunk<void, Params>(
  "associate/createTour",
  async (params, thunkAPI) => {
    await endpoints.directory.createTour(
      {
        action: DirectoryCreateAction.createTour,
        ...params,
      },
      thunkAPI.signal,
    );
  },
);

interface EditParams extends Params {
  id: number | string;
}

export const editTour = createAsyncThunk<void, EditParams>(
  "associate/editTour",
  async (params, thunkAPI) => {
    await endpoints.directory.editTour(
      {
        action: DirectoryEditAction.editTour,
        ...params,
      },
      thunkAPI.signal,
    );
  },
);
