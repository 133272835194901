import { createAsyncThunk } from "@reduxjs/toolkit";
import { saveSessionToken } from "../../../api/api";
import { endpoints } from "../../../api/endpoints";
import { IAuthParams } from "../../../api/types/auth/IAuthParams";
import { IUser } from "../../../api/types/user/IUser";

export const loginUser = createAsyncThunk<IUser, IAuthParams>(
  "user/login",
  async (param, thunkAPI) => {
    const result = await endpoints.auth.login(param, thunkAPI.signal);
    if (result.session && result.user) {
      saveSessionToken(result.session, result.user);
      return result.user;
    } else if (result.error) throw new Error(result.error);
    else throw new Error("Ошибка авторизации! Неверный ответ сервера.");
  },
);
