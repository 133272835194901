import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiAction } from "../../../api/ApiAction";
import { endpoints } from "../../../api/endpoints";
import { IPansion } from "../../../api/types/directory/IPansion";
import { AppDispatch } from "../../appDispatch";
import { RootState } from "../../rootState";
import { IAssociateParams } from "./types/IAssociateParams";

export const fetchAssociatePansions = createAsyncThunk<
  IPansion[],
  IAssociateParams,
  { state: RootState; dispatch: AppDispatch }
>("associate/fetchPansions", async (params, thunkAPI) => {
  const result = await endpoints.directory.get(
    {
      action: ApiAction.getPansions,
      operator: params.operator,
      country: params.country,
      hotel_id: params.hotel_id,
    },
    thunkAPI.signal,
  );

  return result as IPansion[];
});
