import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AssociateType } from "../../../components/filter/types/AssociateType";
import { fetchAssociateResorts } from "../thunks/fetchAssociateResorts";
import { IAssociateState } from "../types/IAssociateState";
import { selectMatchingItems } from "../utils/selectMatchingItems";
import { updateSuggestedOnFetchComplete } from "./utils/updateSuggestedOnFetchComplete";

export const addFetchAssociateResorts = (builder: ActionReducerMapBuilder<IAssociateState>) => {
  builder.addCase(fetchAssociateResorts.pending, (state, action) => {
    state.loadingKeys = [...state.loadingKeys, `${action.meta.arg.operator}`];
  });
  builder.addCase(fetchAssociateResorts.rejected, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `${action.meta.arg.operator}`);
  });
  builder.addCase(fetchAssociateResorts.fulfilled, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `${action.meta.arg.operator}`);
    state.resorts[action.meta.arg.operator] = [...action.payload];

    if (action.meta.arg.noSuggestions !== true) {
      selectMatchingItems(
        state.operators.map((itm) => itm.id),
        state,
        state.tezTargets,
        action.meta.arg.operator,
        AssociateType.regions, // TODO: - check if this appropriate type
      );
      updateSuggestedOnFetchComplete(state, action.meta.arg.operator, action.payload);
    }
  });
};
