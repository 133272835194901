import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IActionErrorInfo } from "../../jobs/types/IActionErrorInfo";
import { createTour, editTour } from "../thunks/createTour";
import { IAssociateState } from "../types/IAssociateState";

export const addCreateTour = (
  builder: ActionReducerMapBuilder<IAssociateState>,
) => {
  builder.addCase(createTour.pending, (state, action) => {
    state.loadingKeys = [...state.loadingKeys, `${action.meta.arg.operator}`];
  });
  builder.addCase(createTour.rejected, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter(
      (itm) => itm !== `${action.meta.arg.operator}`,
    );
    const error: IActionErrorInfo = {
      id: Date.now(),
      message: `Не удалось сохранить тур "${action.meta.arg.name}"`,
    };
    state.errors = [...state.errors, error];
  });
  builder.addCase(createTour.fulfilled, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter(
      (itm) => itm !== `${action.meta.arg.operator}`,
    );
  });
};

export const addEditTour = (
  builder: ActionReducerMapBuilder<IAssociateState>,
) => {
  builder.addCase(editTour.pending, (state, action) => {
    state.loadingKeys = [...state.loadingKeys, `${action.meta.arg.operator}`];
  });
  builder.addCase(editTour.rejected, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter(
      (itm) => itm !== `${action.meta.arg.operator}`,
    );
    const error: IActionErrorInfo = {
      id: Date.now(),
      message: `Не удалось сохранить тур "${action.meta.arg.name}"`,
    };
    state.errors = [...state.errors, error];
  });
  builder.addCase(editTour.fulfilled, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter(
      (itm) => itm !== `${action.meta.arg.operator}`,
    );
  });
};
