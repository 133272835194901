import { LoadingButton } from "@mui/lab";
import { Button, Container, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { ICreateUserParams, IEditUserParams } from "../../api/types/user/ICreateUserParams";
import { IUser, UserStatus, UserType } from "../../api/types/user/IUser";
import { SelectField } from "../../components/select/SelectField";
import { SpacedContainerStyled } from "../../components/styled/SpacedContainerStyled";
import { UserEditorMode } from "../../components/template/types/UserEditorMode";
import { inputStyleVariant } from "../../constants/interface";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { createUser } from "../../store/user/thunks/createUser";
import { editUser } from "../../store/user/thunks/editUser";
import isEmail from "validator/lib/isEmail";
import isAlphanumeric from "validator/lib/isAlphanumeric";

interface Props {
  mode: UserEditorMode
  source?: IUser
  onClose: () => void
}

const minPassLength = 8;

export const UserEditor = ({ mode, source, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((s) => s.user.isLoading);
  const [username, setUsername] = useState(source?.username ?? "");
  const [email, setEmail] = useState(source?.email ?? "");
  const [fullName, setFullName] = useState(source?.fullname ?? "");
  const [status, setStatus] = useState(source?.status ?? "active");
  const [type, setType] = useState(source?.type ?? "user");
  const [phone, setPhone] = useState(source?.phone ?? "");
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [showPassword, setShowPassword] = useState(false);

  const [usernameError, setUsernameError] = useState<string | undefined>(undefined);
  const [passwordError, setPasswordError] = useState<string | undefined>(undefined);
  const [emailError, setEmailError] = useState<string | undefined>(undefined);

  const userCreated = useAppSelector(
    (s) => s.user.usersUpdated,
    (lhs, rhs) => lhs.join(",") === rhs.join(","),
  );

  useEffect(() => {
    if (userCreated.length > 0) {
      onClose();
    }
  }, [userCreated]);

  const hasValidPassword =
    (mode === "addUser" &&
      password &&
      password.length >= minPassLength &&
      passwordError === undefined) ||
    (mode === "editUser" && passwordError === undefined);
  const canSave =
    username.length > 0 &&
    email.length > 0 &&
    usernameError === undefined &&
    hasValidPassword &&
    emailError === undefined;

  const validateUsername = () => {
    let usernameError: string | undefined = undefined;
    if (username.length < 4) {
      usernameError = "Длина должна быть не менее 4х символов";
    } else if (!isAlphanumeric(username)) {
      usernameError = "Имя пользователя должно содержать только цифры и буквы латинского алфавита";
    }
    setUsernameError(usernameError);
  };

  const validateEmail = () => {
    const emailValid = isEmail(email);
    setEmailError(emailValid ? undefined : "Некорректный e-mail адрес");
  };

  const validatePassword = () => {
    if (mode === "addUser" && password === undefined) {
      setPasswordError("Пожалуйста, задайте пароль");
    } else if (password && password.trim().length < minPassLength) {
      setPasswordError(`Пароль должен быть не менее ${minPassLength} символов`);
    } else {
      setPasswordError(undefined);
    }
  };

  const onSave = () => {
    if (mode === "editUser" && source) {
      const updatedUser: IEditUserParams = {
        ...source,
        username,
        email,
        fullname: fullName,
        status,
        type,
        phone,
        password,
      };
      dispatch(editUser(updatedUser));
    }
    if (mode === "addUser" && password) {
      const userParams: ICreateUserParams = {
        username,
        password,
        email,
        type,
        fullname: fullName,
        phone,
      };
      dispatch(createUser(userParams));
    }
  };

  const usernameChanged = source ? source?.username !== username : false;
  const emailChanged = source ? source?.email !== email : false;
  const nameChanged = source ? source.fullname !== fullName : false;
  const phoneChanged = source ? source.phone !== phone : false;
  const statusChanged = source ? source.status !== status : false;
  const typeChanged = source ? source.type !== type : false;

  return (
    <Container maxWidth={"md"} component={"div"}>
      <Grid container spacing={1} rowSpacing={2} alignItems={"flex-end"}>
        <Grid item xs={12}>
          <TextField
            label={`Логин${source ? ` для ID: ${source.id}` : ""}${
              usernameChanged ? " (изменено)" : ""
            }`}
            error={usernameError !== undefined}
            required
            variant={inputStyleVariant}
            fullWidth
            disabled={loading}
            color={usernameChanged ? "secondary" : undefined}
            value={username}
            helperText={usernameError ?? "Минимум 4 символа"}
            onBlur={validateUsername}
            onChange={(e) => {
              const val = e.target.value ?? "";
              if (usernameError) {
                validateUsername();
              }
              setUsername(val.replace(/[^a-zA-Z0-9]+/g, "").trim());
            }}
            autoComplete={"username"}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label={`E-mail${emailChanged ? " (изменено)" : ""}`}
            required
            disabled={loading}
            error={emailError !== undefined}
            helperText={emailError}
            variant={inputStyleVariant}
            fullWidth
            color={emailChanged ? "secondary" : undefined}
            value={email}
            onBlur={validateEmail}
            onChange={(e) => {
              const val = e.target.value ?? "";
              if (emailError) {
                validateEmail();
              }
              setEmail(val.trim());
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={`Имя и фамилия${nameChanged ? " (изменено)" : ""}`}
            variant={inputStyleVariant}
            fullWidth
            disabled={loading}
            color={nameChanged ? "secondary" : undefined}
            value={fullName}
            onChange={(e) => {
              const val = e.target.value ?? "";
              setFullName(val);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={`Номер телефона${phoneChanged ? " (изменено)" : ""}`}
            variant={inputStyleVariant}
            fullWidth
            disabled={loading}
            color={phoneChanged ? "secondary" : undefined}
            type={"phone"}
            value={phone}
            onChange={(e) => {
              const val = e.target.value ?? "";
              const normalized = val.length > 0 ? `+${val.replace(/\D/g, "")}` : "";
              const length = normalized.length;
              const result = normalized
                .slice(0, 13)
                .split("")
                .map((char, index) => {
                  // +375 29 123 4567
                  // 0123 45 678 9.10.11.12
                  if (index === 3 && length !== 4) return `${char} (`;
                  if (index === 5 && length !== 6) return `${char}) `;
                  if (index === 8 && length !== 9) return `${char} `;
                  return char;
                })
                .join("");
              setPhone(result);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={`${source ? "Новый пароль" : "Пароль"}${password ? " (изменено)" : ""}`}
            variant={inputStyleVariant}
            fullWidth
            required={mode === "addUser"}
            disabled={loading}
            autoComplete={"new-password"}
            error={passwordError !== undefined}
            helperText={
              mode === "editUser"
                ? passwordError ?? "Введите новый пароль, если хотите изменить" + " текущий"
                : passwordError ?? `Минимум ${minPassLength} символов`
            }
            color={password ? "secondary" : undefined}
            value={password ?? ""}
            type={showPassword ? "text" : "password"}
            onBlur={validatePassword}
            onChange={(e) => {
              const val = e.target.value ?? "";
              if (passwordError) validatePassword();
              setPassword(val.trim().length > 0 ? val : undefined);
            }}
            InputProps={{
              autoComplete: "new-password",
              inputProps: {
                minLength: minPassLength,
                maxLength: 32,
              },
              endAdornment: password ? (
                <InputAdornment position="end">
                  <IconButton
                    size={"small"}
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                  </IconButton>
                </InputAdornment>
              ) : undefined,
            }}
          />
        </Grid>
        {mode === "editUser" ? (
          <Grid item xs={6}>
            <SelectField
              items={[
                { id: "active", name: "Активен" },
                { id: "blocked", name: "Заблокирован" },
              ]}
              disabled={loading}
              label={`Статус пользователя${statusChanged ? " (изменено)" : ""}`}
              value={status}
              onSelect={(val) => val && setStatus(val as UserStatus)}
            />
          </Grid>
        ) : null}

        <Grid item xs={mode === "editUser" ? 6 : 12}>
          <SelectField
            items={[
              { id: "user", name: "Пользователь" },
              { id: "admin", name: "Администратор" },
            ]}
            disabled={loading}
            label={`Роль${typeChanged ? " (изменено)" : ""}`}
            value={type}
            onSelect={(val) => val && setType(val as UserType)}
          />
        </Grid>
        <Grid item xs={12}>
          <SpacedContainerStyled>
            <Button variant="text" onClick={onClose}>
              Отмена
            </Button>
            <LoadingButton
              loading={loading}
              disabled={!canSave || loading}
              variant="contained"
              onClick={onSave}
            >
              {mode === "editUser" ? "Сохранить" : "Создать"}
            </LoadingButton>
          </SpacedContainerStyled>
        </Grid>
      </Grid>
    </Container>
  );
};
