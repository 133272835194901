import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import { ICreateReportResponse } from "../../../api/types/reports/ICreateReportResponse";
import { IReportParams } from "../../../api/types/reports/IReportParams";
import { ReportStatus } from "../types/reportStatus";

export const createReportFromTemplate = createAsyncThunk<ICreateReportResponse, IReportParams>(
  "reports/createFromTemplate",
  async (param, thunkAPI) => {
    const result = await endpoints.report.create(
      { ...param, status: ReportStatus.pending },
      thunkAPI.signal,
    );
    if (result.reportID) {
      return result;
    } else {
      throw new Error(result.error ?? `Не удалось создать шаблон "${param.name}"`);
    }
  },
);
