import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiAction } from "../../../api/ApiAction";
import { endpoints } from "../../../api/endpoints";
import { IRegion } from "../../../api/types/directory/IRegion";
import { RootState } from "../../rootState";

interface Params {
  operator: number;
  departureCity?: number | string;
  destinationCountry: number | string;
  tour_id: number | string;
}

export const fetchServiceRegions = createAsyncThunk<IRegion[], Params, { state: RootState }>(
  "services/getRegions",
  async (param, thunkAPI) => {
    const result = await endpoints.directory.get(
      {
        action: ApiAction.getRegions,
        operator: param.operator,
        departure: param.departureCity,
        country: param.destinationCountry,
        tour_id: param.tour_id,
      },
      thunkAPI.signal,
    );

    return result as IRegion[];
  },
);
