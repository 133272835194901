import { CircularProgress, TableRow } from "@mui/material";
import React from "react";
import { StyledTableCell } from "../../styled/TableCell.styled";

interface Props {
  colSpan: number
  loading: boolean
}
export const LoadingRow = ({ colSpan, loading }: Props) => {
  return (
    <TableRow>
      <StyledTableCell colSpan={colSpan} align={"center"}>
        {loading ? <CircularProgress color="inherit" /> : "Нет подходящих результатов"}
      </StyledTableCell>
    </TableRow>
  );
};
