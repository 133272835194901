import { AssociateType } from "../../../components/filter/types/AssociateType";
import { IAssociateState } from "../types/IAssociateState";

export const resetAssociated = (
  state: IAssociateState,
  {
    withFilters,
    associatedType,
  }: { withFilters: boolean; associatedType?: AssociateType },
) => {
  if (associatedType === AssociateType.roomTypes) {
    state.associated = {};
    state.tezTargets = [];
    return;
  }

  state.associated = {};
  state.tezTargets = [];
  state.suggested = {};
  state.pansions = [];
  if (withFilters) {
    state.filterResort = {};
    state.filterHotel = {};
  }
};
