import { ReportStatus } from "../../../store/reports/types/reportStatus";

export const titleForReportStatus = (status: ReportStatus | -1): string => {
  if (status === -1) return "Все статусы";
  switch (status) {
    case ReportStatus.active:
      return "Активная";
    case ReportStatus.deleted:
      return "Удалена";
    case ReportStatus.finished:
      return "Завершена";
    case ReportStatus.pending:
      return "В очереди";
    case ReportStatus.stopped:
      return "Остановлена";
    case ReportStatus.template:
      return "Шаблон";
    case ReportStatus.imported:
      return "Импортирована";
    default:
      return "Неизвестный";
  }
};
