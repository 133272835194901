import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IActionErrorInfo } from "../../jobs/types/IActionErrorInfo";
import { editReportTemplate } from "../thunks/editReportTemplate";
import { IReportsState } from "../types/IReportsState";
import { ReportStatus } from "../types/reportStatus";
import { mapReportPramsToReportInfo } from "./utils/mapReportPramsToReportInfo";

export const addEditReportTemplate = (builder: ActionReducerMapBuilder<IReportsState>) => {
  builder.addCase(editReportTemplate.pending, (state, action) => {
    state.loadingReportKeys = [...state.loadingReportKeys, `${action.meta.arg.id}`];
    state.reportsCreating = true;
  });
  builder.addCase(editReportTemplate.rejected, (state, action) => {
    state.reportsCreating = false;
    state.loadingReportKeys = state.loadingReportKeys.filter(
      (id) => id !== `${action.meta.arg.id}`,
    );
    const error: IActionErrorInfo = {
      id: Date.now(),
      message: `Ошибка редактирования ${
        action.meta.arg.status === ReportStatus.template ? "шаблона" : "задачи"
      } ID: ${action.meta.arg.id}!: ${action.error.message}`,
    };
    state.errors = [...state.errors, error];
  });
  builder.addCase(editReportTemplate.fulfilled, (state, action) => {
    state.reportsCreating = false;
    state.loadingReportKeys = state.loadingReportKeys.filter(
      (id) => id !== `${action.meta.arg.id}`,
    );
    state.templates = state.templates.map((r) => {
      if (r.id == action.meta.arg.id) {
        return mapReportPramsToReportInfo(r, action.meta.arg);
      }
      return r;
    });
  });
};
