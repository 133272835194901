import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import { ICreateJobResponse } from "../../../api/types/jobs/ICreateJobResponse";
import { IJobInfo } from "../../../api/types/jobs/IJobInfo";
import { IJobParams } from "../../../api/types/jobs/IJobParams";
import { JobStatus } from "../../../api/types/jobs/JobStatus";
import { AppDispatch } from "../../appDispatch";
import { RootState } from "../../rootState";
import { jobParamsFromInfo } from "./utils/jobParamsFromInfo";

export const createJobFromTemplateID = createAsyncThunk<
  ICreateJobResponse,
  IJobInfo,
  { state: RootState; dispatch: AppDispatch }
>("jobs/createNew", async (param, thunkAPI) => {
  const params: IJobParams = jobParamsFromInfo(param, false);

  const response = await endpoints.job.create(
    { ...params, status: JobStatus.pending },
    thunkAPI.signal,
  );
  if (response.jobID) {
    return response;
  } else throw new Error(response?.error ?? `Не удалось запустить задачу "${params.name}"`);
});
