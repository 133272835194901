import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import { DirectoryCreateAction } from "../../../api/types/DirectoryCreateAction";
import { RootState } from "../../rootState";

interface Params {
  action: DirectoryCreateAction;
  hotels: number[];
  price?: string;
}
export const createOrEditService = createAsyncThunk<void, Params, { state: RootState }>(
  "services/createTransfer",
  async (params, thunkAPI) => {
    const s = thunkAPI.getState().services.selection;
    if (!s.operator || !s.country || !s.region || !s.cityFrom || !s.tour) {
      throw new Error("Не удалось создать сервис! Не указаны необходимые данные!");
    }
    const operator = s.operator.id;
    const country = s.country.id;
    const region_id = s.region.id;
    const city_from = s.cityFrom.id;
    const tour_id = s.tour.id;

    await endpoints.directory.createService(
      { ...params, operator, country, region_id, city_from, tour_id },
      thunkAPI.signal,
    );
  },
);
