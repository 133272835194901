import { createTheme, PaletteColor } from "@mui/material";
import { Color } from "./constants/colors";

const primaryColor: PaletteColor = {
  contrastText: Color.text.onDark,
  dark: Color.brand,
  light: Color.brand,
  main: Color.brand,
};

export const theme = createTheme({
  palette: {
    primary: primaryColor,
  },
});
