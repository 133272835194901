import { LoadingButton } from "@mui/lab";
import { Alert, Autocomplete, Button, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdCheck, MdLink } from "react-icons/md";
import { IDestinationCity } from "../../../api/types/directory/IDestinationCity";
import { IHotelExtended } from "../../../api/types/directory/IHotel";
import { IHotelCategory } from "../../../api/types/directory/IHotelCategory";
import { IResort } from "../../../api/types/directory/IResort";
import { ColumnBodyStyled, ColumnStyled } from "../../../components/columns/ColumnBodyStyled";
import { AutoCompleteItemStyled } from "../../../components/selectList/styled/ListItem.styled";
import { SpacedContainerStyled } from "../../../components/styled/SpacedContainerStyled";
import { fetchAssociateDestinationCities } from "../../../store/associate/thunks/fetchAssociateDestinationCities";
import { fetchAssociateHotelCategories } from "../../../store/associate/thunks/fetchAssociateHotelCategories";
import { fetchAssociateResorts } from "../../../store/associate/thunks/fetchAssociateResorts";
import { postHotelChanges } from "../../../store/associate/thunks/postHotelChanges";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { hasTezID } from "../../../utils/hasTezID";

export const HotelEditor = ({ hotel }: { hotel?: IHotelExtended }) => {
  const dispatch = useAppDispatch();

  const [isSaving, setIsSaving] = useState(false);

  const [name, setName] = useState<string | null>(null);
  const [resort, setResort] = useState<IResort | null>(null);
  const [category, setCategory] = useState<IHotelCategory | null>(null);
  const [destinationCity, setDestinationCity] = useState<IDestinationCity | null>(null);
  const [note, setNote] = useState<string | null>(null);

  const resorts = useAppSelector((s) => s.associate.resorts[10]);
  const destinationCities = useAppSelector((s) => s.associate.destinationCities[10]);
  const categories = useAppSelector((s) => s.associate.tezHotelCategories);

  const defaultResort =
    resorts && hotel ? resorts.find((itm) => itm.id == hotel.resort_id) ?? null : null;

  const defaultCity =
    destinationCities && hotel
      ? destinationCities.find((itm) => itm.id == hotel.city_id) ?? null
      : null;

  const defaultCategory =
    categories.length > 0 && hotel ? categories.find((itm) => itm.id == hotel.category_id) : null;

  useEffect(() => {
    if (!resorts && hotel) {
      dispatch(
        fetchAssociateResorts({
          country: hotel.country_id,
          operator: hotel.operator_id,
          noSuggestions: true,
        }),
      );
    }
    if (!destinationCities && hotel) {
      dispatch(
        fetchAssociateDestinationCities({
          country: hotel.country_id,
          operator: hotel.operator_id,
          noSuggestions: true,
        }),
      );
    }
    if (categories.length === 0 && hotel) {
      dispatch(
        fetchAssociateHotelCategories({
          country: hotel.country_id,
          operator: hotel.operator_id,
          noSuggestions: true,
        }),
      );
    }
  }, [hotel]);
  const onCancelPress = () => {
    setName(null);
    setResort(null);
    setCategory(null);
    setDestinationCity(null);
    setNote(null);
    setIsSaving(false);
  };

  const onSavePress = () => {
    if (hotel) {
      setIsSaving(true);
      console.log("category name: ", category, hotel.stars);
      dispatch(
        postHotelChanges({
          id: hotel.id,
          name: name ?? hotel.name,
          city_id: destinationCity?.id ?? hotel.city_id,
          resort_id: resort?.id ?? hotel.resort_id,
          category_id: category?.id ?? hotel.category_id,
          note: note ?? hotel.note,
          stars: category?.name ?? hotel.stars,
        }),
      ).then(() => onCancelPress());
    }
  };

  const saveEnabled =
    name !== null ||
    resort !== null ||
    category !== null ||
    destinationCity !== null ||
    name !== null ||
    note !== null;

  return (
    <Grid item xs={2} sx={{ marginBottom: "auto" }}>
      <ColumnStyled fullWidth={true}>
        <h5>Редактор</h5>

        {!hotel ? (
          <Alert severity={"info"}>
            Выберите хотя бы один отель TezTour, чтобы редактировать его свойства.
          </Alert>
        ) : (
          <ColumnBodyStyled>
            <TextField
              label={"Название"}
              variant={"filled"}
              fullWidth
              size={"small"}
              value={name ?? hotel.name}
              onChange={(e) =>
                setName(e.target.value && e.target.value.length > 0 ? e.target.value : null)
              }
            />

            <Autocomplete
              // sx={{ marginBottom: "8px" }}
              size={"small"}
              options={resorts ?? []}
              disableClearable={false}
              getOptionLabel={(option) => `${option.name} [${option.id}]`}
              value={resort || defaultResort || null}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(e, val) => setResort(val)}
              renderOption={(props, option, state) => (
                <AutoCompleteItemStyled
                  key={`${option.name}.${option.id}`}
                  {...props}
                  selected={state.selected}
                  highlighted={hasTezID(option.tez_id)}
                >
                  {hasTezID(option.tez_id) ? <MdLink /> : null}
                  {state.selected ? <MdCheck /> : null}
                  {option.name}
                  <small>id:{option.id}</small>
                </AutoCompleteItemStyled>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size={"small"}
                  variant={"filled"}
                  label={"Курорт"}
                  placeholder={"Курорт"}
                />
              )}
            />

            <Autocomplete
              // sx={{ marginBottom: "8px" }}
              size={"small"}
              options={destinationCities ?? []}
              disableClearable={false}
              getOptionLabel={(option) => `${option.name} [${option.id}]`}
              value={destinationCity || defaultCity || null}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(e, val) => setDestinationCity(val)}
              renderOption={(props, option, state) => (
                <AutoCompleteItemStyled
                  key={`${option.name}.${option.id}`}
                  {...props}
                  selected={state.selected}
                  highlighted={hasTezID(option.tez_id)}
                >
                  {hasTezID(option.tez_id) ? <MdLink /> : null}
                  {state.selected ? <MdCheck /> : null}
                  {option.name}
                  <small>id:{option.id}</small>
                </AutoCompleteItemStyled>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size={"small"}
                  variant={"filled"}
                  label={"Город прибытия"}
                  placeholder={"Город прибытия"}
                />
              )}
            />
            <Autocomplete
              // sx={{ marginBottom: "8px" }}
              size={"small"}
              options={categories ?? []}
              disableClearable={false}
              getOptionLabel={(option) => `${option.name} [${option.id}]`}
              value={category || defaultCategory || null}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(e, val) => setCategory(val)}
              renderOption={(props, option, state) => (
                <AutoCompleteItemStyled
                  key={`${option.name}.${option.id}`}
                  {...props}
                  selected={state.selected}
                >
                  {state.selected ? <MdCheck /> : null}
                  {option.name}
                  <small>id:{option.id}</small>
                </AutoCompleteItemStyled>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size={"small"}
                  variant={"filled"}
                  label={"Категория отеля"}
                  placeholder={hotel.stars ?? "Категория отеля"}
                />
              )}
            />

            <TextField
              size={"small"}
              multiline={true}
              label={"Примечание"}
              variant={"filled"}
              fullWidth
              value={note ?? hotel.note}
              onChange={(e) =>
                setNote(e.target.value && e.target.value.length > 0 ? e.target.value : null)
              }
            />
            <SpacedContainerStyled>
              <Button variant="text" onClick={onCancelPress} size={"small"}>
                Отмена
              </Button>
              <LoadingButton
                loading={isSaving}
                disabled={!saveEnabled || isSaving}
                variant={"contained"}
                size={"small"}
                onClick={onSavePress}
              >
                Сохранить
              </LoadingButton>
            </SpacedContainerStyled>
          </ColumnBodyStyled>
        )}
      </ColumnStyled>
    </Grid>
  );
};
