import { Autocomplete, Button, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { MdClear } from "react-icons/md";
import { useSelector } from "react-redux";
import { IOperator } from "../../../api/types/directory/IOperator";
import { FilterRowStyled } from "../../../components/filter/styled/FilterRow.styled";
import { reportStatusSelectItems } from "../../../components/filter/utils/reportStatusSelectItems";
import { SelectField } from "../../../components/select/SelectField";
import { inputStyleVariant } from "../../../constants/interface";
import { DirectoryTarget } from "../../../store/directory/thunks/directoryTarget";
import { fetchDepartureCity } from "../../../store/directory/thunks/fetchDepartureCity";
import { fetchDestinationCountries } from "../../../store/directory/thunks/fetchDestinationCountries";
import { fetchOperators } from "../../../store/directory/thunks/fetchOperators";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  resetReportFilters,
  setReportFilters,
} from "../../../store/reports/reportsSlice";
import {
  getReportList,
  refreshReportList,
} from "../../../store/reports/thunks/getReportList";
import { IReportFilters } from "../../../store/reports/types/IReportFilters";
import { RootState } from "../../../store/rootState";

interface Props {
  mode: "templates" | "reports";
}

export const ReportsFilter = ({ mode }: Props) => {
  const dispatch = useAppDispatch();

  const isLoggedIn = useAppSelector(
    (s) => s.user.isLoggedIn && s.user.info !== null,
  );

  const updated = useAppSelector(
    (s) => {
      return (
        s.reports.reportsCreating === false &&
        (mode === "templates"
          ? s.reports.templates.length
          : s.reports.reports.length) > 0
      );
    },
    (l, r) => l === r,
  );

  const operators = useAppSelector((s) => s.reports.filters.operators);
  const operatorsSelected = useAppSelector((s) => {
    const val = s.reports.filters.operators;
    if (val) {
      let ids: number[] = [];
      if (typeof val === "string") {
        ids = val.split(",").map((id) => parseInt(id, 10));
      } else if (typeof val === "number") {
        ids = [val];
      }
      if (ids.length === 0) return [];
      return s.directory.operators?.filter((o) => ids.includes(o.id)) ?? [];
    }
    return [];
  });
  const departureCity = useAppSelector((s) => s.reports.filters.city);
  const destinationCountry = useAppSelector((s) => s.reports.filters.country);
  const status = useAppSelector((s) => s.reports.filters.status);

  const operatorList = useAppSelector((s) => s.directory.operators ?? []);
  const departureCityList = useAppSelector(
    (s) => s.directory.filterData.departureCities ?? [],
  );
  const destinationCountryList = useAppSelector((s) => {
    const result = s.directory.filterData.destinationCountries;
    return result ? [{ id: -1, name: "Все" }, ...result] : [];
  });

  const loadingKeys = useSelector(
    (state: RootState) => state.directory.loadingKeys,
  );

  useEffect(() => {
    if (isLoggedIn) {
      const token = dispatch(getReportList({ mode }));
      return () => token.abort();
    }
  }, [operators, departureCity, destinationCountry, status, mode, isLoggedIn]);

  useEffect(() => {
    if (mode === "reports") {
      const interval = setInterval(() => dispatch(refreshReportList()), 60000);
      return () => clearInterval(interval);
    }
  }, [mode]);

  useEffect(() => {
    if (updated) {
      if (mode === "reports") {
        const token = dispatch(refreshReportList());
        return () => token.abort();
      }
      if (mode === "templates") {
        const token = dispatch(getReportList({ mode }));
        return () => token.abort();
      }
    }
  }, [updated]);

  useEffect(() => {
    const tOperators = dispatch(fetchOperators());
    const tCity = dispatch(fetchDepartureCity({ forFilter: true }));
    const tCountry = dispatch(
      fetchDestinationCountries({ target: DirectoryTarget.filter }),
    );
    return () => {
      tOperators.abort();
      tCity.abort();
      tCountry.abort();
    };
  }, []);

  const updateFilters = (data: Partial<IReportFilters>) => {
    dispatch(
      setReportFilters({
        operators: Object.hasOwn(data, "operators")
          ? data.operators
          : operators,
        city: Object.hasOwn(data, "city") ? data.city : departureCity,
        country: Object.hasOwn(data, "country")
          ? data.country
          : destinationCountry,
        status: data.status ?? status,
      }),
    );
  };

  const onOperatorSelect = (operators: IOperator[]) => {
    updateFilters({ operators: operators.map((o) => o.id).join(",") });
  };

  const onDepartureCitySelect = (id: string) => {
    updateFilters({
      city: id,
    });
  };

  const onDestinationCountrySelect = (id: string) => {
    updateFilters({
      country: id,
    });
  };

  const resetFilter = () => {
    dispatch(resetReportFilters());
  };

  const resetButtonDisabled = () => {
    const noFilters =
      destinationCountry === undefined &&
      departureCity === undefined &&
      operators === undefined;
    return mode === "reports" ? noFilters && status === -1 : noFilters;
  };

  const operatorsLoading = loadingKeys.includes("operators");
  const departureCitiesDisabled = departureCityList.length === 0;
  const destCountriesDisabled = destinationCountryList.length === 0;

  return (
    <FilterRowStyled>
      <Autocomplete
        multiple
        fullWidth
        options={operatorList}
        loading={operatorsLoading}
        isOptionEqualToValue={(lhs, rhs) => lhs.id === rhs.id}
        getOptionLabel={(option) => option.name}
        value={operatorsSelected}
        onChange={(_event, newValue) => {
          onOperatorSelect(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={inputStyleVariant}
            label="Операторы"
            placeholder="Операторы"
          />
        )}
      />
      <SelectField
        items={departureCityList}
        loading={loadingKeys.includes("departureCities")}
        label={"Город вылета"}
        value={departureCity}
        disabled={departureCitiesDisabled}
        onSelect={(val) => onDepartureCitySelect(val as string)}
        canClear={true}
      />
      <SelectField
        items={destinationCountryList}
        disabled={destCountriesDisabled}
        label={"Страна назначения"}
        value={destinationCountry}
        loading={loadingKeys.includes("destinationCountries")}
        onSelect={(val) => onDestinationCountrySelect(val as string)}
        canClear={true}
      />
      {mode === "reports" ? (
        <SelectField
          items={reportStatusSelectItems}
          label={"Статус"}
          value={status}
          defaultValue={-1}
          onSelect={(val) => updateFilters({ status: val as number })}
        />
      ) : null}
      <Button
        variant={"outlined"}
        onClick={() => resetFilter()}
        disabled={resetButtonDisabled()}
      >
        <MdClear />
      </Button>
    </FilterRowStyled>
  );
};
