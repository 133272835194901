import { IServicesState } from "./types/IServicesState";

export const initialState: IServicesState = {
  gross: {
    selectedOperators: [],
    data: {},
  },
  marginNotes: [],
  selection: {
    cityFrom: null,
    region: null,
    country: null,
    operator: null,
    resort: null,
    hotels: [],
    tour: null,
  },
  cities: [],
  countries: [],
  regions: [],
  hotels: [],
  resorts: [],
  transfers: {},
  margin: {},
  insurance: {},
  tourFilters: [],
  loadingKeys: [],
  errors: [],
};
