import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiAction } from "../../../api/ApiAction";
import { endpoints } from "../../../api/endpoints";
import { IHotelCategory } from "../../../api/types/directory/IHotelCategory";
import { AppDispatch } from "../../appDispatch";
import { RootState } from "../../rootState";
import { IAssociateParams } from "./types/IAssociateParams";

export const fetchAssociateHotelCategories = createAsyncThunk<
  IHotelCategory[],
  IAssociateParams,
  { state: RootState; dispatch: AppDispatch }
>("associate/fetchHotelCategories", async (params, thunkAPI) => {
  const result = await endpoints.directory.get(
    {
      action: ApiAction.getHotelCategories,
      operator: params.operator,
      country: params.country,
    },
    thunkAPI.signal,
  );

  return result as IHotelCategory[];
});
