import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDepartureCity } from "../../api/types/directory/IDepartureCity";
import { IDestinationCountry } from "../../api/types/directory/IDestinationCountry";
import { IHotel } from "../../api/types/directory/IHotel";
import { IOperator } from "../../api/types/directory/IOperator";
import { IRegion } from "../../api/types/directory/IRegion";
import { ITourFilter } from "../../api/types/directory/ITourFilter";
import { addCreateOrEditService } from "./extraReducers/addCreateOrEditService";
import { addFetchMarginNotes } from "./extraReducers/addFetchMarginNotes";
import { addFetchServiceDepartureCities } from "./extraReducers/addFetchServiceDepartureCities";
import { addFetchServiceRegions } from "./extraReducers/addFetchServiceRegions";
import { addFetchServiceDestinationCountries } from "./extraReducers/addFetchServiceDestinationCountries";
import { addFetchServiceHotels } from "./extraReducers/addFetchServiceHotels";
import { addFetchServiceTourFilters } from "./extraReducers/addFetchServiceTourFilters";
import { addFetchInsuranceInfo } from "./extraReducers/addFetchInsuranceInfo";
import { addFetchMarginInfo } from "./extraReducers/addFetchMarginInfo";
import { addFetchTransfersInfo } from "./extraReducers/addFetchTransfersInfo";
import { AddFetchGross } from "./extraReducers/addFetchGross";
import { initialState } from "./initialState";
import { MarginNotes } from "./types/marginNotes";

const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    resetServices: () => initialState,

    setServiceHotel: (state, action: PayloadAction<IHotel[]>) => {
      state.selection.hotels = action.payload;
    },
    setServiceTour: (state, action: PayloadAction<ITourFilter | null>) => {
      state.selection.tour = action.payload;
    },
    setServiceOperator: (state, action: PayloadAction<IOperator | null>) => {
      state.selection.operator = action.payload;
    },
    setServiceCity: (state, action: PayloadAction<IDepartureCity | null>) => {
      state.selection.cityFrom = action.payload;
    },
    setServiceRegion: (state, action: PayloadAction<IRegion | null>) => {
      state.selection.region = action.payload;
    },
    setServiceDestinationCountry: (
      state,
      action: PayloadAction<IDestinationCountry | null>,
    ) => {
      state.selection.country = action.payload;
    },
    removeServicesError: (state, action: PayloadAction<number>) => {
      state.errors = state.errors.filter((j) => j.id != action.payload);
    },
    resetGrossData: (state) => {
      state.gross.data = {};
    },
    setGrossOperators: (state, action: PayloadAction<IOperator[]>) => {
      state.gross.selectedOperators = action.payload;
    },
    updateMarginNote: (state, action: PayloadAction<MarginNotes>) => {
      const marginNoteIndex = state.marginNotes.findIndex(
        (el) => el.id === action.payload.id,
      );
      state.marginNotes[marginNoteIndex] = action.payload;
    },
  },
  extraReducers: (builder) => {
    AddFetchGross(builder);
    addFetchMarginNotes(builder);
    addFetchServiceHotels(builder);
    addFetchServiceTourFilters(builder);
    addFetchServiceDepartureCities(builder);
    addFetchServiceRegions(builder);
    addFetchServiceDestinationCountries(builder);
    addFetchInsuranceInfo(builder);
    addFetchMarginInfo(builder);
    addFetchTransfersInfo(builder);
    addCreateOrEditService(builder);
  },
});

export const {
  resetServices,
  resetGrossData,
  setGrossOperators,
  setServiceHotel,
  setServiceTour,
  setServiceOperator,
  setServiceRegion,
  setServiceDestinationCountry,
  setServiceCity,
  removeServicesError,
  updateMarginNote,
} = servicesSlice.actions;
export default servicesSlice.reducer;
