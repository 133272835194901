import { deleteJob } from "../thunks/deleteJob";
import { IActionErrorInfo } from "../types/IActionErrorInfo";
import { JobsExtraBuilder } from "./types/jobsExtraBuilder";

export const addDeleteJob = (builder: JobsExtraBuilder) => {
  builder.addCase(deleteJob.pending, (state, action) => {
    state.loadingJobKeys = [...state.loadingJobKeys, action.meta.arg.id];
  });
  builder.addCase(deleteJob.rejected, (state, action) => {
    if (state.loadingJobKeys) {
      state.loadingJobKeys = state.loadingJobKeys.filter((id) => id !== action.meta.arg.id);
    }
    const error: IActionErrorInfo = {
      id: Date.now(),
      message: `Ошибка удаления шаблона/задачи ID: ${action.meta.arg.id}!: ${action.error.message}`,
    };
    state.jobErrors = state.jobErrors ? [...state.jobErrors, error] : [error];
  });
  builder.addCase(deleteJob.fulfilled, (state, action) => {
    if (state.loadingJobKeys) {
      state.loadingJobKeys = state.loadingJobKeys.filter((id) => id !== action.meta.arg.id);
    }
    state.allJobs = state.allJobs.filter((j) => j.id !== action.meta.arg.id);
  });
};
