import { TableHead, TableRow } from "@mui/material";
import React from "react";
import { StyledTableCell } from "../../../components/styled/TableCell.styled";

export function ReportTemplatesHeader() {
  return (
    <TableHead>
      <TableRow>
        <StyledTableCell>
          Название <br />
          <small>Кем создан</small>
          <br />
          <small>Тип </small>
        </StyledTableCell>
        <StyledTableCell>Страна</StyledTableCell>
        <StyledTableCell>Город вылета</StyledTableCell>
        <StyledTableCell>Операторы</StyledTableCell>
        <StyledTableCell>Описание</StyledTableCell>
        <StyledTableCell sx={{ width: "100px" }}>
          Формирование
          <br />
          <small>Последнее</small>
          <br />
          <small>Следующее</small>
        </StyledTableCell>
        <StyledTableCell align={"right"}>Управление</StyledTableCell>
      </TableRow>
    </TableHead>
  );
}
