import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IHotel } from "../../api/types/directory/IHotel";
import { IOperator } from "../../api/types/directory/IOperator";
import { IRegion } from "../../api/types/directory/IRegion";
import { ITourFilter } from "../../api/types/directory/ITourFilter";
import { AssociateType } from "../../components/filter/types/AssociateType";
import { ISelectItem } from "../../components/selectList/types/ISelectItem";
import {
  addBindDirectoryItem,
  addUnbindDirectoryItem,
} from "./extraReducers/addBindDirectoryItem";
import {
  addCreateRegion,
  addEditRegion,
} from "./extraReducers/addCreateRegion";
import { addCreateTour, addEditTour } from "./extraReducers/addCreateTour";
import { addFetchAssociateDestinationCities } from "./extraReducers/addFetchAssociateDestinationCities";
import { addFetchAssociateHotelCategories } from "./extraReducers/addFetchAssociateHotelCategories";
import { addFetchAssociateHotels } from "./extraReducers/addFetchAssociateHotels";
import { addFetchAssociatePansions } from "./extraReducers/addFetchAssociatePansions";
import { addFetchAssociateRegions } from "./extraReducers/addFetchAssociateRegions";
import { addFetchAssociateResorts } from "./extraReducers/addFetchAssociateResorts";
import { addFetchAssociateRoomTypes } from "./extraReducers/addFetchAssociateRoomTypes";
import { addFetchAssociateTourFilters } from "./extraReducers/addFetchAssociateTourFilters";
import { addFetchAssociateTourTypes } from "./extraReducers/addFetchAssociateTourTypes";
import { addPostHotelChanges } from "./extraReducers/addPostHotelChanges";
import { addPostRoomChanges } from "./extraReducers/addPostRoomChanges";
import { initialState } from "./initialState";
import { ISetAssociatedPayload } from "./types/ISetAssociatedPayload";
import { cleanupOperatorData } from "./utils/cleanupOperatorData";
import { getAllForOperator } from "./utils/getAllForOperator";
import { getSuggestedForItems } from "./utils/getSuggestedForItems";
import { resetAssociated } from "./utils/resetAssociated";
import { selectMatchingItems } from "./utils/selectMatchingItems";
import { setMatchingItemFilter } from "./utils/setMatchingItemFilter";

export const associateSlice = createSlice({
  name: "associate",
  initialState,
  reducers: {
    resetAssociateState: () => initialState,
    resetAssociatedItems: (
      state,
      action: PayloadAction<{
        withFilters: boolean;
        associatedType?: AssociateType;
      }>,
    ) => {
      resetAssociated(state, action.payload);
    },
    setAssociateOperators: (
      state,
      action: PayloadAction<{ items: IOperator[]; type: AssociateType }>,
    ) => {
      const ids = action.payload.items.map((itm) => itm.id);
      const removed = state.operators.filter((itm) => !ids.includes(itm.id));
      state.operators = [...action.payload.items];
      for (const operator of removed) {
        cleanupOperatorData(operator.id, state, true);
      }

      const filterItem = state.filterHotel[10];
      if (filterItem) {
        setMatchingItemFilter(10, filterItem, state, "hotel");
      }
    },
    /// Selecting items only for Tez
    setTezAssociateTargets: (
      state,
      action: PayloadAction<{ type: AssociateType; items: ISelectItem[] }>,
    ) => {
      const { items, type } = action.payload;
      const isPansionsOrRoomTypes =
        type === AssociateType.pansions || type === AssociateType.roomTypes;

      state.tezTargets = [...items];

      // Select associated items for other operators
      const operatorIDs = state.operators.map((itm) => itm.id);
      selectMatchingItems(operatorIDs, state, items, 10, type);

      const allTez = getAllForOperator(10, type, state);

      if (!isPansionsOrRoomTypes) {
        state.suggested[10] = getSuggestedForItems(
          items,
          allTez,
          state.tezTargets,
        );
      }

      for (const id of operatorIDs) {
        const all = getAllForOperator(id, type, state);
        const selected = state.associated[id] ?? [];

        if (!isPansionsOrRoomTypes) {
          state.suggested[id] = getSuggestedForItems(items, all, selected);
        }
      }

      if (items.length === 0) {
        state.associated = {};
      }
    },
    /// Selecting items for the rest of the operators
    setAssociatedForOperator: (
      state,
      action: PayloadAction<ISetAssociatedPayload>,
    ) => {
      const { operator, type } = action.payload;
      const isPansionsOrRoomTypes =
        type === AssociateType.pansions || type === AssociateType.roomTypes;

      state.associated[operator] = [...action.payload.items];

      const operatorIds = state.operators
        .map((itm) => itm.id)
        .filter((itm) => itm !== operator);
      operatorIds.push(10);
      selectMatchingItems(
        operatorIds,
        state,
        action.payload.items,
        operator,
        type,
      );

      const noTezItems = state.tezTargets.length === 0;
      const operatorIDs = noTezItems
        ? state.operators.map((itm) => itm.id)
        : [operator];
      const items = noTezItems ? action.payload.items : state.tezTargets;

      if (noTezItems) {
        operatorIDs.push(10);
      }

      if (!isPansionsOrRoomTypes) {
        for (const id of operatorIDs) {
          const all = getAllForOperator(id, type, state);
          const selected =
            id === operator ? action.payload.items : state.associated[id] ?? [];
          state.suggested[id] = getSuggestedForItems(items, all, selected);
        }
      }
    },
    setAssociateCountry: (state, action: PayloadAction<string>) => {
      if (state.country !== action.payload) {
        state.hotels = {};
        state.pansions = {};
        state.country = action.payload;
        state.resorts = {};
        state.rooms = {};
        state.destinationCities = {};
        state.filterHotel = {};
        state.filterResort = {};
      }
    },
    setAssociateHotelFilter: (
      state,
      action: PayloadAction<{ operatorID: number; item: IHotel | null }>,
    ) => {
      if (action.payload.item) {
        state.filterHotel[action.payload.operatorID] = action.payload.item;
        // Pansions is dependent on selected hotel, if we change it we need to reset all
        // dependent filters.
        delete state.pansions[action.payload.operatorID];

        // Find linked items and set them to other operators
        setMatchingItemFilter(
          action.payload.operatorID,
          action.payload.item,
          state,
          "hotel",
        );
      } else {
        if (action.payload.operatorID === 10) {
          state.filterHotel = {};
          state.pansions = {};
          state.suggested = {};
        } else {
          delete state.filterHotel[action.payload.operatorID];
          delete state.pansions[action.payload.operatorID];
          delete state.suggested[action.payload.operatorID];
        }
      }
    },
    setAssociateRegionFilter: (
      state,
      action: PayloadAction<{ operatorID: number; item: IRegion | null }>,
    ) => {
      if (action.payload.item) {
        state.filterRegion[action.payload.operatorID] = action.payload.item;
        // Tour, Hotel and Pansions are dependent on selected region, if we change it we need to reset all
        // dependent filters.
        state.filterTour = {};
        state.filterHotel = {};
        state.pansions = {};

        // Find linked items and set them to other operators
        setMatchingItemFilter(
          action.payload.operatorID,
          action.payload.item,
          state,
          "region",
        );
      } else {
        if (action.payload.operatorID === 10) {
          state.filterRegion = {};
        } else {
          delete state.filterRegion[action.payload.operatorID];
        }

        // We don't need to delete other filters here as resetting current filter doesn't narrow
        // down choice for other filters.
      }
    },
    setAssociateTourFilter: (
      state,
      action: PayloadAction<{ operatorID: number; item: ITourFilter | null }>,
    ) => {
      if (action.payload.item) {
        state.filterTour[action.payload.operatorID] = action.payload.item;
        // Hotel and Pansions are dependent on selected tour, if we change it we need to reset all dependent
        // filters.
        state.filterHotel = {};
        state.pansions = {};

        // Find linked resorts and set them to other operators
        setMatchingItemFilter(
          action.payload.operatorID,
          action.payload.item,
          state,
          "tour",
        );
      } else {
        if (action.payload.operatorID === 10) {
          state.filterTour = {};
        } else {
          delete state.filterTour[action.payload.operatorID];
        }

        // We don't need to delete other filters here as resetting current filter doesn't narrow
        // down choice for other filters.
      }
    },
    clearAssociateDataForType: (
      state,
      action: PayloadAction<{ operator: number; type: AssociateType }>,
    ) => {
      switch (action.payload.type) {
        case AssociateType.roomTypes: {
          delete state.rooms[action.payload.operator];
          break;
        }
        case AssociateType.hotels: {
          delete state.hotels[action.payload.operator];
          break;
        }
        case AssociateType.pansions: {
          delete state.pansions[action.payload.operator];
          break;
        }
        default:
          break;
      }
    },
    removeAssociateError: (state, action: PayloadAction<number>) => {
      state.errors = state.errors.filter((j) => j.id != action.payload);
    },
  },
  extraReducers: (builder) => {
    addFetchAssociateHotels(builder);
    addFetchAssociatePansions(builder);
    addFetchAssociateDestinationCities(builder);
    addFetchAssociateResorts(builder);
    addFetchAssociateRoomTypes(builder);
    addFetchAssociateHotelCategories(builder);
    addPostHotelChanges(builder);
    addBindDirectoryItem(builder);

    addCreateRegion(builder);
    addEditRegion(builder);
    addCreateTour(builder);
    addEditTour(builder);

    addFetchAssociateTourFilters(builder);
    addFetchAssociateTourTypes(builder);
    addPostRoomChanges(builder);
    addUnbindDirectoryItem(builder);
    addFetchAssociateRegions(builder);
  },
});

export const {
  resetAssociateState,
  resetAssociatedItems,
  setAssociateOperators,
  setAssociateCountry,
  setTezAssociateTargets,
  setAssociatedForOperator,
  setAssociateHotelFilter,
  setAssociateTourFilter,
  setAssociateRegionFilter,
  clearAssociateDataForType,
  removeAssociateError,
} = associateSlice.actions;
export default associateSlice.reducer;
