import React from "react";
import { IoArchiveOutline, IoBusOutline, IoLinkOutline } from "react-icons/io5";
import { PagePath } from "../../../navigation/routes";
import { HeaderItem } from "../HeaderItem";
import { HeaderSubRowStyled } from "../styled/Header.styled";

export function DataImportSubNav() {
  return (
    <HeaderSubRowStyled>
      <HeaderItem
        path={PagePath.dataImport.association.hotels}
        subItem={true}
        notReady={false}
      >
        <IoLinkOutline />
        Отели
      </HeaderItem>
      <HeaderItem
        path={PagePath.dataImport.association.pansions}
        subItem={true}
        notReady={false}
      >
        <IoLinkOutline />
        Пансионы
      </HeaderItem>
      <HeaderItem
        path={PagePath.dataImport.association.roomTypes}
        subItem={true}
        notReady={false}
      >
        <IoLinkOutline />
        Типы номеров
      </HeaderItem>
      <HeaderItem
        path={PagePath.dataImport.association.regions}
        subItem={true}
        notReady={false}
      >
        <IoLinkOutline />
        Регионы
      </HeaderItem>
      <HeaderItem
        path={PagePath.dataImport.association.tours}
        subItem={true}
        notReady={false}
      >
        <IoLinkOutline />
        Туры
      </HeaderItem>
      <HeaderItem
        path={PagePath.dataImport.association.cities}
        subItem={true}
        notReady={false}
      >
        <IoLinkOutline />
        Города
      </HeaderItem>
      <HeaderItem
        path={PagePath.dataImport.transfers}
        subItem={true}
        notReady={false}
      >
        <IoBusOutline />
        Сервисы
      </HeaderItem>
      <HeaderItem
        path={PagePath.dataImport.margin}
        subItem={true}
        notReady={false}
      >
        <IoLinkOutline />
        Наценки
      </HeaderItem>
      <HeaderItem
        path={PagePath.dataImport.gross}
        subItem={true}
        notReady={false}
      >
        <IoLinkOutline />
        Бруттовка
      </HeaderItem>
      <HeaderItem path={PagePath.dataImport.manage} subItem={true}>
        <IoArchiveOutline />
        Импорт
      </HeaderItem>
    </HeaderSubRowStyled>
  );
}
