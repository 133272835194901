import React from "react";
import {
  MdClear,
  MdContentCopy,
  MdCreate,
  MdOutlineReceipt,
  MdPause,
  MdPlayArrow,
} from "react-icons/md";
import { IJobInfo } from "../../../api/types/jobs/IJobInfo";
import { JobAction } from "../../../api/types/jobs/JobAction";
import { TemplateListMode } from "../../../pages/types/TemplateListMode";
import { useAppDispatch } from "../../../store/hooks";
import { toggleJobStatus } from "../../../store/jobs/thunks/toggleJobStatus";
import { showAlert, showModal } from "../../../store/modals/modalsSlice";
import { ControlButtonStyled } from "../../styled/ControlButton.styled";
import { ControlButtonContainerStyled } from "../../styled/ControlButtonContainer.styled";

interface Props {
  job: IJobInfo
  disabled: boolean
  isStopped: boolean
  mode: TemplateListMode
}
export const RowControlButtons = ({ job, disabled, isStopped, mode }: Props) => {
  const dispatch = useAppDispatch();

  const toggleStatus = (info: IJobInfo) => {
    dispatch(toggleJobStatus(info));
  };

  const copyTemplate = (info: IJobInfo) => {
    dispatch(
      showModal({
        type: "clone",
        info: info,
        id: info.id,
        kind: "job",
      }),
    );
  };

  const editTemplate = (info: IJobInfo, readOnly: boolean) => {
    dispatch(
      showModal({
        type: readOnly ? "view" : "edit",
        info: info,
        id: info.id,
        kind: "job",
      }),
    );
  };

  const removeTemplate = (id: number, name?: string) => {
    const kind = mode === "templates" ? "Шаблон" : "Задача";
    const kindTitle = mode === "templates" ? "шаблон" : "задачу";
    dispatch(
      showAlert({
        title: `Удалить ${kindTitle}?`,
        message: `${kind} ${
          name ? `"${name}"` : ""
        } и все связанные данные будут удалены. Это действие нельзя будет отменить.`,
        id,
        actions: [
          { title: "Отмена", kind: "standard" },
          {
            title: "Удалить",
            kind: "danger",
            onPress: {
              action: JobAction.deleteJob,
              params: {
                action: JobAction.deleteJob,
                id,
              },
            },
          },
        ],
      }),
    );
  };

  return (
    <ControlButtonContainerStyled>
      {mode !== "imported" ? (
        <>
          <ControlButtonStyled
            title={isStopped ? "Запустить" : "Остановить"}
            onClick={() => toggleStatus(job)}
            disabled={disabled}
          >
            {isStopped ? <MdPlayArrow /> : mode === "jobs" ? <MdPause /> : null}
          </ControlButtonStyled>
          <ControlButtonStyled
            title={"Клонировать"}
            onClick={() => copyTemplate(job)}
            disabled={disabled}
          >
            <MdContentCopy />
          </ControlButtonStyled>
        </>
      ) : null}

      <ControlButtonStyled
        title={mode === "imported" ? "Просмотр" : "Редактировать"}
        onClick={() => editTemplate(job, mode === "imported")}
        disabled={disabled}
      >
        {mode === "imported" ? <MdOutlineReceipt /> : <MdCreate />}
      </ControlButtonStyled>
      <ControlButtonStyled
        variant={"danger"}
        title={"Удалить"}
        disabled={disabled}
        onClick={() => removeTemplate(job.id, job.name)}
      >
        <MdClear />
      </ControlButtonStyled>
    </ControlButtonContainerStyled>
  );
};
