import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { deleteGross } from "../thunks/deleteGross";
import { IServicesState } from "../types/IServicesState";
import { fetchGross } from "../thunks/fetchGross";

export const GROSS_KEY = "gross";

export const AddFetchGross = (
  builder: ActionReducerMapBuilder<IServicesState>,
) => {
  builder.addCase(fetchGross.pending, (state) => {
    state.loadingKeys = [...state.loadingKeys, GROSS_KEY];
  });
  builder.addCase(fetchGross.fulfilled, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== GROSS_KEY);

    const list = action.payload;

    if (list.length) {
      state.gross.data[list[0].operator_id] = list;
    }
  });
  builder.addCase(fetchGross.rejected, (state) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== GROSS_KEY);
  });
  builder.addCase(deleteGross.pending, (state) => {
    state.loadingKeys = [...state.loadingKeys, GROSS_KEY];
  });
  builder.addCase(deleteGross.fulfilled, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== GROSS_KEY);

    const id = action.meta.arg;
    for (const [key, value] of Object.entries(state.gross.data)) {
      state.gross.data[key] = value.filter((el) => el.id !== id);
    }
  });
  builder.addCase(deleteGross.rejected, (state) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== GROSS_KEY);
  });
};
