import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";

interface EditRoomParams {
  localIds: (string | number)[]
  note: string
}

export const postRoomChanges = createAsyncThunk<EditRoomParams, EditRoomParams>(
  "associate/editRoom",
  async (param, thunkAPI) => {
    await endpoints.directory.editRoom({ note: param.note, id: param.localIds }, thunkAPI.signal);
    return param;
  },
);
