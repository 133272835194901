import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import { IReportActionParams } from "../../../api/types/reports/IReportActionParams";
import { IReportResponse } from "../../../api/types/reports/IReportResponse";

export const deleteReport = createAsyncThunk<
  IReportResponse,
  IReportActionParams
>("reports/deleteReport", async (param, thunkAPI) => {
  const response = await endpoints.report.remove(param, thunkAPI.signal);
  if (response.error) throw response.error;
  if (response.status === "OK") return response;
  else throw new Error("Invalid response from the server.");
});
