import { JobStatus } from "../../../api/types/jobs/JobStatus";

export const titleForStatus = (status: JobStatus | -1): string => {
  if (status === -1) return "Все статусы";
  switch (status) {
    case JobStatus.active:
      return "Активная";
    case JobStatus.deleted:
      return "Удалена";
    case JobStatus.finished:
      return "Завершена";
    case JobStatus.pending:
      return "В очереди";
    case JobStatus.stopped:
      return "Остановлена";
    case JobStatus.template:
      return "Шаблон";
    case JobStatus.imported:
      return "Импортирована";
  }
};
