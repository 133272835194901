import { LoadingButton } from "@mui/lab";
import { Alert, TextField } from "@mui/material";
import React, { useState } from "react";
import { ColumnBodyStyled, ColumnStyled } from "../../../components/columns/ColumnBodyStyled";
import { SpacedContainerStyled } from "../../../components/styled/SpacedContainerStyled";
import { createRegion } from "../../../store/associate/thunks/createRegion";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

export const ResortEditor = ({ operatorID }: { operatorID: number }) => {
  const dispatch = useAppDispatch();

  const items = useAppSelector((s) => {
    if (operatorID === 10) return s.associate.tezTargets;
    return s.associate.associated[operatorID] ?? [];
  });

  const isSaving = useAppSelector((s) => s.associate.loadingKeys.includes(`group${operatorID}`));

  const countryID = useAppSelector((s) => s.associate.country);

  const [name, setName] = useState<string>("");

  const onClear = () => {
    setName("");
  };
  const onSavePress = () => {
    if (items.length < 1) return;
    if (name.length === 0) return;
    if (countryID === null) return;

    dispatch(
      createRegion({
        name,
        operator: operatorID,
        country: countryID,
        resorts: items.map((itm) => itm.id).join(","),
      }),
    ).then(() => onClear());
  };

  const saveEnabled = items.length > 0 && name.length > 0 && countryID !== null;

  return (
    <ColumnStyled fullWidth={true}>
      <h5>Сохранить группу</h5>
      {items.length < 1 ? (
        <Alert severity={"info"}>
          Выберите минимум один курорт, чтобы иметь возможность создать Регион.
        </Alert>
      ) : (
        <ColumnBodyStyled minHeight={60}>
          <TextField
            label={"Название группы"}
            variant={"filled"}
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value ?? "")}
          />
          <SpacedContainerStyled>
            <LoadingButton
              fullWidth={true}
              loading={isSaving}
              disabled={!saveEnabled || isSaving}
              variant={"contained"}
              onClick={onSavePress}
            >
              Создать группу
            </LoadingButton>
          </SpacedContainerStyled>
        </ColumnBodyStyled>
      )}
    </ColumnStyled>
  );
};
