import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IActionErrorInfo } from "../../jobs/types/IActionErrorInfo";
import {
  bindDirectoryItems,
  unbindDirectoryItems,
} from "../thunks/bindDirectoryItems";
import { IAssociateState } from "../types/IAssociateState";

function markAssociatedFor(
  operatorID: number,
  tezID: number | string,
  state: IAssociateState,
) {
  // Here selected items state changes happening
  const items = state.associated[operatorID];
  if (items) {
    state.associated[operatorID] = items.map((itm) => ({
      ...itm,
      tez_id: `${tezID}`,
    }));
  }
}
export const addBindDirectoryItem = (
  builder: ActionReducerMapBuilder<IAssociateState>,
) => {
  builder.addCase(bindDirectoryItems.pending, (state) => {
    state.loadingKeys = [...state.loadingKeys, "bind"];
  });
  builder.addCase(bindDirectoryItems.rejected, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== "bind");

    const error: IActionErrorInfo = {
      id: Date.now(),
      message: `Не удалось сохранить ассоциации для ID: ${action.meta.arg.tezID}`,
    };
    state.errors = [...state.errors, error];
  });
  builder.addCase(bindDirectoryItems.fulfilled, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== "bind");
    const operators = state.operators;

    state.tezTargets = state.tezTargets.map((itm) => ({ ...itm, tez_id: "1" }));
    for (const operator of operators) {
      markAssociatedFor(operator.id, action.meta.arg.tezID, state);
    }
  });
};

export const addUnbindDirectoryItem = (
  builder: ActionReducerMapBuilder<IAssociateState>,
) => {
  builder.addCase(unbindDirectoryItems.pending, (state) => {
    state.loadingKeys = [...state.loadingKeys, "bind"];
  });
  builder.addCase(unbindDirectoryItems.rejected, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== "bind");

    const error: IActionErrorInfo = {
      id: Date.now(),
      message: `Не удалось удалить ассоциации для ID: ${action.meta.arg.localIDs.join(
        ",",
      )}`,
    };
    state.errors = [...state.errors, error];
  });
  builder.addCase(unbindDirectoryItems.fulfilled, (state) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== "bind");

    state.tezTargets = state.tezTargets.map((itm) => ({ ...itm, tez_id: "0" }));
    for (const operator of state.operators) {
      markAssociatedFor(operator.id, "0", state);
    }
  });
};
