import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { deleteJob } from "../jobs/thunks/deleteJob";
import { editJob } from "../jobs/thunks/editJob";
import { deleteReport } from "../reports/thunks/deleteReport";
import { editReportTemplate } from "../reports/thunks/editReportTemplate";
import { deleteUser } from "../user/thunks/deleteUser";
import { logoutUser } from "../user/thunks/logoutUser";
import { IAlertInfo } from "./types/IAlertInfo";
import { IModalInfo } from "./types/IModalInfo";
import { IModalsState } from "./types/IModalsState";

const initialState: IModalsState = {
  alerts: [],
  modals: [],
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    removeAllAlerts: (state) => {
      state.alerts = [];
    },
    resetModals: () => initialState,
    closeModal: (state, action: PayloadAction<number>) => {
      state.modals = state.modals.filter((m) => m.id !== action.payload);
    },
    closeAlert: (state, action: PayloadAction<number>) => {
      state.alerts = state.alerts.filter((m) => m.id !== action.payload);
    },
    showAlert: (state, action: PayloadAction<IAlertInfo>) => {
      state.alerts = [...state.alerts, action.payload];
    },
    showModal: (state, action: PayloadAction<IModalInfo>) => {
      state.modals = [...state.modals, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteJob.fulfilled, (state, action) => {
      state.alerts = state.alerts.filter((m) => m.id !== action.meta.arg.id);
    });
    builder.addCase(deleteJob.rejected, (state, action) => {
      state.alerts = state.alerts.filter((m) => m.id !== action.meta.arg.id);
    });
    builder.addCase(deleteReport.fulfilled, (state, action) => {
      state.alerts = state.alerts.filter((m) => m.id !== action.meta.arg.id);
    });
    builder.addCase(deleteReport.rejected, (state, action) => {
      state.alerts = state.alerts.filter((m) => m.id !== action.meta.arg.id);
    });
    builder.addCase(editJob.fulfilled, (state, action) => {
      state.modals = state.modals.filter((m) => m.id !== action.meta.arg.id);
    });
    builder.addCase(editReportTemplate.fulfilled, (state, action) => {
      state.modals = state.modals.filter((m) => m.id !== action.meta.arg.id);
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.alerts = state.alerts.filter((m) => m.id !== action.meta.arg);
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.alerts = state.alerts.filter((m) => m.id !== action.meta.arg);
    });
    builder.addCase(logoutUser.fulfilled, (state) => {
      state.modals = [];
      state.alerts = [];
    });
  },
});

export const {
  removeAllAlerts,
  resetModals,
  closeModal,
  closeAlert,
  showModal,
  showAlert,
} = modalsSlice.actions;
export default modalsSlice.reducer;
