import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import { EditApiAction } from "../../../api/ApiAction";
import { RootState } from "../../rootState";
import { IGross } from "../types/gross";

export const editGross = createAsyncThunk<void, IGross, { state: RootState }>(
  "services/editGross",
  async (params, thunkAPI) => {
    await endpoints.directory.editGross(
      {
        ...params,
        operator: params.operator_id,
        action: EditApiAction.editGross,
      },
      thunkAPI.signal,
    );
  },
);
