import { TableRow } from "@mui/material";
import React from "react";
import { IReportInfo } from "../../../api/types/reports/IReportInfo";
import { titleForReportStatus } from "../../../components/filter/utils/titleForReportStatus";
import { StyledTableCell } from "../../../components/styled/TableCell.styled";
import { truncateString } from "../../../components/template/row/utils/TruncateString";
import { templateTypeDataSource } from "../../../constants/TemplateTypeDataSource";
import { useAppSelector } from "../../../store/hooks";
import { ReportStatus } from "../../../store/reports/types/reportStatus";
import { ReportRowControlButtons } from "../common/ReportRowControlButtons";

interface Params {
  info: IReportInfo;
}
export const ReportsRow = ({ info }: Params) => {
  const operators = useAppSelector((s) => {
    const ids = info.operators.split(",").map((id) => parseInt(id, 10));
    console.log("operators", ids, info.operators);
    return (
      s.directory.operators
        ?.filter((o) => ids.includes(o.id))
        .map((o) => o.name) ?? []
    );
  });

  const disabled = useAppSelector((s) =>
    s.reports.loadingReportKeys.includes(`${info.id}`),
  );

  const st =
    typeof info.status === "string" ? parseInt(info.status, 10) : info.status;
  const isStopped =
    st === ReportStatus.stopped ||
    st === ReportStatus.finished ||
    st === ReportStatus.template ||
    st == ReportStatus.imported;
  return (
    <TableRow hover sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <StyledTableCell>
        <strong>{info.name}</strong>
        <br />
        <small>
          {templateTypeDataSource.find((itm) => itm.id == info.type)?.name ??
            "Авиа"}
        </small>
      </StyledTableCell>
      <StyledTableCell>{info.country_string}</StyledTableCell>
      <StyledTableCell>{info.city_from_string}</StyledTableCell>
      <StyledTableCell>{operators.join(", ")}</StyledTableCell>
      <StyledTableCell>
        <small>
          <strong>Ночей:</strong>{" "}
          {truncateString(info.nights.split(",").join(", "), 50)}; <br />
          <strong>Даты:</strong>{" "}
          {truncateString(info.dates.split(",").join(", "), 50)}
        </small>
      </StyledTableCell>
      <StyledTableCell sx={{ width: "100px" }}>
        <small>{titleForReportStatus(st)}</small>
      </StyledTableCell>
      <StyledTableCell align={"right"}>
        <ReportRowControlButtons
          disabled={disabled}
          isStopped={isStopped}
          isTemplate={false}
          info={info}
        />
      </StyledTableCell>
    </TableRow>
  );
};
