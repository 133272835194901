import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiAction } from "../../../api/ApiAction";
import { endpoints } from "../../../api/endpoints";
import { IServicePrice } from "../../../api/types/directory/IServicePrice";
import { RootState } from "../../rootState";
import { IServiceParams } from "../types/IServiceParams";

export const fetchInsuranceInfo = createAsyncThunk<
  IServicePrice[],
  IServiceParams,
  { state: RootState }
>("services/getInsuranceInfo", async (param, thunkAPI) => {
  const result = await endpoints.directory.getInsuranceInfo(
    {
      action: ApiAction.getInsurance,
      operator: param.operator,
      region_id: param.region_id,
      hotel_id: param.hotel_id,
    },
    thunkAPI.signal,
  );

  return result;
});
