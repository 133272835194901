import { ISelectItem } from "../../../components/selectList/types/ISelectItem";

export const getSuggestedForItems = (
  items: ISelectItem[],
  all: ISelectItem[],
  selected: ISelectItem[],
): ISelectItem[] => {
  if (items.length === 0) return [];
  const selectedIDs = selected.map((itm) => itm.id);
  // We don't need to suggest already selected items
  const available = all.filter((itm) => !selectedIDs.includes(itm.id));
  const matches: { rating: number; value: ISelectItem }[] = [];
  for (const item of items) {
    const components = item.name.toLowerCase().split(/[\s-]/g);
    const count = components.length;
    if (count === 0) continue;
    for (const itm of available) {
      let isMatch = false;
      let matchCount = 0;
      const otherComponents = itm.name
        .toLowerCase()
        .split(/[\s-]/g)
        .map((word) => word.trim())
        .filter((word) => word.length > 0);
      for (const word of components) {
        if (word === "hotel") continue;
        if (otherComponents.includes(word.trim())) {
          isMatch = true;
          matchCount = matchCount + 1;
        }
      }
      const matchRatio = matchCount / count;
      if (isMatch && matchRatio > 0.49) {
        const hasItem = matches.find((i) => i.value.id === itm.id);
        if (!hasItem) {
          matches.push({ rating: matchRatio, value: itm });
        }
      }
    }
  }

  const sorted = matches
    .sort((a, b) => b.rating - a.rating)
    .map((itm) => itm.value)
    .slice(0, 3);
  return [...sorted];
};
