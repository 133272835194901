import styled from "@emotion/styled";

export const FilterRowStyled = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-end;
  column-gap: 8px;
`;
