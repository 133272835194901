import { Grid, Paper, Table, TableBody, TableContainer } from "@mui/material";
import React, { useEffect } from "react";
import { TemplateFilter } from "../../components/filter/TemplateFilter";
import { LoadingRow } from "../../components/template/row/LoadingRow";
import { RowHeader } from "../../components/template/row/RowHeader";
import { TemplateRow } from "../../components/template/row/TemplateRow";
import { fetchOperators } from "../../store/directory/thunks/fetchOperators";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { TemplateListMode } from "../types/TemplateListMode";

const colSpanForMode = (mode: TemplateListMode): number => {
  if (mode === "templates") return 6;
  if (mode === "jobs") return 11;
  if (mode === "imported") return 7;
  return 11;
};

interface Props {
  mode: TemplateListMode
}

export const Templates = ({ mode }: Props) => {
  const dispatch = useAppDispatch();

  const jobs = useAppSelector((state) => state.jobs.allJobs ?? []);
  const loadingJobs = useAppSelector((state) => state.jobs.loadingJobs);

  useEffect(() => {
    dispatch(fetchOperators());
  }, []);

  const colSpan = colSpanForMode(mode);
  const isJobs = mode === "jobs" || mode === "imported";

  return (
    <Grid container spacing={1} rowSpacing={2} alignItems={"flex-end"}>
      <Grid item xs={12}>
        <TemplateFilter mode={mode} />
      </Grid>

      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="Шаблоны">
            <RowHeader mode={mode} isJobs={isJobs} />
            <TableBody>
              {loadingJobs || jobs.length === 0 ? (
                <LoadingRow colSpan={colSpan} loading={loadingJobs} />
              ) : null}
              {jobs.map((job, index) => (
                <TemplateRow
                  key={`job${job.id}.${index}`}
                  job={job}
                  mode={mode}
                  colSpan={colSpan}
                  isJobs={isJobs}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
