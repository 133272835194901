import { Autocomplete, Button, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { MdClear } from "react-icons/md";
import { IOperator } from "../../api/types/directory/IOperator";
import { inputStyleVariant } from "../../constants/interface";
import {
  resetAssociateState,
  setAssociateCountry,
  setAssociateOperators,
} from "../../store/associate/associateSlice";
import { DirectoryTarget } from "../../store/directory/thunks/directoryTarget";
import { fetchCountriesForOperators } from "../../store/directory/thunks/fetchDestinationCountries";
import { fetchOperators } from "../../store/directory/thunks/fetchOperators";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { SelectField } from "../select/SelectField";
import { FilterRowStyled } from "./styled/FilterRow.styled";
import { AssociateType } from "./types/AssociateType";

export const AssociateFieldsFilter = ({ type }: { type: AssociateType }) => {
  const dispatch = useAppDispatch();
  const sourceOperators = useAppSelector(
    (s) => s.directory.operators?.filter((itm) => itm.id !== 10) ?? [],
  );
  const operators = useAppSelector((s) => s.associate.operators);
  const countries = useAppSelector((s) => s.directory.associateData.countries ?? []);
  const country = useAppSelector((s) => s.associate.country);
  const countriesLoading = useAppSelector((s) =>
    s.directory.loadingKeys.includes("destinationCountries"),
  );

  useEffect(() => {
    dispatch(fetchOperators());
  }, []);

  useEffect(() => {
    if (operators.length > 0) {
      dispatch(
        fetchCountriesForOperators({
          operator: `${operators.map((o) => o.id).join(",")},10`,
          target: DirectoryTarget.associateFilter,
        }),
      );
    }
  }, [operators]);

  useEffect(()=>{
    dispatch(resetAssociateState());
  },[type]);

  const resetFilter = () => {
    dispatch(resetAssociateState());
  };

  return (
    <FilterRowStyled>
      <Autocomplete
        multiple
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={inputStyleVariant}
            label="Операторы"
            placeholder="Операторы"
            required
          />
        )}
        fullWidth={true}
        isOptionEqualToValue={(lhs, rhs) => lhs.id === rhs.id}
        onChange={(e, val) => {
          const allItem = val.find((i) => i.id == -1);
          if (allItem) dispatch(setAssociateOperators({ type, items: [allItem] }));
          else {
            const uniqueMap = new Map<number, IOperator>();
            val.forEach((i) => uniqueMap.set(i.id, i));
            dispatch(setAssociateOperators({ type, items: [...uniqueMap.values()] }));
          }
        }}
        value={operators}
        options={sourceOperators}
      />
      <SelectField
        items={countries}
        required
        disabled={operators.length === 0}
        label={"Страна"}
        value={country ?? ""}
        loading={countriesLoading}
        minWidth={200}
        maxWidth={200}
        onSelect={(val) => dispatch(setAssociateCountry(val as string))}
      />
      <Button
        variant={"outlined"}
        onClick={() => resetFilter()}
        disabled={operators.length === 0 && country === null}
      >
        <MdClear />
      </Button>
    </FilterRowStyled>
  );
};
