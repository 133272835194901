import React from "react";
import { IoAddOutline, IoArchiveOutline, IoReaderOutline, IoRocketOutline } from "react-icons/io5";
import { PagePath } from "../../../navigation/routes";
import { HeaderItem } from "../HeaderItem";
import { HeaderSubRowStyled } from "../styled/Header.styled";

export function ParsingSubNav() {
  return (
    <HeaderSubRowStyled>
      <HeaderItem path={PagePath.parsing.jobs} subItem={true}>
        <IoRocketOutline />
        Задачи
      </HeaderItem>

      <HeaderItem path={PagePath.parsing.imported} subItem={true}>
        <IoArchiveOutline />
        Импортированные
      </HeaderItem>

      <HeaderItem path={PagePath.parsing.templates} subItem={true}>
        <IoReaderOutline />
        Шаблоны
      </HeaderItem>
      <HeaderItem path={PagePath.parsing.createTemplate} subItem={true}>
        <IoAddOutline />
        Новый шаблон
      </HeaderItem>
    </HeaderSubRowStyled>
  );
}
