import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IActionErrorInfo } from "../../jobs/types/IActionErrorInfo";
import { createRegion, editRegion } from "../thunks/createRegion";
import { IAssociateState } from "../types/IAssociateState";

export const addCreateRegion = (
  builder: ActionReducerMapBuilder<IAssociateState>,
) => {
  builder.addCase(createRegion.pending, (state, action) => {
    state.loadingKeys = [...state.loadingKeys, `${action.meta.arg.operator}`];
  });
  builder.addCase(createRegion.rejected, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter(
      (itm) => itm !== `${action.meta.arg.operator}`,
    );
    const error: IActionErrorInfo = {
      id: Date.now(),
      message: `Не удалось сохранить регион "${action.meta.arg.name}"`,
    };
    state.errors = [...state.errors, error];
  });
  builder.addCase(createRegion.fulfilled, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter(
      (itm) => itm !== `${action.meta.arg.operator}`,
    );
  });
};

export const addEditRegion = (
  builder: ActionReducerMapBuilder<IAssociateState>,
) => {
  builder.addCase(editRegion.pending, (state, action) => {
    state.loadingKeys = [...state.loadingKeys, `${action.meta.arg.operator}`];
  });
  builder.addCase(editRegion.rejected, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter(
      (itm) => itm !== `${action.meta.arg.operator}`,
    );
    const error: IActionErrorInfo = {
      id: Date.now(),
      message: `Не удалось сохранить регион "${action.meta.arg.name}"`,
    };
    state.errors = [...state.errors, error];
  });
  builder.addCase(editRegion.fulfilled, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter(
      (itm) => itm !== `${action.meta.arg.operator}`,
    );
  });
};
