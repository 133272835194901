import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchServiceDestinationCountries } from "../thunks/fetchServiceDestinationCountries";
import { IServicesState } from "../types/IServicesState";

export const addFetchServiceDestinationCountries = (
  builder: ActionReducerMapBuilder<IServicesState>,
) => {
  builder.addCase(fetchServiceDestinationCountries.pending, (state) => {
    state.loadingKeys = [...state.loadingKeys, `countries`];
  });
  builder.addCase(fetchServiceDestinationCountries.rejected, (state) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `countries`);
  });
  builder.addCase(fetchServiceDestinationCountries.fulfilled, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `countries`);
    state.countries = [...action.payload];
  });
};
