import styled from "@emotion/styled";
import { Color } from "../../../constants/colors";
import { globalRadius, hPaddingValue } from "../../../constants/sizes";
import { adjustColor } from "../../../utils/adjustColor";

interface Props {
  selected?: boolean
  highlighted?: boolean
}

export const ListItemStyled = styled.div<Props>`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 4px;
  border-radius: ${globalRadius}px;
  background-color: ${(props) =>
    props.selected
      ? Color.brand
      : props.highlighted
      ? adjustColor(Color.background, -15)
      : Color.inputBackground};
  padding: ${hPaddingValue / 2}px;

  color: ${(props) =>
    props.selected
      ? Color.text.onDark
      : props.highlighted
      ? adjustColor(Color.brand, -25)
      : Color.text.onLight};
  cursor: pointer;

  font-size: 12px;
  font-weight: ${(props) => (props.selected || props.highlighted ? 500 : 400)};

  &:hover,
  &:focus {
    background-color: ${(props) =>
      props.selected ? adjustColor(Color.brand, -15) : adjustColor(Color.background, -7)};
  }

  small {
    align-self: flex-end;
    justify-self: flex-end;
    margin-left: auto;
    font-size: 8px;
  }

  svg {
    flex-shrink: 0;
  }

  sup {
    color: ${(props) => (props.selected ? adjustColor(Color.brand, 60) : "#727974")};
    font-weight: 500;
    flex-shrink: 0;
  }
`;

export const ListItemButtonStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-left: auto;
  font-size: 14px;
`;
const colorForItem = (selected: boolean, highlighted: boolean): string => {
  if (selected) return Color.brand;
  if (highlighted) {
    return adjustColor(Color.brand, -20);
  }
  return "#000";
};
export const AutoCompleteItemStyled = styled.li<Props>`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;

  color: ${(props) => colorForItem(props.selected === true, props.highlighted === true)};
  background-color: ${(props) => (props.selected ? "#fafafa" : "#fff")};
  svg {
    margin-right: 8px;
    flex-shrink: 0;
  }
  small {
    margin-left: auto;
  }
`;
