import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import { ICreateJobResponse } from "../../../api/types/jobs/ICreateJobResponse";
import { IJobParams } from "../../../api/types/jobs/IJobParams";
import { createJobFromTemplate } from "./createJobFromTemplate";

export const createJobTemplate = createAsyncThunk<ICreateJobResponse, IJobParams>(
  "jobs/create",
  async (param, thunkAPI) => {
    const response = await endpoints.job.create(param, thunkAPI.signal);
    if (response.jobID) {
      if (param.schedule_days.length === 0 || param.schedule_time.length === 0)
        thunkAPI.dispatch(createJobFromTemplate(param));
      return response;
    } else throw new Error(response?.error ?? `Не удалось сохранить шаблон "${param.name}"`);
  },
);
