import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { MdAdd, MdCheck, MdClose, MdLink } from "react-icons/md";
import { IHotel } from "../../api/types/directory/IHotel";
import { IRegion } from "../../api/types/directory/IRegion";
import { ITourFilter } from "../../api/types/directory/ITourFilter";
import { ResortEditor } from "../../pages/data/associate/ResortEditor";
import { RoomEditor } from "../../pages/data/associate/RoomEditor";
import { useAppSelector } from "../../store/hooks";
import { hasTezID } from "../../utils/hasTezID";
import { ColumnBodyStyled, ColumnStyled } from "../columns/ColumnBodyStyled";
import { AssociateType } from "../filter/types/AssociateType";
import { ItemCreator } from "./ItemCreator";
import { SelectListEmptyState } from "./SelectListEmptyState";
import {
  AutoCompleteItemStyled,
  ListItemButtonStyled,
  ListItemStyled,
} from "./styled/ListItem.styled";
import { ISelectItem } from "./types/ISelectItem";
import { labelForType } from "./utils/labelForType";

interface Props {
  label: string;
  items: ISelectItem[];

  id: number;

  suggested: ISelectItem[];
  selected: ISelectItem[];

  onSelect: (items: ISelectItem[]) => void;
  hotelFilterItems: IHotel[];
  onSelectHotelFilter: (item: IHotel | null) => void;
  selectedHotelFilter: IHotel | undefined;
  regionFilterItems: IRegion[];
  onSelectRegionFilter: (item: IRegion | null) => void;
  selectedRegionFilter: IRegion | undefined;
  tourFilterItems: ITourFilter[];
  onSelectTourFilter: (item: ITourFilter | null) => void;
  selectedTourFilter: ITourFilter | undefined;
  type: AssociateType;
}
export const SelectList = ({
  label,
  items,
  suggested,
  selected,
  onSelect,
  id,
  hotelFilterItems,
  regionFilterItems,
  tourFilterItems,
  onSelectHotelFilter,
  onSelectRegionFilter,
  onSelectTourFilter,
  selectedHotelFilter,
  selectedRegionFilter,
  selectedTourFilter,
  type,
}: Props) => {
  const isLoading = useAppSelector((s) =>
    s.associate.loadingKeys.includes(`${id}`),
  );
  const needsExpandedList =
    type === AssociateType.hotels ||
    type === AssociateType.pansions ||
    type === AssociateType.roomTypes;

  const needsFilters =
    type === AssociateType.hotels ||
    type === AssociateType.pansions ||
    type === AssociateType.roomTypes;

  return (
    <ColumnStyled>
      <h5>{label}</h5>
      <ColumnBodyStyled minHeight={64} marginBottom={8}>
        {/*<SelectListSpinner isLoading={isLoading && items.length === 0} />*/}

        {needsFilters ? (
          <Autocomplete
            // sx={{ marginBottom: "8px" }}
            options={regionFilterItems}
            loading={isLoading}
            disableClearable={false}
            getOptionLabel={(option) => `${option.name} [${option.id}]`}
            value={selectedRegionFilter ?? null}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, val) => onSelectRegionFilter(val)}
            renderOption={(props, option, state) => (
              <AutoCompleteItemStyled
                key={`${option.name}.${option.id}`}
                {...props}
                selected={state.selected}
                highlighted={hasTezID(option.tez_id)}
              >
                {hasTezID(option.tez_id) ? <MdLink /> : null}
                {state.selected ? <MdCheck /> : null}
                {option.name}
                <small>id:{option.id}</small>
              </AutoCompleteItemStyled>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size={"small"}
                variant={"filled"}
                label={"Фильтр по регионам"}
                placeholder={"Поиск по названию"}
              />
            )}
          />
        ) : null}

        {needsFilters ? (
          <Autocomplete
            // sx={{ marginBottom: "8px" }}
            options={tourFilterItems}
            loading={isLoading}
            disableClearable={false}
            getOptionLabel={(option) => `${option.name} [${option.id}]`}
            value={selectedTourFilter ?? null}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, val) => onSelectTourFilter(val)}
            renderOption={(props, option, state) => (
              <AutoCompleteItemStyled
                key={`${option.name}.${option.id}`}
                {...props}
                selected={state.selected}
                highlighted={hasTezID(option.tez_id)}
              >
                {hasTezID(option.tez_id) ? <MdLink /> : null}
                {state.selected ? <MdCheck /> : null}
                {option.name}
                <small>id:{option.id}</small>
              </AutoCompleteItemStyled>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size={"small"}
                variant={"filled"}
                label={"Фильтр по турам"}
                placeholder={"Поиск по названию"}
              />
            )}
          />
        ) : null}

        {hotelFilterItems.length > 0 && needsFilters ? (
          <Autocomplete
            // sx={{ marginBottom: "8px" }}
            options={hotelFilterItems}
            loading={isLoading}
            disableClearable={false}
            getOptionLabel={(option) => `${option.name} [${option.id}]`}
            value={selectedHotelFilter ?? null}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, val) => onSelectHotelFilter(val)}
            renderOption={(props, option, state) => (
              <AutoCompleteItemStyled
                key={`${option.name}.${option.id}`}
                {...props}
                selected={state.selected}
                highlighted={hasTezID(option.tez_id)}
              >
                {hasTezID(option.tez_id) ? <MdLink /> : null}
                {state.selected ? <MdCheck /> : null}
                {option.name} {option.stars ? `(${option.stars})` : null}
                <small>id:{option.id}</small>
              </AutoCompleteItemStyled>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size={"small"}
                variant={"filled"}
                label={"Фильтр по отелям"}
                placeholder={"Поиск по названию"}
              />
            )}
          />
        ) : null}

        {items.length > 0 ? (
          <Autocomplete
            sx={{ marginBottom: "8px" }}
            multiple
            autoComplete={true}
            loading={isLoading}
            options={items}
            disabled={
              type !== AssociateType.hotels && needsFilters
                ? hotelFilterItems.length > 0 && !selectedHotelFilter
                : false
            }
            disableClearable={true}
            getOptionLabel={(option) => `${option.name} [${option.id}]}`}
            value={selected}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, val) => onSelect(val)}
            renderTags={() => <div />}
            renderOption={(props, option, state) => (
              <AutoCompleteItemStyled
                key={`${option.name}.${option.id}`}
                {...props}
                selected={state.selected}
                highlighted={hasTezID(option.tez_id)}
              >
                {hasTezID(option.tez_id) ? <MdLink /> : null}
                {state.selected ? <MdCheck /> : null}
                {option.name} {option.stars ? `(${option.stars})` : null}
                <small>id:{option.id}</small>
              </AutoCompleteItemStyled>
            )}
            ChipProps={{ size: "small" }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant={"filled"}
                label={
                  selected.length > 0
                    ? "Выбранные опции"
                    : labelForType(type, false)
                }
                placeholder={"Поиск по названию"}
              />
            )}
          />
        ) : (
          <SelectListEmptyState isHidden={isLoading} />
        )}
        {selected.map((item, index) => (
          <ListItemStyled
            key={`li${label}.${index}.${item.id}`}
            selected={true}
            highlighted={hasTezID(item.tez_id)}
            onClick={() =>
              onSelect(selected.filter((itm) => itm.id !== item.id))
            }
          >
            {hasTezID(item.tez_id) ? <MdLink /> : null}
            {item.name}
            {item.stars ? <sup>{item.stars}</sup> : null}
            <ListItemButtonStyled>
              <MdClose />
            </ListItemButtonStyled>
          </ListItemStyled>
        ))}
      </ColumnBodyStyled>
      {type === AssociateType.roomTypes && selected.length > 0 && id == 10 ? (
        <RoomEditor />
      ) : null}
      {suggested.length > 0 ? (
        <ColumnBodyStyled minHeight={24} maxHeight={145} marginBottom={8}>
          <h3>Возможные совпадения</h3>
          {suggested.map((item, index) => (
            <ListItemStyled
              key={`li${label}.${index}.${item.id}`}
              selected={false}
              highlighted={hasTezID(item.tez_id)}
              onClick={() => onSelect([...selected, item])}
            >
              {hasTezID(item.tez_id) ? <MdLink /> : null}

              {item.name}
              {item.stars ? <sup>{item.stars}</sup> : null}
              <ListItemButtonStyled>
                <MdAdd />
              </ListItemButtonStyled>
            </ListItemStyled>
          ))}
        </ColumnBodyStyled>
      ) : null}

      {type === AssociateType.createTours ? (
        <ResortEditor operatorID={id} />
      ) : null}
      {/* The last one section*/}
      {items.length > 0 && needsExpandedList ? (
        <ColumnBodyStyled>
          <h3>Все опции</h3>
          {items.map((item, index) => {
            const isSelected =
              selected.find((itm) => {
                // TODO: Most probably we can bind to local ids instead of
                //  simple id because an example bellow show us not unified
                //  ids in the Pansions case
                if (type === AssociateType.pansions) {
                  return itm.local_id === item.local_id;
                }
                return itm.id === item.id;
              }) !== undefined;
            return (
              <ListItemStyled
                key={`li${label}.${index}.${item.id}`}
                selected={isSelected}
                highlighted={hasTezID(item.tez_id)}
                onClick={() => {
                  isSelected
                    ? onSelect(
                        selected.filter((itm) => {
                          // TODO: Most probably we can bind to local ids instead of
                          //  simple id because an example bellow show us not unified
                          //  ids in the Pansions case
                          if (type === AssociateType.pansions) {
                            return itm.local_id !== item.local_id;
                          }
                          return itm.id !== item.id;
                        }),
                      )
                    : onSelect([...selected, item]);
                }}
              >
                {hasTezID(item.tez_id) ? <MdLink /> : null}

                {item.name}
                {item.stars ? <sup>{item.stars}</sup> : null}
                <ListItemButtonStyled>
                  {isSelected ? <MdClose /> : <MdAdd />}
                </ListItemButtonStyled>
              </ListItemStyled>
            );
          })}
        </ColumnBodyStyled>
      ) : null}

      <ItemCreator type={type} operatorID={id} />
    </ColumnStyled>
  );
};
