import styled from "@emotion/styled";
import { globalRadius } from "../../../constants/sizes";

export const GridBlockStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  border-radius: ${globalRadius}px;
  margin-top: 6px;
`;
