import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IActionErrorInfo } from "../../jobs/types/IActionErrorInfo";
import { createReportFromTemplate } from "../thunks/createReportFromTemplate";
import { IReportsState } from "../types/IReportsState";

export const addCreateReportFromTemplate = (builder: ActionReducerMapBuilder<IReportsState>) => {
  builder.addCase(createReportFromTemplate.pending, (state) => {
    state.reportsCreating = true;
  });
  builder.addCase(createReportFromTemplate.rejected, (state, action) => {
    state.reportsCreating = false;
    const error: IActionErrorInfo = {
      id: Date.now(),
      message: `Ошибка запуска отчета! "${action.meta.arg.name}": ${action.error.message}`,
    };
    state.errors = state.errors ? [...state.errors, error] : [error];
  });
  builder.addCase(createReportFromTemplate.fulfilled, (state, action) => {
    if (action.payload.reportID) {
      state.updated = [
        ...state.updated,
        {
          id: action.payload.reportID,
          message: `Задача отчета из шаблона "${action.meta.arg.name}" успешно создана`,
        },
      ];
    }
    state.reportsCreating = false;
  });
};
