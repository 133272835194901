import { createSlice } from "@reduxjs/toolkit";
import { IDestinationCity } from "../../api/types/directory/IDestinationCity";
import { IHotel } from "../../api/types/directory/IHotel";
import { IResort } from "../../api/types/directory/IResort";
import { ITourType } from "../../api/types/directory/ITourType";
import { IJobData } from "../../api/types/jobs/IJobInfo";
import { IJobStats } from "../../api/types/jobs/IJobStats";
import { fetchDirectoryForJobInfo } from "../directory/thunks/fetchDirectoryForJobInfo";
import { editJob } from "../jobs/thunks/editJob";
import { closeModal } from "../modals/modalsSlice";

interface IJobDataState {
  data?: IJobData;
  stats?: IJobStats[];
  id: number;
}

const initialState: IJobDataState = {
  id: 0,
};
const jobDataSlice = createSlice({
  name: "jobData",
  initialState,
  reducers: {
    resetJobData: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDirectoryForJobInfo.fulfilled, (state, action) => {
      const info = action.meta.arg;
      const data: IJobData = {
        ...info,
        sDestinationCity: [],
        sResorts: [],
        sTourType: [],
        sHotel: [],
      };
      const cities = action.payload.destinationCities;
      const resorts = action.payload.resorts;
      const tourTypes = action.payload.tourTypes;
      const hotels = action.payload.hotels;
      if (cities && resorts && tourTypes && hotels) {
        data.sDestinationCity = info.city_to
          .split(",")
          .map((id) => cities.find((city) => `${city.id}` === id))
          .filter((v) => v !== undefined) as IDestinationCity[];
        data.sResorts = info.resort
          .split(",")
          .map((id) => resorts.find((r) => `${r.id}` === id))
          .filter((v) => v !== undefined) as IResort[];
        data.sTourType = info.tour_types
          .split(",")
          .map((id) => tourTypes.find((t) => `${t.id}` === id))
          .filter((v) => v !== undefined) as ITourType[];
        data.sHotel = info.hotels
          .split(",")
          .map((id) => hotels.find((t) => `${t.id}` === id))
          .filter((v) => v !== undefined) as IHotel[];
      }
      state.data = data;
      state.id = info.id;
      if (action.payload.stats) {
        state.stats = [...action.payload.stats];
      }
    });
    builder.addCase(closeModal, (state, action) => {
      if (state.id == action.payload) {
        state.id = 0;
        state.data = undefined;
        state.stats = undefined;
      }
    });
    builder.addCase(editJob.fulfilled, (state, action) => {
      if (state.id == action.meta.arg.id) {
        state.id = 0;
        state.data = undefined;
        state.stats = undefined;
      }
    });
  },
});

export const { resetJobData } = jobDataSlice.actions;
export default jobDataSlice.reducer;
