import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { DirectoryCreateAction } from "../../../api/types/DirectoryCreateAction";
import { createOrEditService } from "../thunks/createOrEditService";
import { IServicesState } from "../types/IServicesState";

const loadingKeyForAction = (action: DirectoryCreateAction): string => {
  if (action === DirectoryCreateAction.createTransfer) {
    return "transfer";
  }
  if (action === DirectoryCreateAction.createInsurance) {
    return "insurance";
  }
  if (action === DirectoryCreateAction.createMargin) {
    return "margin";
  }
  return action;
};
export const addCreateOrEditService = (builder: ActionReducerMapBuilder<IServicesState>) => {
  builder.addCase(createOrEditService.pending, (state, action) => {
    state.loadingKeys = [...state.loadingKeys, loadingKeyForAction(action.meta.arg.action)];
  });
  builder.addCase(createOrEditService.rejected, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter(
      (itm) => itm !== loadingKeyForAction(action.meta.arg.action),
    );
    state.errors = [
      ...state.errors,
      { id: Date.now(), message: `Не удалось сохранить значение "${action.error}"` },
    ];
  });
  builder.addCase(createOrEditService.fulfilled, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter(
      (itm) => itm !== loadingKeyForAction(action.meta.arg.action),
    );
  });
};
