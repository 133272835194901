import { TextField } from "@mui/material";
import React, { useState } from "react";
import { inputStyleVariant } from "../../constants/interface";
import { DayPickerContainerStyled } from "./styled/DayPickerContainer.styled";
import { DayTimeBlockContainer } from "./styled/DayTimeBlockContainer";
import { GridRowStyled } from "./styled/GridRow.styled";
import { GridTextCellStyled } from "./styled/GridTextCell.styled";

const defaultDays = ["пн", "вт", "ср", "чт", "пт", "сб", "вс"];
const defaultTimes = ["08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00"];
type Day = typeof defaultDays[number]

export const dayToNumber = (day: Day): number => defaultDays.indexOf(day) + 1;
export const numberToDay = (index: number): Day | undefined => defaultDays[index - 1];

const refillTimeFromArray = (
  days: string[],
  times: (string | undefined)[],
): (string | undefined)[] => {
  return days.map((_, index) => {
    const time = times[index];
    if (time === undefined) return undefined;
    return time;
  });
};
const refillTime = (days: string[], time?: string): (string | undefined)[] => {
  const times = time?.split(",") ?? []; //?? ["08:00"]
  return refillTimeFromArray(days, times);
};

interface Props {
  label?: string
  days?: string[]
  time?: string
  selected: number[]
  onChange: (val: number[]) => void
  onTimeChange: (val: string) => void
}

export const DayPicker = ({
  label,
  days = defaultDays,
  selected,
  onChange,
  time,
  onTimeChange,
}: Props) => {
  const timeArray = refillTime(days, time);
  const [values, setValues] = useState(timeArray);

  const sanitizeTimeValues = (updatedValues: (string | undefined)[]): string[] => {
    const sanitized = updatedValues.filter((t) => {
      return t !== undefined && t.length === 5;
    });
    const first = sanitized[0];
    if (selected.length > 0 && (first === undefined || first?.length < 5)) {
      sanitized[0] = defaultTimes[0];
    }
    return sanitized as string[];
  };
  const saveTime = (val?: string) => {
    if (val) {
      setValues(refillTime(days, val));
      onTimeChange(val);
    } else {
      const updatedValues = [...values];
      const sanitized = sanitizeTimeValues(updatedValues);
      setValues(refillTimeFromArray(days, sanitized));
      onTimeChange(sanitized.join(","));
    }
  };

  const toggleSelected = (day: number) => {
    const dayIndex = selected.indexOf(day);
    const dayFound = dayIndex > -1;
    if (dayFound) {
      if (selected.length === 1) {
        setValues(refillTime(days, ""));
        onTimeChange("");
      } else if (selected.length === 0) {
        setValues(refillTime(days, undefined));
        onTimeChange("");
      }
      onChange(selected.filter((d) => d !== day));
    } else {
      const noTime = values[0] === undefined || values[0]?.length === 0;
      if (noTime) {
        const updated = refillTimeFromArray(days, [defaultTimes[0]]);
        setValues(updated);
        onTimeChange(defaultTimes[0]);
      }
      onChange([...selected, day]);
    }
  };

  const changeTime = (time: string, index: number) => {
    const updatedTime = [...values];
    updatedTime[index] = time;

    const unique = new Set(updatedTime.filter((time) => time !== undefined));
    setValues(refillTimeFromArray(days, Array.from(unique)));
  };

  const enableTimeInput = (index: number) => {
    if (selected.length === 0) return;
    if (index === 0) return;
    const item = values[index];
    if (item === undefined) {
      const updatedTime = [...values];
      for (const [i, value] of values.entries()) {
        if (value === undefined) {
          updatedTime[i] = defaultTimes[i];
          break;
        }
      }
      const sanitized = sanitizeTimeValues(updatedTime);
      onTimeChange(sanitized.join(","));
      setValues([...updatedTime]);
    }
  };

  return (
    <DayTimeBlockContainer>
      <DayPickerContainerStyled>
        <GridRowStyled>
          <GridTextCellStyled style={{ width: "60px" }} title={"Дни запуска задачи"}>
            {label ?? "Дни"}:
          </GridTextCellStyled>
          {days?.map((d, index) => (
            <GridTextCellStyled
              key={`day${index}`}
              selectable={true}
              selected={selected.includes(dayToNumber(d))}
              onClick={() => toggleSelected(dayToNumber(d))}
            >
              {d}
            </GridTextCellStyled>
          ))}
        </GridRowStyled>
      </DayPickerContainerStyled>
      <h5>Время запуска в выбранные дни (назависимо от дней)</h5>
      <DayPickerContainerStyled>
        <GridRowStyled>
          <GridTextCellStyled title={"Время запуска в выбранные дни"}>Время:</GridTextCellStyled>
          {days?.map((d, index) => (
            <GridTextCellStyled noPadding={true} key={`daytime${index}`} selectable={false}>
              <TextField
                size={"small"}
                type={"time"}
                margin={"none"}
                onBlur={() => saveTime()}
                onClick={() => enableTimeInput(index)}
                disabled={values[index] === undefined || selected.length === 0}
                value={values[index] ?? defaultDays[index]}
                onChange={(e) => changeTime(e.currentTarget.value, index)}
                variant={inputStyleVariant}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                // fullWidth
              />
            </GridTextCellStyled>
          ))}
        </GridRowStyled>
      </DayPickerContainerStyled>
    </DayTimeBlockContainer>
  );
};
