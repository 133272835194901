import React, { memo, useEffect } from "react";
import { IHotel, IHotelExtended } from "../../api/types/directory/IHotel";
import { TEZ_OPERATOR } from "../../api/types/directory/IOperator";
import { IRegion } from "../../api/types/directory/IRegion";
import { ITourFilter } from "../../api/types/directory/ITourFilter";
import {
  clearAssociateDataForType,
  resetAssociatedItems,
  setAssociatedForOperator,
  setAssociateHotelFilter,
  setAssociateRegionFilter,
  setAssociateTourFilter,
  setTezAssociateTargets,
} from "../../store/associate/associateSlice";
import { fetchAssociateDestinationCities } from "../../store/associate/thunks/fetchAssociateDestinationCities";
import { fetchAssociateHotels } from "../../store/associate/thunks/fetchAssociateHotels";
import { fetchAssociatePansions } from "../../store/associate/thunks/fetchAssociatePansions";
import { fetchAssociateRegions } from "../../store/associate/thunks/fetchAssociateRegions";
import { fetchAssociateResorts } from "../../store/associate/thunks/fetchAssociateResorts";
import { fetchAssociateRoomTypes } from "../../store/associate/thunks/fetchAssociateRoomTypes";
import { fetchAssociateTourFilters } from "../../store/associate/thunks/fetchAssociateTourFilters";
import { IAssociateParams } from "../../store/associate/thunks/types/IAssociateParams";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { AssociateType } from "../filter/types/AssociateType";
import { SelectList } from "./SelectList";
import { selectListItemsFrom } from "./selectors/SelectListItemsFrom";
import { ISelectItem } from "./types/ISelectItem";

const REASON = "unmount";
interface ControlledSelectListProps {
  operatorID: number;
  operatorName: string;
  country: string;
  type: AssociateType;
}

export const ControlledSelectList = memo(
  ({ operatorID, operatorName, country, type }: ControlledSelectListProps) => {
    const dispatch = useAppDispatch();

    const hotelFilter = useAppSelector(
      (s) => s.associate.filterHotel[operatorID] ?? null,
    );

    const regionFilter = useAppSelector(
      (s) => s.associate.filterRegion[operatorID] ?? null,
    );

    const tourFilter = useAppSelector(
      (s) => s.associate.filterTour[operatorID] ?? null,
    );

    const hotelFilterItems = useAppSelector((s) =>
      type !== AssociateType.hotels ? s.associate.hotels[operatorID] ?? [] : [],
    );
    const regionFilterItems = useAppSelector(
      (s) => s.associate.regions[operatorID] ?? [],
    );
    const tourFilterItems = useAppSelector(
      (s) => s.associate.tourFilters[operatorID] ?? [],
    );

    const items = useAppSelector((s) =>
      selectListItemsFrom(s, operatorID, type),
    );

    const selected = useAppSelector((s) =>
      operatorID === TEZ_OPERATOR.id
        ? s.associate.tezTargets
        : s.associate.associated[operatorID] ?? [],
    );

    const suggested = useAppSelector(
      (s) => s.associate.suggested[operatorID] ?? [],
    );

    const onSelect = (items: ISelectItem[]) => {
      if (operatorID === TEZ_OPERATOR.id) {
        dispatch(setTezAssociateTargets({ type, items }));
      } else {
        dispatch(
          setAssociatedForOperator({ operator: operatorID, items, type }),
        );
      }
    };

    const onSelectHotelFilter = (item: IHotel | null) => {
      dispatch(
        setAssociateHotelFilter({ operatorID, item: item as IHotelExtended }),
      );
      dispatch(resetAssociatedItems({ withFilters: false }));
    };
    const onSelectRegionFilter = (item: IRegion | null) => {
      dispatch(setAssociateRegionFilter({ operatorID, item }));
      dispatch(resetAssociatedItems({ withFilters: false }));
    };
    const onSelectTourFilter = (item: ITourFilter | null) => {
      dispatch(setAssociateTourFilter({ operatorID, item: item }));
      dispatch(resetAssociatedItems({ withFilters: false }));
    };

    useEffect(() => {
      if (
        type === AssociateType.hotels ||
        type === AssociateType.pansions ||
        type === AssociateType.roomTypes
      ) {
        const params: IAssociateParams = {
          operator: operatorID,
          country,
          tour_id: tourFilter?.id,
          region_id: regionFilter?.id,
        };
        const hToken = dispatch(
          fetchAssociateHotels(
            type === AssociateType.hotels
              ? params
              : { ...params, noSuggestions: true },
          ),
        );
        const tToken = dispatch(
          fetchAssociateTourFilters({ ...params, noSuggestions: true }),
        );
        const rToken = dispatch(
          fetchAssociateRegions({ ...params, noSuggestions: true }),
        );
        return () => {
          hToken.abort(REASON);
          rToken.abort(REASON);
          tToken.abort(REASON);
        };
      }
    }, [type, operatorID, country, tourFilter, regionFilter]);

    // Pansions and room types
    useEffect(() => {
      const params: IAssociateParams = {
        operator: operatorID,
        country,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        hotel_id: hotelFilter?.external_id ?? hotelFilter?.id,
      };
      const tokens: Promise<unknown>[] = [];
      if (type === AssociateType.pansions) {
        if (hotelFilter?.id) {
          const token = dispatch(fetchAssociatePansions(params));
          tokens.push(token);
        }
      } else if (type === AssociateType.roomTypes) {
        if (hotelFilter?.id) {
          const token = dispatch(fetchAssociateRoomTypes(params));
          tokens.push(token);
        } else {
          dispatch(clearAssociateDataForType({ type, operator: operatorID }));
        }
      }
      return () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        tokens.forEach((token) => token.abort(REASON));
      };
    }, [type, operatorID, country, hotelFilter]);

    useEffect(() => {
      const params = { operator: operatorID, country };

      if (type === AssociateType.regions) {
        const token = dispatch(fetchAssociateRegions(params));
        const rToken = dispatch(
          fetchAssociateResorts({ ...params, noSuggestions: true }),
        );
        return () => {
          token.abort(REASON);
          rToken.abort(REASON);
        };
      } else if (type === AssociateType.tours) {
        const token = dispatch(fetchAssociateTourFilters(params));
        const rToken = dispatch(
          fetchAssociateRegions({ ...params, noSuggestions: true }),
        );
        return () => {
          token.abort(REASON);
          rToken.abort(REASON);
        };
      } else if (type === AssociateType.createTours) {
        const token = dispatch(fetchAssociateResorts(params));
        return () => token.abort(REASON);
      } else if (type === AssociateType.cities) {
        const token = dispatch(fetchAssociateDestinationCities(params));
        return () => token.abort(REASON);
      }
    }, [type, operatorID, country]);

    return (
      <SelectList
        items={items}
        suggested={suggested}
        selected={selected}
        label={operatorName}
        onSelect={onSelect}
        id={operatorID}
        hotelFilterItems={hotelFilterItems}
        regionFilterItems={regionFilterItems}
        tourFilterItems={tourFilterItems}
        onSelectHotelFilter={onSelectHotelFilter}
        onSelectRegionFilter={onSelectRegionFilter}
        onSelectTourFilter={onSelectTourFilter}
        selectedHotelFilter={hotelFilter}
        selectedTourFilter={tourFilter}
        selectedRegionFilter={regionFilter}
        type={type}
      />
    );
  },
  (prevProps, nextProps) =>
    prevProps.country === nextProps.country &&
    prevProps.operatorID === nextProps.operatorID &&
    prevProps.type === nextProps.type,
);

ControlledSelectList.displayName = "ControlledSelectList";
