import React from "react";
import { MdClear } from "react-icons/md";
import {
  inputStyleVariant,
  InputStyleVariantType,
} from "../../constants/interface";
import { IconButton, InputAdornment, MenuItem, TextField } from "@mui/material";
import { ClipLoader } from "react-spinners";
import { Color } from "../../constants/colors";

interface Props {
  items: ISelectItem[];
  initialIndex?: number;
  label: string;
  hasNone?: boolean;
  onSelect?: (val: SelectValue | undefined) => void;
  value?: SelectValue;
  defaultValue?: SelectValue;
  required?: boolean;
  loading?: boolean;
  disabled?: boolean;
  maxWidth?: number;
  minWidth?: number;
  canClear?: boolean;

  variant?: InputStyleVariantType;
}

export const SelectField = ({
  items,
  label,
  hasNone,
  value,
  defaultValue,
  onSelect,
  required,
  loading,
  disabled,
  maxWidth,
  minWidth,
  canClear,
  variant,
}: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onSelect) {
      onSelect(event.target.value);
    }
  };

  return (
    <TextField
      suppressHydrationWarning={true}
      label={label}
      value={value ?? ""}
      defaultValue={defaultValue}
      variant={variant ?? inputStyleVariant}
      fullWidth={maxWidth === undefined}
      sx={{
        maxWidth,
        minWidth,
        flexGrow: 1,
      }}
      select
      required={required}
      onChange={handleChange}
      disabled={loading || disabled}
      InputProps={{
        endAdornment: loading ? (
          <InputAdornment position="end">
            <ClipLoader size={24} color={Color.brand} />
          </InputAdornment>
        ) : value !== undefined && canClear ? (
          <InputAdornment
            position="end"
            sx={{
              marginRight: "16px",
            }}
          >
            <IconButton
              size="small"
              onClick={() => onSelect && onSelect(undefined)}
            >
              <MdClear />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
    >
      {hasNone || items.length === 0 ? (
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
      ) : null}
      {items.map((itm, index) => (
        <MenuItem key={`si_${label}_${index}`} value={itm.id}>
          {itm.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export type SelectValue = string | number;
export interface ISelectItem {
  name: string;
  id: SelectValue;
}
