import styled from "@emotion/styled";
import { Color } from "../../constants/colors";

export const AppVersionStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  color: ${Color.border};
  padding: 8px;
`;
