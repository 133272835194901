import React, { useEffect } from "react";
import { GridLoader } from "react-spinners";
import { IReportInfo } from "../../api/types/reports/IReportInfo";
import { FullLoaderStyled } from "../../components/styled/FullLoader.styled";
import { EditorMode } from "../../components/template/types/EditorMode";
import { Color } from "../../constants/colors";
import { fetchDirectoryDataForReportInfo } from "../../store/directory/thunks/fetchDirectoryDataForReportInfo";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ReportEditor } from "./editor/ReportEditor";

interface Props {
  mode: EditorMode;
  source?: IReportInfo;
  onClose: () => void;
}
export const ModifyReportTemplate = ({ mode, source, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((s) => s.reportData.data);

  useEffect(() => {
    if (source) dispatch(fetchDirectoryDataForReportInfo(source));
  }, [source]);

  return data && data.id === source?.id ? (
    <ReportEditor mode={mode} source={data} onClose={onClose} />
  ) : (
    <FullLoaderStyled>
      <GridLoader color={Color.brand} />
    </FullLoaderStyled>
  );
};
