import React from "react";
import {
  IoAddOutline,
  IoReaderOutline,
  IoRocketOutline,
} from "react-icons/io5";
import { PagePath } from "../../../navigation/routes";
import { HeaderItem } from "../HeaderItem";
import { HeaderSubRowStyled } from "../styled/Header.styled";

export function ReportsSubNav() {
  return (
    <HeaderSubRowStyled>
      <HeaderItem
        path={PagePath.reports.results}
        subItem={true}
        notReady={true}
      >
        <IoRocketOutline />
        Результаты отчетов
      </HeaderItem>

      <HeaderItem
        path={PagePath.reports.templates}
        subItem={true}
        notReady={false}
      >
        <IoReaderOutline />
        Шаблоны
      </HeaderItem>

      <HeaderItem
        path={PagePath.reports.createTemplate}
        subItem={true}
        notReady={false}
      >
        <IoAddOutline />
        Новый шаблон
      </HeaderItem>
    </HeaderSubRowStyled>
  );
}
