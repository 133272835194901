import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiAction } from "../../../api/ApiAction";
import { endpoints } from "../../../api/endpoints";
import { IHotel } from "../../../api/types/directory/IHotel";
import { RootState } from "../../rootState";

interface Params {
  operator: number | string;
  departureCity: number;
  destinationCountry: number;
  tour_id?: string | number;
}

export const fetchHotels = createAsyncThunk<IHotel[], Params, { state: RootState }>(
  "directory/getHotels",
  async (param, thunkAPI) => {
    const result = await endpoints.directory.get(
      {
        action: ApiAction.getHotels,
        operator: param.operator,
        departure: param.departureCity,
        country: param.destinationCountry,
        tour_id: param.tour_id,
      },
      thunkAPI.signal,
    );

    return result as IHotel[];
  },
);
