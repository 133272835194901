let subscribers: (() => void)[] = [];
let statusSubscribers: (() => void)[] = [];

export const addAuthStatusSubscriber = (observer: () => void) => {
  subscribers.push(observer);
};

export const addUserStatusSubscriber = (observer: () => void) => {
  statusSubscribers.push(observer);
};

export const removeAuthStatusSubscriber = (observer: () => void) => {
  subscribers = subscribers.filter((o) => o !== observer);
};

export const removeUserStatusSubscriber = (observer: () => void) => {
  statusSubscribers = statusSubscribers.filter((o) => o !== observer);
};

export const postUserStatusEvent = () => {
  for (const observer of statusSubscribers) {
    observer();
  }
};

export const postLogoutEvent = () => {
  for (const observer of subscribers) {
    observer();
  }
};
