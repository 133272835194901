import styled from "@emotion/styled";
import { Color } from "../../../constants/colors";
import { globalRadius } from "../../../constants/sizes";

export const GridColumnStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  align-content: stretch;
  border-right: 1px solid ${Color.border};
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 60px;
  &:last-of-type {
    border-right: none;
    border-top-right-radius: ${globalRadius}px;
    border-bottom-right-radius: ${globalRadius}px;
  }
  &:first-of-type {
    flex-grow: 0;
    border-top-left-radius: ${globalRadius}px;
    border-bottom-left-radius: ${globalRadius}px;
  }
`;
