import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { Button } from "@mui/material";
import { Color } from "../../constants/colors";
import { removeAssociateError } from "../../store/associate/associateSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { clearJobError, removeUpdatedJob } from "../../store/jobs/jobsSlice";
import { IActionErrorInfo } from "../../store/jobs/types/IActionErrorInfo";
import {
  removeReportError,
  removeUpdatedReport,
} from "../../store/reports/reportsSlice";
import { removeServicesError } from "../../store/services/servicesSlice";
import { removeUserError } from "../../store/user/userSlice";

type SnackBarSource = "job" | "user" | "associate" | "service" | "report";
export const SnackBarManager = () => {
  const dispatch = useAppDispatch();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const jobsCreated = useAppSelector((state) => state.jobs.jobsUpdated);
  const jobErrors = useAppSelector((s) => s.jobs.jobErrors ?? []);
  const userErrors = useAppSelector((s) => s.user.errors ?? []);
  const associateErrors = useAppSelector((s) => s.associate.errors ?? []);
  const serviceErrors = useAppSelector((s) => s.services.errors ?? []);
  const reportErrors = useAppSelector((s) => s.reports.errors ?? []);
  const reportsCreated = useAppSelector((s) => s.reports.updated);

  const closeInfoBar = (id: number, type: "report" | "job") => {
    if (type === "job") dispatch(removeUpdatedJob(id));
    if (type === "report") dispatch(removeUpdatedReport(id));
  };

  const closeErrorBar = (id: number, type: SnackBarSource) => {
    if (type === "job") dispatch(clearJobError(id));
    if (type === "user") dispatch(removeUserError(id));
    if (type === "associate") dispatch(removeAssociateError(id));
    if (type === "service") dispatch(removeServicesError(id));
    if (type === "report") dispatch(removeReportError(id));
  };

  useEffect(() => {
    jobsCreated.forEach((info) =>
      enqueueSnackbar(info.message, {
        key: info.id,
        autoHideDuration: 5000,
        variant: "success",
        preventDuplicate: true,
        onClose: () => closeInfoBar(info.id, "job"),
      }),
    );
    reportsCreated.forEach((info) =>
      enqueueSnackbar(info.message, {
        key: info.id,
        autoHideDuration: 5000,
        variant: "success",
        preventDuplicate: true,
        onClose: () => closeInfoBar(info.id, "report"),
      }),
    );
  }, [jobsCreated, reportsCreated]);

  useEffect(() => {
    const infoToSnackBar = (info: IActionErrorInfo, type: SnackBarSource) => {
      enqueueSnackbar(info.message, {
        key: info.id,
        autoHideDuration: null,
        variant: "error",
        preventDuplicate: true,
        onClose: () => closeErrorBar(info.id, type),
        action: () => (
          <Button
            sx={{ color: Color.text.onDark }}
            onClick={() => closeSnackbar(info.id)}
          >
            Закрыть
          </Button>
        ),
      });
    };
    jobErrors.forEach((info) => infoToSnackBar(info, "job"));
    userErrors.forEach((info) => infoToSnackBar(info, "user"));
    associateErrors.forEach((info) => infoToSnackBar(info, "associate"));
    serviceErrors.forEach((info) => infoToSnackBar(info, "service"));
    reportErrors.forEach((info) => infoToSnackBar(info, "report"));
  }, [jobErrors, userErrors, associateErrors, serviceErrors, reportErrors]);

  return <></>;
};
