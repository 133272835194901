import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchServiceTourFilters } from "../thunks/fetchServiceTourFilters";
import { IServicesState } from "../types/IServicesState";

export const addFetchServiceTourFilters = (builder: ActionReducerMapBuilder<IServicesState>) => {
  builder.addCase(fetchServiceTourFilters.pending, (state) => {
    state.loadingKeys = [...state.loadingKeys, `tourFilters`];
  });
  builder.addCase(fetchServiceTourFilters.rejected, (state) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `tourFilters`);
  });
  builder.addCase(fetchServiceTourFilters.fulfilled, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `tourFilters`);
    state.tourFilters = [...action.payload];
  });
};
