import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import { DirectoryCreateAction } from "../../../api/types/DirectoryCreateAction";
import { DirectoryEditAction } from "../../../api/types/DirectoryEditAction";
import { AppDispatch } from "../../appDispatch";
import { RootState } from "../../rootState";

interface Params {
  name: string;
  operator: number;
  country: string;
  resorts: string;
}

export const createRegion = createAsyncThunk<
  void,
  Params,
  { state: RootState; dispatch: AppDispatch }
>("associate/createRegion", async (params, thunkAPI) => {
  await endpoints.directory.createRegion(
    {
      action: DirectoryCreateAction.createRegion,
      ...params,
    },
    thunkAPI.signal,
  );
});

interface EditParams extends Params {
  id: number | string;
}

export const editRegion = createAsyncThunk<
  void,
  EditParams,
  { state: RootState; dispatch: AppDispatch }
>("associate/editRegion", async (params, thunkAPI) => {
  await endpoints.directory.editRegion(
    {
      action: DirectoryEditAction.editRegion,
      ...params,
    },
    thunkAPI.signal,
  );
});
