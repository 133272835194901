import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiAction } from "../../../api/ApiAction";
import { endpoints } from "../../../api/endpoints";
import { IRoomType } from "../../../api/types/directory/IRoomType";
import { AppDispatch } from "../../appDispatch";
import { RootState } from "../../rootState";
import { IAssociateParams } from "./types/IAssociateParams";

export const fetchAssociateRoomTypes = createAsyncThunk<
  IRoomType[],
  IAssociateParams,
  { state: RootState; dispatch: AppDispatch }
>("associate/fetchRoomTypes", async (params, thunkAPI) => {
  const result = await endpoints.directory.get(
    {
      action: ApiAction.getRooms,
      operator: params.operator,
      country: params.country,
      hotel_id: params.hotel_id,
      full: params.operator === 10,
    },
    thunkAPI.signal,
  );

  return result as IRoomType[];
});
