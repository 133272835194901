import { IGross } from "../store/services/types/gross";
import { MarginNotes } from "../store/services/types/marginNotes";
import { getSessionToken, tezGET, tezPOST } from "./api";
import { EditApiAction } from "./ApiAction";
import { apiPath, baseURL } from "./baseURL";
import { IAuthParams } from "./types/auth/IAuthParams";
import { IAuthResponse } from "./types/auth/IAuthResponse";
import {
  DirectoryItem,
  IDirectoryResponse,
  IServicePriceResponse,
} from "./types/directory/IResponse";
import { IServicePrice } from "./types/directory/IServicePrice";
import { IEditMarginNotesParams } from "./types/EditMarginNoteParams";
import { IBindParams, IUnbindParams } from "./types/IBindParams";
import { ICreateGrossParams } from "./types/ICreateGrossParams";
import { ICreateRegionParams } from "./types/ICreateRegionParams";
import { ICreateServiceParams } from "./types/ICreateServiceParams";
import { ICreateTourParams } from "./types/ICreateTourParams";
import { IDeleteGrossParams } from "./types/IDeleteGrossParams";
import { IDirectoryParams } from "./types/IDirectoryParams";
import { IEditGrossParams } from "./types/IEditGrossParams";
import { IEditRegionParams } from "./types/IEditRegionParams";
import { IEditRoomParams } from "./types/IEditRoomParams";
import { IEditTourParams } from "./types/IEditTourParams";
import { IBaseResponse } from "./types/jobs/IBaseResponse";
import { ICreateJobResponse } from "./types/jobs/ICreateJobResponse";
import { IEditJobParams } from "./types/jobs/IEditJobParams";
import { IGetJobsParams } from "./types/jobs/IGetJobsParams";
import { IJobActionParams } from "./types/jobs/IJobActionParams";
import { IJobCSVParams } from "./types/jobs/IJobCSVParams";
import { IJobParams } from "./types/jobs/IJobParams";
import { IJobResponse } from "./types/jobs/IJobResponse";
import { IRestartJobParams } from "./types/jobs/IRestartJobParams";
import { ICreateReportResponse } from "./types/reports/ICreateReportResponse";
import { IGetReportsParams } from "./types/reports/IGetReportsParams";
import { IReportActionParams } from "./types/reports/IReportActionParams";
import { IReportGenerateParams } from "./types/reports/IReportGenerateParams";
import {
  IEditReportParams,
  IReportParams,
} from "./types/reports/IReportParams";
import { IReportResponse } from "./types/reports/IReportResponse";
import {
  ICreateUserParams,
  IEditUserParams,
} from "./types/user/ICreateUserParams";
import { IGetUserResponse } from "./types/user/IGetUserResponse";
import { IGetUsersResponse } from "./types/user/IGetUsersResponse";
import { IUserResponse } from "./types/user/IUserResponse";
import { getDataForAction } from "./utils/getDataForAction";
import { paramsObjectToString } from "./utils/paramsObjectToString";

export const endpoints = {
  directory: {
    get: async (
      params: IDirectoryParams,
      signal?: AbortSignal,
    ): Promise<DirectoryItem[]> => {
      const data = await tezGET<IDirectoryResponse>({ ...params }, signal);
      return getDataForAction(params.action, data);
    },
    getGross: async (
      params: IDirectoryParams,
      signal?: AbortSignal,
    ): Promise<{ gross: IGross[] }> => {
      const data = await tezGET<{ gross: IGross[] }>({ ...params }, signal);
      return data;
    },
    getMarginNotes: async (
      params: IDirectoryParams,
      signal?: AbortSignal,
    ): Promise<{ notes: MarginNotes[] }> => {
      return await tezGET<{ notes: MarginNotes[] }>({ ...params }, signal);
    },
    getTransfersInfo: async (
      params: IDirectoryParams,
      signal?: AbortSignal,
    ): Promise<IServicePrice[]> => {
      const data = await tezGET<IServicePriceResponse>({ ...params }, signal);
      return data.transfer as IServicePrice[];
    },
    getInsuranceInfo: async (
      params: IDirectoryParams,
      signal?: AbortSignal,
    ): Promise<IServicePrice[]> => {
      const data = await tezGET<IServicePriceResponse>({ ...params }, signal);
      return data.insurance as IServicePrice[];
    },
    getMarginInfo: async (
      params: IDirectoryParams,
      signal?: AbortSignal,
    ): Promise<IServicePrice[]> => {
      const data = await tezGET<IServicePriceResponse>({ ...params }, signal);
      return data.margin as IServicePrice[];
    },
    edit: async (
      params: IDirectoryParams,
      signal?: AbortSignal,
    ): Promise<IBaseResponse> => {
      return await tezGET<IBaseResponse>({ ...params }, signal);
    },
    editGross: async (
      params: IEditGrossParams,
      signal?: AbortSignal,
    ): Promise<IBaseResponse> => {
      return await tezGET<IBaseResponse>({ ...params }, signal);
    },
    editMarginNotes: async (
      params: IEditMarginNotesParams,
      signal?: AbortSignal,
    ): Promise<IBaseResponse> => {
      return await tezGET<IBaseResponse>({ ...params }, signal);
    },
    editRoom: async (
      params: IEditRoomParams,
      signal?: AbortSignal,
    ): Promise<IBaseResponse> => {
      return await tezGET<IBaseResponse>(
        { action: EditApiAction.editRoom, ...params },
        signal,
      );
    },
    bind: async (
      params: IBindParams,
      signal?: AbortSignal,
    ): Promise<IBaseResponse> => {
      return await tezGET<IBaseResponse>({ ...params }, signal);
    },
    unbind: async (
      params: IUnbindParams,
      signal?: AbortSignal,
    ): Promise<IBaseResponse> => {
      return await tezGET<IBaseResponse>({ ...params }, signal);
    },
    /**
     * @deprecated use createRegion instead
     */
    createGroupResort: async (
      params: ICreateRegionParams,
      signal?: AbortSignal,
    ): Promise<IBaseResponse> => {
      return await tezGET<IBaseResponse>({ ...params }, signal);
    },
    createRegion: async (
      params: ICreateRegionParams,
      signal?: AbortSignal,
    ): Promise<IBaseResponse> => {
      return await tezGET<IBaseResponse>({ ...params }, signal);
    },
    editRegion: async (
      params: IEditRegionParams,
      signal?: AbortSignal,
    ): Promise<IBaseResponse> => {
      return await tezGET<IBaseResponse>({ ...params }, signal);
    },
    createTour: async (
      params: ICreateTourParams,
      signal?: AbortSignal,
    ): Promise<IBaseResponse> => {
      return await tezGET<IBaseResponse>({ ...params }, signal);
    },
    editTour: async (
      params: IEditTourParams,
      signal?: AbortSignal,
    ): Promise<IBaseResponse> => {
      return await tezGET<IBaseResponse>({ ...params }, signal);
    },
    createGross: async (
      params: ICreateGrossParams,
      signal?: AbortSignal,
    ): Promise<IBaseResponse> => {
      return await tezGET<IBaseResponse>({ ...params }, signal);
    },
    createService: async (
      params: ICreateServiceParams,
      signal?: AbortSignal,
    ): Promise<IBaseResponse> => {
      return await tezGET<IBaseResponse>({ ...params }, signal);
    },
    deleteGross: async (
      params: IDeleteGrossParams,
      signal?: AbortSignal,
    ): Promise<IBaseResponse> => {
      return await tezGET<IBaseResponse>({ ...params }, signal);
    },
  },
  job: {
    create: async (
      params: IJobParams,
      signal?: AbortSignal,
    ): Promise<ICreateJobResponse> => {
      return await tezGET<ICreateJobResponse>({ ...params }, signal);
    },
    edit: async (
      params: IEditJobParams,
      signal?: AbortSignal,
    ): Promise<IJobResponse> => {
      return await tezGET<IJobResponse>({ ...params }, signal);
    },
    remove: async (
      params: IJobActionParams,
      signal?: AbortSignal,
    ): Promise<IJobResponse> => {
      return await tezGET<IJobResponse>({ ...params }, signal);
    },
    info: async (
      params: IJobActionParams,
      signal?: AbortSignal,
    ): Promise<IJobResponse> => {
      return await tezGET<IJobResponse>({ ...params }, signal);
    },
    list: async (
      params: IGetJobsParams,
      signal?: AbortSignal,
    ): Promise<IJobResponse> => {
      return await tezGET<IJobResponse>({ ...params }, signal);
    },
    start: async (
      params: IJobActionParams,
      signal?: AbortSignal,
    ): Promise<IJobResponse> => {
      return await tezGET<IJobResponse>({ ...params }, signal);
    },
    stop: async (
      params: IJobActionParams,
      signal?: AbortSignal,
    ): Promise<IJobResponse> => {
      return await tezGET<IJobResponse>({ ...params }, signal);
    },
    restart: async (
      params: IRestartJobParams,
      signal?: AbortSignal,
    ): Promise<IJobResponse> => {
      return await tezGET<IJobResponse>({ ...params }, signal);
    },
    getCSVLink: (params: IJobCSVParams): string => {
      const session = getSessionToken();
      return `${baseURL}${apiPath}?${paramsObjectToString({
        ...params,
        session: session,
      })}`;
    },
    getStats: async (
      params: IJobActionParams,
      signal?: AbortSignal,
    ): Promise<IJobResponse> => {
      return await tezGET<IJobResponse>({ ...params }, signal);
    },
  },
  report: {
    create: async (
      params: IReportParams,
      signal?: AbortSignal,
    ): Promise<ICreateReportResponse> => {
      return await tezGET<ICreateReportResponse>({ ...params }, signal);
    },
    edit: async (
      params: IEditReportParams,
      signal?: AbortSignal,
    ): Promise<IReportResponse> => {
      return await tezGET<IReportResponse>({ ...params }, signal);
    },
    remove: async (
      params: IReportActionParams,
      signal?: AbortSignal,
    ): Promise<IReportResponse> => {
      return await tezGET<IReportResponse>({ ...params }, signal);
    },
    getGenerateReportLink: (params: IReportGenerateParams): string => {
      const session = getSessionToken();
      return `${baseURL}${apiPath}?${paramsObjectToString({
        ...params,
        session: session,
      })}`;
    },
    generate: async (
      params: IReportActionParams,
      signal?: AbortSignal,
    ): Promise<IReportResponse> => {
      return await tezGET<IReportResponse>({ ...params }, signal);
    },
    list: async (
      params: IGetReportsParams,
      signal?: AbortSignal,
    ): Promise<IReportResponse> => {
      return await tezGET<IReportResponse>({ ...params }, signal);
    },
    info: async (
      params: IReportActionParams,
      signal?: AbortSignal,
    ): Promise<IReportResponse> => {
      return await tezGET<IReportResponse>({ ...params }, signal);
    },
  },
  auth: {
    login: async (
      params: IAuthParams,
      signal?: AbortSignal,
    ): Promise<IAuthResponse> => {
      return tezPOST<IAuthResponse>("?action=auth", { ...params }, signal);
    },
    logout: async (signal?: AbortSignal): Promise<IAuthResponse> => {
      return tezGET({ action: "logout" }, signal);
    },
  },
  user: {
    create: async (
      params: ICreateUserParams,
      signal?: AbortSignal,
    ): Promise<IUserResponse> => {
      return tezPOST<IUserResponse>(
        "?action=createUser",
        { ...params },
        signal,
      );
    },
    delete: async (
      id: number,
      signal?: AbortSignal,
    ): Promise<IUserResponse> => {
      return tezGET<IUserResponse>({ action: "deleteUser", id }, signal);
    },
    edit: async (
      params: IEditUserParams,
      signal?: AbortSignal,
    ): Promise<IUserResponse> => {
      return tezPOST<IUserResponse>("?action=editUser", { ...params }, signal);
    },
    get: async (
      id: number,
      signal?: AbortSignal,
    ): Promise<IGetUserResponse> => {
      return tezGET<IGetUserResponse>({ action: "getUser", id }, signal);
    },
    getAll: async (signal?: AbortSignal): Promise<IGetUsersResponse> => {
      return tezGET<IGetUsersResponse>({ action: "getUsers" }, signal);
    },
  },
};
