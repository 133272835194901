export enum JobAction {
  createJob = "createJob",
  editJob = "editJob",
  deleteJob = "deleteJob",
  getJobs = "getJobs",
  getJob = "getJob",
  startJob = "startJob",
  stopJob = "stopJob",
  restartJob = "restartJob",
  getJobStats = "getJobStats",
  getToursCSV = "getToursCSV",
  getToursXLSX = "getToursXLSX",
}
