import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiAction } from "../../../api/ApiAction";
import { endpoints } from "../../../api/endpoints";
import { ITourType } from "../../../api/types/directory/ITourType";
import { RootState } from "../../rootState";

interface Params {
  operator: number
  departureCity: number
  destinationCountry: number
}

export const fetchTourTypes = createAsyncThunk<ITourType[], Params, { state: RootState }>(
  "directory/getTourTypes",
  async (param, thunkAPI) => {
    const result = await endpoints.directory.get(
      {
        action: ApiAction.getTourTypes,
        operator: param.operator,
        departure: param.departureCity,
        country: param.destinationCountry,
      },
      thunkAPI.signal,
    );

    return result as ITourType[];
  },
);
