import { IGross } from "../../../../store/services/types/gross";

export enum KEYS {
  KEYWORD = "keywords",
  RATE = "rate",
}

export type GrossData = {
  [operatorId: number]: {
    data: { [grossId: string]: IGross };
    ids: string[];
  };
};
