import { Grid, Paper, Table, TableBody, TableContainer } from "@mui/material";
import React from "react";
import { LoadingRow } from "../../components/template/row/LoadingRow";
import { useAppSelector } from "../../store/hooks";
import { ReportsFilter } from "./common/ReportsFilter";
import { ReportsHeader } from "./results/ReportsHeader";
import { ReportsRow } from "./results/ReportsRow";

const colSpan = 7;
export const ReportResults = () => {
  const reports = useAppSelector((s) => s.reports.reports ?? []);
  const loadingReports = useAppSelector((s) => s.reports.loadingReports);
  return (
    <Grid container spacing={1} rowSpacing={2} alignItems={"flex-end"}>
      <Grid item xs={12}>
        <ReportsFilter mode={"reports"} />
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="Отчеты">
            <ReportsHeader />
            <TableBody>
              {loadingReports || reports.length === 0 ? (
                <LoadingRow colSpan={colSpan} loading={loadingReports} />
              ) : null}
              {reports.map((info) => (
                <ReportsRow key={`${info.id}`} info={info} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
