import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiAction } from "../../../api/ApiAction";
import { endpoints } from "../../../api/endpoints";
import { IDestinationCountry } from "../../../api/types/directory/IDestinationCountry";
import { RootState } from "../../rootState";
import { DirectoryTarget } from "./directoryTarget";

interface Params {
  operator?: number | string;
  departureCity?: number;
  target?: DirectoryTarget;

  multiOperator?: boolean;
}

export const fetchDestinationCountries = createAsyncThunk<
  IDestinationCountry[],
  Params,
  { state: RootState }
>("directory/getCountries", async (param, thunkAPI) => {
  const result = await endpoints.directory.get(
    {
      action: param.multiOperator
        ? ApiAction.getCountries
        : ApiAction.getDestinationCountries,
      operator: param.operator,
      departure: param.departureCity,
    },
    thunkAPI.signal,
  );

  return result as IDestinationCountry[];
});

export const fetchCountriesForOperators = createAsyncThunk<
  IDestinationCountry[],
  Params,
  { state: RootState }
>("directory/getCountries", async (param, thunkAPI) => {
  const result = await endpoints.directory.get(
    {
      action: ApiAction.getCountries,
      operator: param.operator,
    },
    thunkAPI.signal,
  );

  return result as IDestinationCountry[];
});
