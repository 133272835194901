import { createAsyncThunk } from "@reduxjs/toolkit";
import { IDepartureCity } from "../../../api/types/directory/IDepartureCity";
import { RootState } from "../../rootState";
import { endpoints } from "../../../api/endpoints";
import { ApiAction } from "../../../api/ApiAction";

interface Params {
  operatorId?: number | string;
  forFilter?: boolean;
}

export const fetchDepartureCity = createAsyncThunk<IDepartureCity[], Params, { state: RootState }>(
  "directory/getDepartureCities",
  async (param, thunkAPI) => {
    const result = await endpoints.directory.get(
      {
        action: ApiAction.getDepartureCities,
        operator: param.operatorId,
      },
      thunkAPI.signal,
    );

    return result as IDepartureCity[];
  },
);
