import styled from "@emotion/styled";
import { baseSpacingValue, globalRadius } from "../../../constants/sizes";
import { Color } from "../../../constants/colors";
import { adjustColor } from "../../../utils/adjustColor";

interface Props {
  isActive?: boolean
}

export const HeaderItemStyled = styled.div<Props>`
  display: inline-block;
  position: relative;
  margin-right: ${baseSpacingValue / 2}px;
  &:last-of-type {
    margin-right: 0;
  }

  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 0.875rem;
    text-decoration: none;

    padding: ${baseSpacingValue}px ${baseSpacingValue * 2}px;

    border-radius: ${globalRadius}px;
    color: ${(props) => (props.isActive ? Color.text.onDark : Color.text.onLight)};
    background-color: ${(props) => (props.isActive ? Color.brand : "transparent")};

    //transition: all 0.2s ease-in-out;

    &:hover,
    &:active,
    &:focus {
      background-color: ${(props) =>
        props.isActive ? Color.brand : adjustColor(Color.background, -10)};
    }
  }

  svg {
    margin-right: ${baseSpacingValue}px;
  }
`;

export const HeaderSubItemStyled = styled(HeaderItemStyled)`
  a {
    font-size: 13px;

    color: ${(props) => (props.isActive ? Color.text.onLight : Color.text.onDark)};
    background-color: ${(props) =>
      props.isActive ? adjustColor(Color.background, -10) : "transparent"};

    &:hover,
    &:active,
    &:focus {
      color: ${Color.text.onLight};
      background-color: ${adjustColor(Color.background, -10)};
    }
  }
`;

export const HeaderItemSeparatorStyled = styled.div`
  padding: 8px 16px;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: ${adjustColor(Color.border, 100)};
  svg {
    margin-right: 0;
  }
`;

export const HeaderItemRightStyled = styled(HeaderItemStyled)`
  margin-left: 0;
`;
