import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import { ICreateJobResponse } from "../../../api/types/jobs/ICreateJobResponse";
import { IJobParams } from "../../../api/types/jobs/IJobParams";
import { JobStatus } from "../../../api/types/jobs/JobStatus";

export const createJobFromTemplate = createAsyncThunk<ICreateJobResponse, IJobParams>(
  "jobs/createNew",
  async (params, thunkAPI) => {
    const response = await endpoints.job.create(
      { ...params, status: JobStatus.pending },
      thunkAPI.signal,
    );
    if (response.jobID) {
      return response;
    } else throw new Error(response?.error ?? `Не удалось запустить задачу "${params.name}"`);
  },
);
