import { LoadingButton } from "@mui/lab";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DirectoryCreateAction } from "../../../api/types/DirectoryCreateAction";
import { SpacedContainerStyled } from "../../../components/styled/SpacedContainerStyled";
import { StyledTableCell } from "../../../components/styled/TableCell.styled";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setServiceHotel } from "../../../store/services/servicesSlice";
import { createOrEditService } from "../../../store/services/thunks/createOrEditService";

const allowedChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "."];
enum SaveTarget {
  TRANSFER = "transfer",
  MARGIN = "margin",
  INSURANCE = "insurance",
}

type Rows = {
  id: number;
  hotelName: string;
  transfer: string;
  insurance: string;
  margin: string;
};

export const ServiceEditor = () => {
  const dispatch = useAppDispatch();

  const loading = useAppSelector((s) => s.services.loadingKeys);
  const { insurance, margin, transfers } = useAppSelector((s) => s.services);
  const hotels = useAppSelector((s) => s.services.selection.hotels);
  const fieldsEnabled = useAppSelector(
    (s) => s.services.selection.region !== null && s.services.hotels.length > 0,
  );

  const [rows, setRows] = useState<Rows[]>([]);
  const [hasFormChanged, setHasFormChanged] = useState(false);

  useEffect(() => {
    const newState = hotels.map((hotel) => ({
      id: +hotel.id,
      hotelName: hotel.name,
      transfer: transfers[hotel.id]?.price ?? "",
      insurance: insurance[hotel.id]?.price ?? "",
      margin: margin[hotel.id]?.price ?? "",
    }));

    setRows(newState);
  }, [hotels, insurance, margin, transfers]);

  const onSave = () => {
    rows.forEach((item) => {
      const actionsArr = [
        {
          action: DirectoryCreateAction.createTransfer,
          hotel_id: item.id,
          price: item.transfer,
        },
        {
          action: DirectoryCreateAction.createInsurance,
          hotel_id: item.id,
          price: item.insurance,
        },
        {
          action: DirectoryCreateAction.createMargin,
          hotel_id: item.id,
          price: item.margin,
        },
      ];

      actionsArr.forEach((action) => {
        if (action.price) {
          dispatch(
            createOrEditService({
              action: action.action,
              hotels: [action.hotel_id],
              price: action.price,
            }),
          );
        }
      });
    });

    setRows([]);
    dispatch(setServiceHotel([]));
    setHasFormChanged(false);
  };

  const handleChangeValue = (
    val: string,
    target: SaveTarget,
    index: number,
  ) => {
    const filteredValue = val
      .split("")
      .filter((char) => allowedChars.includes(char))
      .join("");

    const newState = [
      ...rows.slice(0, index),
      {
        ...rows[index],
        [target]: filteredValue,
      },
      ...rows.slice(index + 1),
    ];

    setRows(newState);
    setHasFormChanged(true);
  };

  const renderInput = (value: string, target: SaveTarget, index: number) => (
    <TextField
      size="small"
      type="number"
      variant="standard"
      disabled={!fieldsEnabled}
      value={value}
      onChange={(e) =>
        handleChangeValue(
          e.target.value.length > 0 ? e.target.value : "",
          target,
          index,
        )
      }
    />
  );

  if (!rows.length) {
    return <p>Укажите фильтры</p>;
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ marginTop: "24px" }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Отель</StyledTableCell>
              <StyledTableCell>Трансфер</StyledTableCell>
              <StyledTableCell>Страховка</StyledTableCell>
              <StyledTableCell>Маржа</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={row.hotelName}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <StyledTableCell>
                  <span>{row.hotelName}</span>
                  <span>[{row.id}]</span>
                </StyledTableCell>
                <StyledTableCell>
                  {renderInput(row.transfer, SaveTarget.TRANSFER, index)}
                </StyledTableCell>
                <StyledTableCell>
                  {renderInput(row.insurance, SaveTarget.INSURANCE, index)}
                </StyledTableCell>
                <StyledTableCell>
                  {renderInput(row.margin, SaveTarget.MARGIN, index)}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <SpacedContainerStyled>
        <LoadingButton
          loading={
            loading.includes(SaveTarget.TRANSFER) ||
            loading.includes(SaveTarget.MARGIN) ||
            loading.includes(SaveTarget.INSURANCE)
          }
          disabled={!fieldsEnabled || !hasFormChanged}
          variant="contained"
          size="small"
          fullWidth={true}
          onClick={onSave}
        >
          Сохранить
        </LoadingButton>
      </SpacedContainerStyled>
    </>
  );
};
