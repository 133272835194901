import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import { RootState } from "../../rootState";
import { DeleteApiAction } from "../../../api/ApiAction";

export const deleteGross = createAsyncThunk<void, string, { state: RootState }>(
  "services/deleteGross",
  async (id, thunkAPI) => {
    await endpoints.directory.deleteGross(
      {
        action: DeleteApiAction.deleteGross,
        id,
      },
      thunkAPI.signal,
    );
  },
);
