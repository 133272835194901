import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchServiceRegions } from "../thunks/fetchServiceRegions";
import { IServicesState } from "../types/IServicesState";

export const addFetchServiceRegions = (builder: ActionReducerMapBuilder<IServicesState>) => {
  builder.addCase(fetchServiceRegions.pending, (state) => {
    state.loadingKeys = [...state.loadingKeys, `regions`];
  });
  builder.addCase(fetchServiceRegions.rejected, (state) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `regions`);
  });
  builder.addCase(fetchServiceRegions.fulfilled, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `regions`);
    state.regions = [...action.payload];
  });
};
