import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../rootState";
import { endpoints } from "../../../api/endpoints";
import { ApiAction } from "../../../api/ApiAction";
import { IDestinationCity } from "../../../api/types/directory/IDestinationCity";

interface Params {
  operator: number
  departureCity: number
  destinationCountry: number
}

export const fetchDestinationCities = createAsyncThunk<
  IDestinationCity[],
  Params,
  { state: RootState }
>("directory/getDestinationCities", async (param, thunkAPI) => {
  const result = await endpoints.directory.get(
    {
      action: ApiAction.getDestinationCities,
      operator: param.operator,
      departure: param.departureCity,
      country: param.destinationCountry,
    },
    thunkAPI.signal,
  );

  return result as IDestinationCity[];
});
