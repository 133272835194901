import { createAsyncThunk } from "@reduxjs/toolkit";
import { IDirectoryResponse } from "../../../api/types/directory/IResponse";
import { IReportInfo } from "../../../api/types/reports/IReportInfo";
import { AppDispatch } from "../../appDispatch";
import { RootState } from "../../rootState";
import { fetchDepartureCity } from "./fetchDepartureCity";
import { fetchDestinationCities } from "./fetchDestinationCities";
import { fetchDestinationCountries } from "./fetchDestinationCountries";
import { fetchHotels } from "./fetchHotels";
import { fetchTourFilters } from "./fetchTourFilters";

export const fetchDirectoryDataForReportInfo = createAsyncThunk<
  IDirectoryResponse,
  IReportInfo,
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>("reports/fetchDirectoryForReportInfo", async (params, thunkAPI) => {
  await thunkAPI.dispatch(
    fetchDepartureCity({
      operatorId: 10,
    }),
  );
  await thunkAPI.dispatch(
    fetchDestinationCountries({
      operator: 10,
      departureCity: params.city_from,
      multiOperator: false,
    }),
  );
  await thunkAPI.dispatch(
    fetchDestinationCities({
      departureCity: params.city_from,
      operator: 10,
      destinationCountry: params.country,
    }),
  );
  await thunkAPI.dispatch(
    fetchTourFilters({
      operator: 10,
      country: params.country,
    }),
  );

  await thunkAPI.dispatch(
    fetchHotels({
      operator: 10,
      departureCity: params.city_from,
      destinationCountry: params.country,
      tour_id: params.tour_id,
    }),
  );

  const state = thunkAPI.getState();
  const operators = state.directory.operators;
  const departureCities = state.directory.departureCities;
  const destinationCountries = state.directory.destinationCountries;
  const destinationCities = state.directory.destinationCities;
  const tourFilters = state.directory.tourFilters;
  const hotels = state.directory.hotels;
  return {
    operators,
    departureCities,
    destinationCountries,
    destinationCities,
    tourFilters,
    hotels,
  } as IDirectoryResponse;
});
