import { IReportsState } from "./types/IReportsState";

export const initialState: IReportsState = {
  loadingKeys: [],
  loadingReportKeys: [],
  updated: [],
  templates: [],
  reports: [],
  filters: {
    status: -1,
  },
  loadingReports: false,
  reportsCreating: false,
  errors: [],
};
