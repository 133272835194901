import { JobStatus } from "../../../api/types/jobs/JobStatus";
import { stopJob } from "../thunks/stopJob";
import { IActionErrorInfo } from "../types/IActionErrorInfo";
import { JobsExtraBuilder } from "./types/jobsExtraBuilder";

export const addStopJob = (builder: JobsExtraBuilder) => {
  builder.addCase(stopJob.pending, (state, action) => {
    state.loadingJobKeys = [...state.loadingJobKeys, action.meta.arg];
  });
  builder.addCase(stopJob.rejected, (state, action) => {
    if (state.loadingJobKeys) {
      state.loadingJobKeys = state.loadingJobKeys.filter((id) => id !== action.meta.arg);
    }

    const error: IActionErrorInfo = {
      id: Date.now(),
      message: `Ошибка остановки задачи ID: ${action.meta.arg}!: ${action.error.message}`,
    };
    state.jobErrors = state.jobErrors ? [...state.jobErrors, error] : [error];
  });

  builder.addCase(stopJob.fulfilled, (state, action) => {
    if (state.loadingJobKeys) {
      state.loadingJobKeys = state.loadingJobKeys.filter((id) => id !== action.meta.arg);
    }
    state.allJobs = state.allJobs.map((j) => {
      if (j.id == action.meta.arg) {
        return { ...j, status: `${JobStatus.stopped}` };
      }
      return j;
    });
  });
};
