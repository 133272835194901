import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DateObject } from "react-multi-date-picker";
import { IHotel } from "../../../api/types/directory/IHotel";
import { IOperator } from "../../../api/types/directory/IOperator";
import { ITourFilter } from "../../../api/types/directory/ITourFilter";
import { IReportData } from "../../../api/types/reports/IReportInfo";
import { IReportParams } from "../../../api/types/reports/IReportParams";
import { ReportAction } from "../../../api/types/reports/ReportAction";
import { DayPicker } from "../../../components/picker/DayPicker";
import { MultiDateSelect } from "../../../components/select/MultiDateSelect";
import { SelectField } from "../../../components/select/SelectField";
import { SpacedContainerStyled } from "../../../components/styled/SpacedContainerStyled";
import { nightsDataSource } from "../../../components/template/data/nightsDataSource";
import { EditorMode } from "../../../components/template/types/EditorMode";
import { titleForMode } from "../../../components/template/utils/TitleForMode";
import { inputStyleVariant } from "../../../constants/interface";
import { templateTypeDataSource } from "../../../constants/TemplateTypeDataSource";
import { fetchDepartureCity } from "../../../store/directory/thunks/fetchDepartureCity";
import { fetchDestinationCities } from "../../../store/directory/thunks/fetchDestinationCities";
import { fetchDestinationCountries } from "../../../store/directory/thunks/fetchDestinationCountries";
import { fetchHotels } from "../../../store/directory/thunks/fetchHotels";
import { fetchOperators } from "../../../store/directory/thunks/fetchOperators";
import { fetchTourFilters } from "../../../store/directory/thunks/fetchTourFilters";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { createReportFromTemplate } from "../../../store/reports/thunks/createReportFromTemplate";
import { createReportTemplate } from "../../../store/reports/thunks/createReportTemplate";
import { editReportTemplate } from "../../../store/reports/thunks/editReportTemplate";
import { ReportStatus } from "../../../store/reports/types/reportStatus";
import {
  accommodationList,
  AccommodationType,
  accommodationTypeFromSource,
  titleForAccommodationType,
} from "./types/AccommodationType";

interface Props {
  mode: EditorMode;

  source?: IReportData;
  onClose: () => void;
}

function accommodationForSource(source?: IReportData): AccommodationType[] {
  if (!source) return [];
  const items = source.accommodation.split(",");
  return items
    .map((itm) => accommodationTypeFromSource(itm))
    .filter((itm) => itm !== undefined) as AccommodationType[];
}

export const ReportEditor = ({ mode, source, onClose }: Props) => {
  const dispatch = useAppDispatch();

  const [name, setName] = useState(source?.name ?? "");
  const [templateType, setTemplateType] = useState(source?.type ?? 0);
  const [operator, setOperator] = useState<IOperator[]>(
    source?.sOperators ?? [],
  );
  const [departureCity, setDepartureCity] = useState<number | undefined>(
    source?.city_from,
  );
  const [destinationCountry, setDestinationCountry] = useState<
    number | undefined
  >(source?.country);

  const [destinationCity, setDestinationCity] = useState<number | undefined>(
    source?.city_id,
  );
  const [tour, setTour] = useState<ITourFilter[]>(source?.sTourFilter ?? []);
  const [tourDates, setTourDates] = useState<DateObject[]>(
    source?.dates.split(",").map((d) => {
      const dObj = new DateObject();
      dObj.setFormat("DD.MM.YYYY");
      return dObj.parse(d);
    }) ?? [],
  );
  const [nights, setNights] = useState<number[]>(
    source?.nights.split(",").map((n) => parseInt(n, 10)) ?? [],
  );
  const [runTime, setRunTime] = useState(source?.schedule_time ?? "");
  const [runDays, setRunDays] = useState<number[]>(
    source
      ? source.schedule_days
          .split(",")
          .map((d) => parseInt(d, 10))
          .filter((val) => !isNaN(val))
      : [],
  );
  const [hotel, setHotel] = useState<IHotel[]>(source?.sHotel ?? []);
  const [excludeHotels, setExcludeHotels] = useState(false);

  const [accommodation, setAccommodation] = useState<AccommodationType[]>(
    accommodationForSource(source),
  );

  const loadingKeys = useAppSelector((state) => state.directory.loadingKeys);

  const operators = useAppSelector((state) => state.directory.operators ?? []);
  const departureCities = useAppSelector(
    (state) => state.directory.departureCities ?? [],
  );
  const destinationCountries = useAppSelector(
    (state) => state.directory.destinationCountries ?? [],
  );
  const destinationCities = useAppSelector(
    (s) => s.directory.destinationCities ?? [],
  );
  const hotels = useAppSelector((state) => state.directory.hotels ?? []);
  const tours = useAppSelector((state) => state.directory.tourFilters ?? []);
  const loading = useAppSelector((s) => s.reports.reportsCreating);

  const reportsCreated = useAppSelector(
    (s) => s.reports.updated,
    (lhs, rhs) => lhs.join(",") === rhs.join(","),
  );

  const canSave =
    name.length > 0 &&
    operator.length > 0 &&
    departureCity !== undefined &&
    destinationCountry !== undefined &&
    destinationCity !== undefined &&
    nights.length > 0 &&
    tourDates.length > 0;

  const operatorsLoading = loadingKeys.includes("operators");
  const departureCitiesDisabled = operatorsLoading || operator.length === 0;
  const destCountriesDisabled = departureCitiesDisabled || !departureCity;
  const destCityDisabled = destCountriesDisabled || !destinationCountry;
  const toursDisabled = destCityDisabled || !destinationCity;

  useEffect(() => {
    if (mode === "create") dispatch(fetchOperators());
  }, []);

  useEffect(() => {
    if (reportsCreated.length > 0) {
      clearForm();
      if (onClose) onClose();
    }
  }, [reportsCreated]);

  const clearTourDependencies = () => {
    setHotel([]);
  };

  const clearDestinationCityDependencies = () => {
    setTour([]);
    clearTourDependencies();
  };
  const clearDestinationCountryDependencies = () => {
    setDestinationCity(undefined);
    clearDestinationCityDependencies();
  };
  const clearDepartureCityDependencies = () => {
    setDestinationCountry(undefined);
    clearDestinationCountryDependencies();
  };
  const clearOperatorDependencies = () => {
    setDepartureCity(undefined);
    clearDepartureCityDependencies();
  };

  const clearForm = () => {
    setName("");
    setTemplateType(0);
    setOperator([]);
    clearOperatorDependencies();
    setTourDates([]);
    setNights([]);
    setExcludeHotels(false);
    setRunTime("");
    setRunDays([]);
  };
  const submitTemplate = () => {
    if (!canSave) return;
    const params: IReportParams = {
      accommodation:
        accommodation.length > 0
          ? accommodation.join(",")
          : AccommodationType.All,
      action:
        mode === "edit" ? ReportAction.editReport : ReportAction.createReport,
      city_from: departureCity,
      country: destinationCountry,
      dates: tourDates.join(","),
      hotels: hotel.map((h) => h.id).join(","),
      exclude_hotels: excludeHotels ? 1 : 0,
      name,
      nights: nights.join(","),
      operators: operator.map((o) => o.id).join(","),
      schedule_days: runDays.join(","),
      schedule_time: runTime,
      special_order: "",
      tour_types: -1,
      type: templateType,
      tour_id: tour.map((t) => t.id).join(","),
      city_id: destinationCity,
    };
    if (mode === "create") dispatch(createReportTemplate(params));
    if (mode === "clone") {
      if (source?.status == ReportStatus.template)
        dispatch(createReportTemplate(params));
      else dispatch(createReportFromTemplate(params));
    }
    if (mode === "edit" && source) {
      dispatch(editReportTemplate({ id: source.id, ...params }));
    }
  };
  const onCancelPress = () => {
    if (mode === "create") clearForm();
    else if (onClose) onClose();
  };

  const onOperatorSelect = (val: IOperator[]) => {
    setOperator(val);
    if (val.length > 0) {
      dispatch(
        fetchDepartureCity({
          operatorId: 10,
        }),
      );
    }
  };

  const onDepartureCitySelect = (id: number) => {
    if (departureCity !== id) {
      if (operator.length > 0)
        dispatch(
          fetchDestinationCountries({
            operator: 10,
            departureCity: id,
            multiOperator: false,
          }),
        );
    }
    setDepartureCity(id);
  };

  const onDestinationCountrySelect = (id: number) => {
    if (destinationCountry !== id) {
      if (operator.length > 0 && departureCity) {
        dispatch(
          fetchDestinationCities({
            departureCity: departureCity,
            operator: 10,
            destinationCountry: id,
          }),
        );
      }
    }
    setDestinationCountry(id);
  };

  const onDestinationCitySelect = (id: number) => {
    if (destinationCity !== id && destinationCountry) {
      if (operator.length > 0) {
        dispatch(
          fetchTourFilters({
            operator: 10,
            country: destinationCountry,
          }),
        );
      }
    }
    setDestinationCity(id);
  };

  const onTourSelect = (tours: ITourFilter[]) => {
    setTour(tours);
    if (tours.length > 0 && departureCity && destinationCountry) {
      dispatch(
        fetchHotels({
          operator: 10,
          departureCity,
          destinationCountry,
          tour_id: tours.map((itm) => itm.id).join(","),
        }),
      );
    }
  };

  return (
    <Container maxWidth="md" component={"div"}>
      <Grid container spacing={1} rowSpacing={2} alignItems={"flex-end"}>
        {mode === "create" ? (
          <Grid item xs={12}>
            <h1>{titleForMode(mode)}</h1>
          </Grid>
        ) : null}
        <Grid item xs={9}>
          <TextField
            label="Название шаблона"
            required
            variant={inputStyleVariant}
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value ?? "")}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectField
            items={templateTypeDataSource}
            required
            label={"Тип шаблона"}
            value={templateType}
            onSelect={(val) => setTemplateType(val as number)}
          />
        </Grid>
        <Grid item xs={8}>
          <Autocomplete
            multiple
            options={operators}
            loading={loadingKeys.includes("operators")}
            disabled={false}
            getOptionLabel={(option) => option.name}
            value={operator}
            isOptionEqualToValue={(lhs, rhs) => lhs.id === rhs.id}
            onChange={(_event, newValue) => {
              const uniqueMap = new Map<number, IOperator>();
              newValue.forEach((i) => uniqueMap.set(i.id, i));
              onOperatorSelect([...uniqueMap.values()]);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant={inputStyleVariant}
                label="Оператор"
                placeholder="Оператор"
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectField
            items={departureCities}
            required
            loading={loadingKeys.includes("departureCities")}
            label={"Город вылета"}
            value={departureCity}
            disabled={departureCitiesDisabled}
            onSelect={(val) => onDepartureCitySelect(val as number)}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectField
            items={destinationCountries}
            required
            disabled={destCountriesDisabled}
            label={"Страна назначения"}
            value={destinationCountry}
            loading={loadingKeys.includes("destinationCountries")}
            onSelect={(val) => onDestinationCountrySelect(val as number)}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectField
            items={destinationCities}
            required
            disabled={destCityDisabled}
            label={"Город назначения"}
            value={destinationCity}
            loading={loadingKeys.includes("destinationCities")}
            onSelect={(val) => onDestinationCitySelect(val as number)}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            multiple
            options={tours}
            loading={loadingKeys.includes("tourFilters")}
            disabled={toursDisabled}
            getOptionLabel={(option) => option.name}
            value={tour}
            isOptionEqualToValue={(lhs, rhs) => lhs.id === rhs.id}
            onChange={(_event, newValue) => {
              const uniqueMap = new Map<string, ITourFilter>();
              newValue.forEach((i) => uniqueMap.set(`${i.id}`, i));
              onTourSelect([...uniqueMap.values()]);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant={inputStyleVariant}
                label="Туры"
                placeholder="Туры"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <MultiDateSelect
            required
            value={tourDates}
            onChange={setTourDates}
            label={"Даты тура *"}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            multiple
            options={nightsDataSource}
            isOptionEqualToValue={(option, value) => option === value}
            value={nights.map((n) => `${n}`)}
            onChange={(e, v) =>
              setNights(v.map((n) => parseInt(n, 10)).sort((a, b) => a - b))
            }
            getOptionLabel={(option) => option}
            defaultValue={[]}
            renderInput={(params) => (
              <TextField
                {...params}
                variant={inputStyleVariant}
                label="Количество ночей"
                placeholder="Количество ночей"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={3} md={4}>
          <Autocomplete
            multiple
            options={accommodationList}
            disabled={false}
            getOptionLabel={(option) => titleForAccommodationType(option)}
            value={accommodation}
            isOptionEqualToValue={(lhs, rhs) => lhs === rhs}
            onChange={(_event, newValue) => {
              const allItem = newValue.includes(AccommodationType.All);
              if (allItem) setAccommodation([AccommodationType.All]);
              else {
                const unique = new Set<AccommodationType>();
                newValue.forEach((i) => unique.add(i));
                setAccommodation([...unique.values()]);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant={inputStyleVariant}
                label="Размещения"
                placeholder="Размещения"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            multiple
            options={hotels}
            disabled={false}
            getOptionLabel={(option) => option.name}
            value={hotel}
            isOptionEqualToValue={(lhs, rhs) => lhs === rhs}
            onChange={(_event, newValue) => {
              const allItem = newValue.find((i) => i.id === -1);
              if (allItem) setHotel([allItem]);
              else {
                const uniqueMap = new Map<string, IHotel>();
                newValue.forEach((i) => uniqueMap.set(`${i.id}`, i));
                setHotel([...uniqueMap.values()]);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant={inputStyleVariant}
                label="Отели"
                placeholder="Отели"
              />
            )}
          />
        </Grid>
        <Grid item md={2} xs={3} justifyContent="flex-end">
          <FormGroup row={true} sx={{ justifyContent: "flex-end" }}>
            <FormControlLabel
              control={
                <Checkbox
                  size={"small"}
                  checked={excludeHotels}
                  onChange={(e, val) => setExcludeHotels(val)}
                />
              }
              label="Исключить"
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12}>
          <h5>Параметры запуска</h5>
          <DayPicker
            selected={runDays}
            onChange={(val) => setRunDays(val)}
            time={runTime}
            onTimeChange={(val) => setRunTime(val)}
          />
        </Grid>
        {runTime.length === 0 && runDays.length === 0 ? (
          <Grid item xs={12}>
            <Alert severity="info">
              Создание отчета будет запущено сразу после сохранения шаблона!
            </Alert>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <SpacedContainerStyled>
            <Button variant="text" onClick={onCancelPress}>
              {mode === "create" ? "Очистить" : "Отмена"}
            </Button>
            <LoadingButton
              loading={loading}
              disabled={!canSave || loading}
              variant="contained"
              onClick={() => submitTemplate()}
            >
              {mode === "clone" ? "Клонировать" : "Сохранить"}
            </LoadingButton>
          </SpacedContainerStyled>
        </Grid>
      </Grid>
    </Container>
  );
};
