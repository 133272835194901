import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IDirectoryState } from "../directorySlice";
import { fetchDirectoryForJobInfo } from "../thunks/fetchDirectoryForJobInfo";
import { jobInfoLoadedKeys } from "./data/jobInfoLoadedKeys";

export const addFetchDirectoryForJobInfo = (builder: ActionReducerMapBuilder<IDirectoryState>) => {
  builder.addCase(fetchDirectoryForJobInfo.pending, (state) => {
    state.loadingKeys = [...state.loadingKeys, ...jobInfoLoadedKeys];
  });
  builder.addCase(fetchDirectoryForJobInfo.rejected, (state) => {
    state.loadingKeys = [...state.loadingKeys.filter((i) => !jobInfoLoadedKeys.includes(i))];
  });
  builder.addCase(fetchDirectoryForJobInfo.fulfilled, (state, action) => {
    if (action.payload.departureCities) {
      state.departureCities = action.payload.departureCities;
    }
    if (action.payload.destinationCities) {
      state.destinationCities = action.payload.destinationCities;
    }
    if (action.payload.resorts) {
      state.resorts = action.payload.resorts;
    }
    if (action.payload.destinationCountries) {
      state.destinationCountries = action.payload.destinationCountries;
    }
    if (action.payload.tourTypes) {
      state.tourTypes = action.payload.tourTypes;
    }
    if (action.payload.hotels) {
      state.hotels = action.payload.hotels;
    }
    state.loadingKeys = [...state.loadingKeys.filter((i) => !jobInfoLoadedKeys.includes(i))];
  });
};
