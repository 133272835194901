import { Container, Grid } from "@mui/material";
import React from "react";
import { ServiceEditor } from "./services/ServiceEditor";
import { ServiceFilters } from "./services/ServiceFilters";

export const ManageTransfers = () => {
  return (
    <Container maxWidth={"md"} component={"div"}>
      <Grid container spacing={1} rowSpacing={2} alignItems={"flex-end"}>
        <Grid item xs={12}>
          <h2>Дополнительные сервисы</h2>
        </Grid>
        <ServiceFilters />
        <ServiceEditor />
      </Grid>
    </Container>
  );
};
