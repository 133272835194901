import styled from "@emotion/styled";
import { Color } from "../../constants/colors";

export const FullLoaderStyled = styled.div`
  display: flex;
  width: 100%;
  min-height: 350px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  color: ${Color.text.onLight};
  font-weight: 500;
  font-size: 16px;
`;
