import { IAssociateState } from "./types/IAssociateState";

export const initialState: IAssociateState = {
  operators: [],
  country: null,
  loadingKeys: [],
  filterResort: {},
  filterTour: {},
  filterRegion: {},
  filterHotel: {},
  tezTargets: [],
  tezHotelCategories: [],
  associated: {},
  suggested: {},
  hotels: {},
  pansions: {},
  resorts: {},
  tourFilters: {},
  destinationCities: {},
  tourTypes: {},
  regions: {},
  rooms: {},
  errors: [],
};
