import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiAction } from "../../../api/ApiAction";
import { endpoints } from "../../../api/endpoints";
import { ITourType } from "../../../api/types/directory/ITourType";

interface Params {
  operator: number
  destinationCountry: number
}

export const fetchAssociateTourTypes = createAsyncThunk<ITourType[], Params>(
  "associate/fetchTourTypes",
  async (param, thunkAPI) => {
    const result = await endpoints.directory.get(
      {
        action: ApiAction.getTourTypes,
        operator: param.operator,
        country: param.destinationCountry,
      },
      thunkAPI.signal,
    );

    return result as ITourType[];
  },
);
