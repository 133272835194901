import React from "react";
import { MdInfoOutline } from "react-icons/md";
import { EmptyListStateStyled } from "./styled/EmptyListState.styled";

export const SelectListEmptyState = ({ isHidden }: { isHidden: boolean }) => {
  if (isHidden) return null;
  return (
    <EmptyListStateStyled>
      <MdInfoOutline />
      &nbsp; Нет данных
    </EmptyListStateStyled>
  );
};
