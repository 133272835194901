import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import { IEditReportParams } from "../../../api/types/reports/IReportParams";
import { IReportResponse } from "../../../api/types/reports/IReportResponse";

export const editReportTemplate = createAsyncThunk<
  IReportResponse,
  IEditReportParams
>("reports/edit", async (param, thunkAPI) => {
  const response = await endpoints.report.edit(param, thunkAPI.signal);
  if (response.error) throw new Error(response.error);
  if (response.status === "OK") return response;
  else
    throw new Error(
      `Invalid response from the server. ${JSON.stringify(response, null, 2)}`,
    );
});
