import { LoadingButton } from "@mui/lab";
import { Alert, Card, CardContent, Divider, Grid, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import { Navigate } from "react-router-dom";
import { CenterContainerStyled } from "../../components/styled/CenterContainerStyled";
import { RightAlignedContainerStyled } from "../../components/styled/SpacedContainerStyled";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { loginUser } from "../../store/user/thunks/loginUser";

export const Login = () => {
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector((s) => s.user.isLoading);
  const account = useAppSelector((s) => s.user.username);
  const error = useAppSelector((s) => s.user.error);

  const isLoggedIn = useAppSelector((s) => s.user.isLoggedIn);

  const [username, setUsername] = useState(account ?? "");
  const [password, setPassword] = useState("");

  const [usernameError, setUsernameError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);

  const onUsernameChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const sanitizedValue = (e.target.value ?? "").replace(/[^a-zA-Z0-9]+/g, "").trim();
    if (sanitizedValue.length > 3) setUsernameError(null);
    setUsername(sanitizedValue);
  };

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value.length > 3) setPasswordError(null);
    setPassword(e.target.value.trim() ?? "");
  };

  const canLogIn =
    username.length > 3 &&
    password.length > 5 &&
    isLoading === false &&
    passwordError === null &&
    usernameError === null;

  const onUsernameBlur = () => {
    if (username.length < 4) {
      setUsernameError("Должно быть не менее трех символов");
    } else if (usernameError !== null) {
      setUsernameError(null);
    }
  };

  const onPasswordBlur = () => {
    if (password.length < 4) {
      setPasswordError("Должно быть не менее трех символов");
    } else if (passwordError !== null) {
      setPasswordError(null);
    }
  };

  const logInUser = () => {
    setUsernameError(null);
    setPasswordError(null);

    dispatch(loginUser({ account: username, password }));
  };

  return (
    <CenterContainerStyled>
      {isLoggedIn ? <Navigate to="/" replace={true} /> : null}
      <Card sx={{ minWidth: 350, maxWidth: 400 }}>
        <CardContent>
          <form onSubmit={() => canLogIn && logInUser()}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" component="div">
                  Авторизация
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  name={"username"}
                  id={"username"}
                  autoComplete={"username"}
                  error={usernameError !== null}
                  disabled={isLoading}
                  fullWidth
                  label="Имя пользователя"
                  variant="filled"
                  value={username}
                  onChange={(event) => onUsernameChange(event)}
                  helperText={usernameError}
                  onBlur={() => onUsernameBlur()}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  name={"password"}
                  autoComplete={"current-password"}
                  id={"password"}
                  error={passwordError !== null}
                  disabled={isLoading}
                  fullWidth
                  label="Пароль"
                  type={"password"}
                  variant="filled"
                  value={password}
                  helperText={passwordError}
                  onChange={(e) => onPasswordChange(e)}
                  onBlur={() => onPasswordBlur()}
                />
              </Grid>
              {error ? (
                <Grid item xs={12}>
                  <Alert severity="error">{error}</Alert>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <RightAlignedContainerStyled>
                  <LoadingButton
                    disabled={!canLogIn}
                    loading={isLoading}
                    variant="contained"
                    type={"submit"}
                    onClick={() => logInUser()}
                  >
                    Войти
                  </LoadingButton>
                </RightAlignedContainerStyled>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </CenterContainerStyled>
  );
};
