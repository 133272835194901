import styled from "@emotion/styled";

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-end;
  flex-grow: 1;
  width: 100%;
`;
