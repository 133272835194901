import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IDirectoryState } from "../directorySlice";
import { fetchDepartureCity } from "../thunks/fetchDepartureCity";

export const addFetchDepartureCity = (builder: ActionReducerMapBuilder<IDirectoryState>) => {
  builder.addCase(fetchDepartureCity.pending, (state) => {
    state.loadingKeys = [...state.loadingKeys, "departureCities"];
  });
  builder.addCase(fetchDepartureCity.rejected, (state) => {
    state.loadingKeys = [...state.loadingKeys.filter((i) => i !== "departureCities")];
  });
  builder.addCase(fetchDepartureCity.fulfilled, (state, action) => {
    if (action.meta.arg.forFilter) {
      state.filterData.departureCities = [...action.payload];
    } else {
      state.departureCities = [...action.payload];
    }

    state.loadingKeys = [...state.loadingKeys.filter((i) => i !== "departureCities")];
  });
};
