import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IDirectoryState } from "../directorySlice";
import { fetchCurrency } from "../thunks/fetchCurrency";

export const addFetchCurrency = (builder: ActionReducerMapBuilder<IDirectoryState>) => {
  builder.addCase(fetchCurrency.pending, (state) => {
    state.loadingKeys = [...state.loadingKeys, "currency"];
  });
  builder.addCase(fetchCurrency.rejected, (state) => {
    state.loadingKeys = [...state.loadingKeys.filter((i) => i !== "currency")];
  });
  builder.addCase(fetchCurrency.fulfilled, (state, action) => {
    state.currency = [...action.payload];
    state.loadingKeys = [...state.loadingKeys.filter((i) => i !== "currency")];
  });
};
