import {
  Autocomplete,
  AutocompleteChangeReason,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { BiSelectMultiple } from "react-icons/bi";
import { inputStyleVariant } from "../../../constants/interface";
import { fetchOperators } from "../../../store/directory/thunks/fetchOperators";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  setServiceCity,
  setServiceDestinationCountry,
  setServiceHotel,
  setServiceOperator,
  setServiceRegion,
  setServiceTour,
} from "../../../store/services/servicesSlice";
import { fetchServiceDepartureCities } from "../../../store/services/thunks/fetchServiceDepartureCities";
import { fetchServiceDestinationCountries } from "../../../store/services/thunks/fetchServiceDestinationCountries";
import { fetchServiceRegions } from "../../../store/services/thunks/fetchServiceRegions";
import { fetchServicesHotels } from "../../../store/services/thunks/fetchServicesHotels";
import { fetchServiceTourFilters } from "../../../store/services/thunks/fetchServiceTourFilters";
import { fetchTransfersInfo } from "../../../store/services/thunks/fetchTransfersInfo";
import { fetchInsuranceInfo } from "../../../store/services/thunks/fetchInsuranceInfo";
import { fetchMarginInfo } from "../../../store/services/thunks/fetchMarginInfo";
import { IServiceParams } from "../../../store/services/types/IServiceParams";
import { IHotel } from "../../../api/types/directory/IHotel";
import { IOperator } from "../../../api/types/directory/IOperator";
import { IRegion } from "../../../api/types/directory/IRegion";

export const ServiceFilters = () => {
  const dispatch = useAppDispatch();

  const operators = useAppSelector((s) => s.directory.operators ?? []);
  const departureCity = useAppSelector((s) => s.services.selection.cityFrom);
  const region = useAppSelector((s) => s.services.selection.region);
  const country = useAppSelector((s) => s.services.selection.country);
  const operator = useAppSelector((s) => s.services.selection.operator);
  const selectedHotels = useAppSelector((s) => s.services.selection.hotels);
  const tour = useAppSelector((s) => s.services.selection.tour);

  const cities = useAppSelector((s) => s.services.cities);
  const countries = useAppSelector((s) => s.services.countries);
  const regions = useAppSelector((s) => s.services.regions);
  const hotels = useAppSelector((s) => s.services.hotels);
  const tourFilters = useAppSelector((s) => s.services.tourFilters);

  const loading = useAppSelector((s) => s.services.loadingKeys);

  const resetRegionDeps = () => {
    dispatch(setServiceHotel([]));
  };
  const resetTourDeps = () => {
    dispatch(setServiceRegion(null));
    resetRegionDeps();
  };
  const resetCountryDeps = () => {
    dispatch(setServiceTour(null));
    resetTourDeps();
  };

  const resetCityDeps = () => {
    dispatch(setServiceDestinationCountry(null));
    resetCountryDeps();
  };

  const resetOperatorDeps = () => {
    dispatch(setServiceCity(null));
    resetCityDeps();
  };

  useEffect(() => {
    const token = dispatch(fetchOperators());
    return () => token.abort();
  }, []);

  useEffect(() => {
    resetOperatorDeps();
    if (operator) {
      const token = dispatch(
        fetchServiceDepartureCities({ operator: operator.id }),
      );
      return () => token.abort();
    }
  }, [operator]);

  useEffect(() => {
    resetCityDeps();
    if (operator && departureCity) {
      const token = dispatch(
        fetchServiceDestinationCountries({
          operator: operator.id,
          departureCity: departureCity.id,
        }),
      );
      return () => token.abort();
    }
  }, [departureCity]);

  useEffect(() => {
    resetCountryDeps();

    if (operator && country) {
      const token = dispatch(
        fetchServiceTourFilters({
          operator: operator.id,
          departureCity: departureCity?.id,
          destinationCountry: country.id,
        }),
      );
      return () => token.abort();
    }
  }, [country]);

  useEffect(() => {
    resetTourDeps();
    if (operator && country && tour) {
      const token = dispatch(
        fetchServiceRegions({
          operator: operator.id,
          departureCity: departureCity?.id,
          destinationCountry: country.id,
          tour_id: tour.id,
        }),
      );
      return () => token.abort();
    }
  }, [tour]);

  useEffect(() => {
    resetRegionDeps();

    if (operator && country && region) {
      const token = dispatch(
        fetchServicesHotels({
          operator: operator.id,
          country: country.id,
          region_id: region.id,
        }),
      );

      return () => {
        token.abort();
      };
    }
  }, [region]);

  const handleHotelChange = (
    e: React.SyntheticEvent<Element, Event>,
    val: IHotel[],
    reason: AutocompleteChangeReason,
  ) => {
    dispatch(setServiceHotel(val));

    if (reason === "selectOption") {
      const params = {
        operator: (operator as IOperator).id,
        region_id: (region as IRegion).id,
        hotel_id: val[val.length - 1].id as number,
      };

      dispatch(fetchTransfersInfo(params));
      dispatch(fetchInsuranceInfo(params));
      dispatch(fetchMarginInfo(params));
    }
  };

  const handleAllClick = () => {
    if (selectedHotels.length < hotels.length) {
      dispatch(setServiceHotel(hotels));

      const params: IServiceParams = {
        operator: (operator as IOperator).id,
        region_id: (region as IRegion).id,
      };

      dispatch(fetchTransfersInfo(params));
      dispatch(fetchInsuranceInfo(params));
      dispatch(fetchMarginInfo(params));
    } else {
      dispatch(setServiceHotel([]));
    }
  };

  const cityEnabled = operator !== null;
  const countryEnabled = cityEnabled && departureCity !== null;
  const tourEnabled = countryEnabled && country !== null;
  const regionEnabled = tourEnabled && tour !== null;
  const hotelEnabled = regionEnabled && region !== null;

  return (
    <>
      <Grid item xs={4}>
        <Autocomplete
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              variant={inputStyleVariant}
              label="Оператор"
              placeholder="Оператор"
              required
            />
          )}
          fullWidth={true}
          isOptionEqualToValue={(lhs, rhs) => lhs.id === rhs.id}
          onChange={(e, val) => {
            dispatch(setServiceOperator(val));
          }}
          value={operator}
          options={operators}
        />
      </Grid>
      <Grid item xs={4}>
        <Autocomplete
          loading={loading.includes("cities")}
          disabled={!cityEnabled}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              variant={inputStyleVariant}
              label="Город отправления"
              placeholder="Город отправления"
              required
            />
          )}
          fullWidth={true}
          isOptionEqualToValue={(lhs, rhs) => lhs.id === rhs.id}
          onChange={(e, val) => {
            dispatch(setServiceCity(val));
          }}
          value={departureCity}
          options={cities}
        />
      </Grid>
      <Grid item xs={4}>
        <Autocomplete
          loading={loading.includes("countries")}
          disabled={!countryEnabled}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              variant={inputStyleVariant}
              label="Страна назначения"
              placeholder="Страна назначения"
              required
            />
          )}
          fullWidth={true}
          isOptionEqualToValue={(lhs, rhs) => lhs.id === rhs.id}
          onChange={(e, val) => {
            dispatch(setServiceDestinationCountry(val));
          }}
          value={country}
          options={countries}
        />
      </Grid>
      <Grid item xs={4}>
        <Autocomplete
          loading={loading.includes("tourFilters")}
          disabled={!tourEnabled}
          getOptionLabel={(option) => `${option.name} [${option.id}]`}
          renderInput={(params) => (
            <TextField
              {...params}
              variant={inputStyleVariant}
              label="Тур"
              placeholder="Тур"
              required
            />
          )}
          fullWidth={true}
          isOptionEqualToValue={(lhs, rhs) => lhs.id === rhs.id}
          onChange={(e, val) => {
            dispatch(setServiceTour(val));
          }}
          value={tour}
          options={tourFilters}
        />
      </Grid>
      <Grid item xs={4}>
        <Autocomplete
          loading={loading.includes("regions")}
          disabled={!regionEnabled}
          getOptionLabel={(option) => `${option.name} [${option.id}]`}
          renderInput={(params) => (
            <TextField
              {...params}
              variant={inputStyleVariant}
              label="Регион"
              placeholder="Регион"
              required
            />
          )}
          fullWidth={true}
          isOptionEqualToValue={(lhs, rhs) => lhs.id === rhs.id}
          onChange={(e, val) => {
            dispatch(setServiceRegion(val));
          }}
          value={region}
          options={regions}
        />
      </Grid>
      <Grid
        container
        item
        xs={4}
        direction="row"
        wrap="nowrap"
        alignItems="center"
      >
        <Autocomplete
          multiple
          loading={loading.includes("hotels")}
          disabled={!hotelEnabled}
          getOptionLabel={(option) => `${option.name} [${option.id}]`}
          renderInput={(params) => (
            <TextField
              {...params}
              variant={inputStyleVariant}
              label="Отель"
              placeholder="Отель"
            />
          )}
          fullWidth={true}
          isOptionEqualToValue={(lhs, rhs) => lhs.id === rhs.id}
          onChange={handleHotelChange}
          value={selectedHotels}
          options={hotels}
        />
        <IconButton onClick={handleAllClick} disabled={!hotelEnabled}>
          <BiSelectMultiple />
        </IconButton>
      </Grid>
    </>
  );
};
