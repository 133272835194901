import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IDirectoryState } from "../directorySlice";
import { fetchDestinationCities } from "../thunks/fetchDestinationCities";

export const addFetchDestinationCities = (builder: ActionReducerMapBuilder<IDirectoryState>) => {
  builder.addCase(fetchDestinationCities.pending, (state) => {
    state.loadingKeys = [...state.loadingKeys, "destinationCities"];
  });
  builder.addCase(fetchDestinationCities.rejected, (state) => {
    state.loadingKeys = [...state.loadingKeys.filter((i) => i !== "destinationCities")];
  });
  builder.addCase(fetchDestinationCities.fulfilled, (state, action) => {
    const includesAllItem = action.payload.find((i) => i.id == -1) !== undefined;
    state.destinationCities = includesAllItem
      ? [...action.payload]
      : [{ id: -1, name: "Все", tez_id: "0", local_id: "-1" }, ...action.payload];
    state.loadingKeys = [...state.loadingKeys.filter((i) => i !== "destinationCities")];
  });
};
