import { configureStore } from "@reduxjs/toolkit";

import directory from "./directory/directorySlice";
import jobs from "./jobs/jobsSlice";
import app from "./app/appSlice";
import modals from "./modals/modalsSlice";
import jobData from "./jobData/jobDataSlice";
import jobStats from "./jobStats/jobStatsSlice";
import user from "./user/userSlice";
import associate from "./associate/associateSlice";
import services from "./services/servicesSlice";
import reports from "./reports/reportsSlice";
import reportData from "./reportData/reportDataSlice";

export const store = configureStore({
  reducer: {
    directory,
    jobs,
    app,
    modals,
    jobData,
    jobStats,
    user,
    associate,
    services,
    reports,
    reportData,
  },
});
