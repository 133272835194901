import React from "react";
import { Navigate } from "react-router-dom";
import { CenterContainerStyled } from "../components/styled/CenterContainerStyled";
import { PagePath } from "../navigation/routes";
import { useAppSelector } from "../store/hooks";
import { ClipLoader } from "react-spinners";

export const Initial = () => {
  const isLoggedIn = useAppSelector((s) => s.user.isLoggedIn);
  return (
    <CenterContainerStyled>
      <ClipLoader />
      <Navigate to={isLoggedIn ? PagePath.parsing.jobs : PagePath.login} replace={true} />
    </CenterContainerStyled>
  );
};
