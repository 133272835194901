import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import { IGetJobFilter, IGetJobsParams } from "../../../api/types/jobs/IGetJobsParams";
import { IJobInfo } from "../../../api/types/jobs/IJobInfo";
import { JobAction } from "../../../api/types/jobs/JobAction";
import { JobStatus } from "../../../api/types/jobs/JobStatus";
import { allStatusList } from "../../../components/filter/AllStatusList";
import { TemplateListMode } from "../../../pages/types/TemplateListMode";
import { AppDispatch } from "../../appDispatch";
import { RootState } from "../../rootState";

const statusesForTemplateList = (
  mode: TemplateListMode,
  filterStatus: number,
): JobStatus | JobStatus[] => {
  if (mode === "templates") return JobStatus.template;
  if (mode === "imported") return JobStatus.imported;
  return filterStatus === -1 ? allStatusList : filterStatus;
};

export const getJobList = createAsyncThunk<
  IJobInfo[],
  { filter?: IGetJobFilter; mode: TemplateListMode } | undefined,
  {
    state: RootState;
  }
>("jobs/getList", async (param, thunkAPI) => {
  const state = thunkAPI.getState();
  if (state.user.isLoggedIn === false) throw new Error("Время сессии авторизации истекло");
  if (state.user.info === null) return [];
  const currentMode = param?.mode ?? state.jobs.mode;
  const currentFilter = param?.filter ?? {
    type: state.jobs.filters.type,
    operator: state.jobs.filters.operator,
    status: statusesForTemplateList(currentMode, state.jobs.filters.status),
    country: state.jobs.filters.country,
    city_from: state.jobs.filters.city,
  };
  const params: IGetJobsParams = {
    action: JobAction.getJobs,
    ...currentFilter,
  };
  const response = await endpoints.job.list(params, thunkAPI.signal);
  if (response.error) throw response.error;
  if (response.jobs) return response.jobs;
  else throw new Error("Invalid response from the server.");
});

export const refreshJobList = createAsyncThunk<
  void,
  void,
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>("jobs/refresh", async (param, thunkAPI) => {
  const state = thunkAPI.getState();
  const currentMode = state.jobs.mode;
  if (currentMode !== "jobs") return;
  if (state.modals.modals.length > 0) return;
  console.log("refreshing job list");
  thunkAPI.dispatch(getJobList());
});
