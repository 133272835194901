import { IconButton, TableRow } from "@mui/material";
import React from "react";
import { MdLeaderboard } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import { endpoints } from "../../../api/endpoints";
import { IJobInfo } from "../../../api/types/jobs/IJobInfo";
import { JobAction } from "../../../api/types/jobs/JobAction";
import { JobStatus } from "../../../api/types/jobs/JobStatus";
import { templateTypeDataSource } from "../../../constants/TemplateTypeDataSource";
import { TemplateListMode } from "../../../pages/types/TemplateListMode";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { toggleJobStats } from "../../../store/jobStats/thunks/toggleJobStats";
import { titleForStatus } from "../../filter/utils/titleForStatus";
import { FlexRowStyled } from "../../styled/FlexRow.styled";
import { StyledTableCell } from "../../styled/TableCell.styled";
import { lastColMaxWidth } from "./constants/lastColMaxWidth";
import { JobInfoRow } from "./JobInfoRow";
import { RowControlButtons } from "./RowControlButtons";
import { VerticalContainerStyled } from "./styled/VerticalContainer.styled";
import { dateToComponents } from "./utils/DateToComponents";
import { truncateString } from "./utils/TruncateString";

interface Props {
  job: IJobInfo;
  mode: TemplateListMode;
  colSpan: number;
  isJobs: boolean;
}

export const TemplateRow = ({ job, mode, colSpan, isJobs }: Props) => {
  const dispatch = useAppDispatch();
  const disabled = useAppSelector((s) =>
    s.jobs.loadingJobKeys.includes(job.id),
  );
  const operator = useAppSelector(
    (s) =>
      s.directory.operators?.find((itm) => itm.id == job.operator_id)?.name ??
      job.operator_id,
  );

  const st = parseInt(job.status, 10);
  const isStopped =
    st === JobStatus.stopped ||
    st === JobStatus.finished ||
    st === JobStatus.template ||
    st == JobStatus.imported;

  const onToggleJobStats = (info: IJobInfo) => {
    dispatch(toggleJobStats(info.id));
  };

  return (
    <>
      <TableRow
        hover
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <StyledTableCell sx={{ minWidth: "150px" }}>
          {job.name} <br />
          <small>
            <i>
              {templateTypeDataSource.find((itm) => itm.id == job.type)?.name ??
                "Авиа"}
            </i>
          </small>
          <br />
          <sub>[id:{job.id}]</sub>
        </StyledTableCell>
        <StyledTableCell>{operator}</StyledTableCell>
        <StyledTableCell>{job.city_from_string}</StyledTableCell>
        <StyledTableCell>{job.country_string}</StyledTableCell>
        <StyledTableCell>
          <small>
            <strong>Ночей:</strong>{" "}
            {truncateString(job.nights.split(",").join(", "), 50)}; <br />
            <strong>Даты:</strong>{" "}
            {truncateString(job.dates.split(",").join(", "), 50)}
          </small>
        </StyledTableCell>

        {isJobs && mode !== "imported" ? (
          <StyledTableCell sx={{ width: lastColMaxWidth }}>
            <small>
              {job.last_date.length > 0 ? job.last_date : "Нет данных"}
            </small>
          </StyledTableCell>
        ) : null}

        {isJobs && mode !== "imported" ? (
          <StyledTableCell
            sx={{ width: lastColMaxWidth, whiteSpace: "nowrap" }}
          >
            <small>
              {dateToComponents(job.start_parse)[0]}
              <br />
              {dateToComponents(job.start_parse)[1]}
            </small>
          </StyledTableCell>
        ) : null}

        {isJobs && mode !== "imported" ? (
          <StyledTableCell
            sx={{ width: lastColMaxWidth, whiteSpace: "nowrap" }}
          >
            <small>
              {dateToComponents(job.finish_parse)[0]}
              <br />
              {dateToComponents(job.finish_parse)[1]}
            </small>
          </StyledTableCell>
        ) : null}

        {isJobs ? (
          <StyledTableCell sx={{ width: "130px" }}>
            <FlexRowStyled>
              <small>
                {mode === "imported" ? 100 : job.parse_progress}% <br />{" "}
                {job.parsed_rows} стр. <br />
                {job.parse_left} мин
                {mode === "imported" ? (
                  <small>
                    <br />
                    {dateToComponents(job.last_update)[0]}
                    <br />
                    {dateToComponents(job.last_update)[1]}
                  </small>
                ) : null}
              </small>
              <VerticalContainerStyled>
                <IconButton
                  href={endpoints.job.getCSVLink({
                    action: JobAction.getToursXLSX,
                    job: job.id,
                    operator: job.operator_id,
                  })}
                  size={"small"}
                  color={"primary"}
                  disabled={!isStopped || job.parsed_rows == 0 || disabled}
                  title={"Скачать отчет"}
                >
                  <RiFileExcel2Fill />
                </IconButton>
                <IconButton
                  size={"small"}
                  color={"primary"}
                  onClick={() => onToggleJobStats(job)}
                  disabled={!isStopped || job.parsed_rows == 0 || disabled}
                  title={"Статистика"}
                >
                  <MdLeaderboard />
                </IconButton>
              </VerticalContainerStyled>
            </FlexRowStyled>
          </StyledTableCell>
        ) : null}

        {isJobs && mode !== "imported" ? (
          <StyledTableCell sx={{ width: lastColMaxWidth }}>
            <small>{titleForStatus(parseInt(job.status, 10))}</small>
          </StyledTableCell>
        ) : null}

        <StyledTableCell align={"right"}>
          <RowControlButtons
            job={job}
            disabled={disabled}
            mode={mode}
            isStopped={isStopped}
          />
        </StyledTableCell>
      </TableRow>
      <JobInfoRow job={job} mode={mode} colSpan={colSpan} />
    </>
  );
};
