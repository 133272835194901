import { JobStatus } from "../../../api/types/jobs/JobStatus";
import { ISelectItem, SelectValue } from "../../select/SelectField";
import { titleForStatus } from "./titleForStatus";

export const statusSelectItems: ISelectItem[] = [
  -1,
  ...Object.values(JobStatus),
]
  .filter(
    (status) =>
      status !== JobStatus.template &&
      status !== JobStatus.deleted &&
      typeof status === "number",
  )
  .map((status) => ({
    name: titleForStatus(status as JobStatus),
    id: status as SelectValue,
  }));
