import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TemplateListMode } from "../../pages/types/TemplateListMode";
import { addCreateJobFromTemplate } from "./extraReducers/addCreateJobFromTemplate";
import { addCreateJobTemplate } from "./extraReducers/addCreateJobTemplate";
import { addDeleteJob } from "./extraReducers/addDeleteJob";
import { addEditJob } from "./extraReducers/addEditJob";
import { addGetJobList } from "./extraReducers/addGetJobList";
import { addRestartJob } from "./extraReducers/addRestartJob";
import { addResumeJob } from "./extraReducers/addResumeJob";
import { addStopJob } from "./extraReducers/addStopJob";
import { IJobFilters } from "./types/IJobFilters";
import { IJobsState } from "./types/IJobsState";

const initialState: IJobsState = {
  loadingKeys: [],
  loadingJobKeys: [],
  activeJobs: [],
  allJobs: [],
  filters: {
    status: -1,
  },
  jobsUpdated: [],
  jobCreating: false,
  loadingJobs: false,
  mode: "jobs",
};

const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    resetFilters: (state) => {
      state.filters = {
        status: -1,
      };
    },
    resetJobsData: () => initialState,
    removeUpdatedJob: (state, action: PayloadAction<number>) => {
      state.jobsUpdated = state.jobsUpdated.filter((j) => j.id != action.payload);
    },
    clearJobError: (state, action: PayloadAction<number>) => {
      if (state.jobErrors) {
        state.jobErrors = state.jobErrors.filter((itm) => itm.id !== action.payload);
      }
    },
    setJobFilters: (state, action: PayloadAction<IJobFilters>) => {
      state.filters.city = action.payload.city;
      state.filters.country = action.payload.country;
      state.filters.status = action.payload.status;
      state.filters.operator = action.payload.operator;
      state.filters.type = action.payload.type;
    },
    setJobListMode: (state, action: PayloadAction<TemplateListMode>) => {
      state.mode = action.payload;
    },
    clearOperatorFilterDependencies: (state) => {
      state.filters.city = undefined;
      state.filters.country = undefined;
    },
    clearCityFilterDependencies: (state) => {
      state.filters.country = undefined;
    },
  },
  extraReducers: (builder) => {
    addCreateJobTemplate(builder);
    addCreateJobFromTemplate(builder);
    addGetJobList(builder);
    addDeleteJob(builder);
    addEditJob(builder);
    addResumeJob(builder);
    addRestartJob(builder);
    addStopJob(builder);
  },
});

export const {
  setJobListMode,
  setJobFilters,
  resetFilters,
  clearOperatorFilterDependencies,
  clearCityFilterDependencies,
  resetJobsData,
  removeUpdatedJob,
  clearJobError,
} = jobsSlice.actions;
export default jobsSlice.reducer;
