import { Autocomplete, Grid, TextField } from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect } from "react";

import { IOperator } from "../../../../api/types/directory/IOperator";
import { inputStyleVariant } from "../../../../constants/interface";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { setGrossOperators } from "../../../../store/services/servicesSlice";
import { fetchOperators } from "../../../../store/directory/thunks/fetchOperators";
import { fetchGross } from "../../../../store/services/thunks/fetchGross";
import GrossContent from "./GrossContent";

export const GrossEditor = () => {
  const dispatch = useAppDispatch();

  const operators = useAppSelector(
    (s) => s.directory.operators ?? ([] as IOperator[]),
  );
  const { data: serverGrossData, selectedOperators } = useAppSelector(
    (s) => s.services.gross,
  );

  useEffect(() => {
    const token = dispatch(fetchOperators());

    return () => {
      return token.abort();
    };
  }, []);

  useEffect(() => {
    if (selectedOperators.length) {
      selectedOperators.forEach((item) => {
        !serverGrossData[item.id] && dispatch(fetchGross(item.id));
      });
    }
  }, [selectedOperators]);

  return (
    <Container maxWidth="xl" component={"div"}>
      <Grid container spacing={1} rowSpacing={2} alignItems={"flex-end"}>
        <Grid item xs={12}>
          <h2>Бруттовка</h2>
        </Grid>

        <Autocomplete
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              variant={inputStyleVariant}
              label="Оператор"
              placeholder="Оператор"
              required
            />
          )}
          multiple
          fullWidth={true}
          isOptionEqualToValue={(lhs, rhs) => lhs.id === rhs.id}
          onChange={(e, val) => {
            dispatch(setGrossOperators(val));
          }}
          value={selectedOperators}
          options={operators}
        />

        <GrossContent />
      </Grid>
    </Container>
  );
};
