import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import React, { useState } from "react";
import { ColumnBodyStyled } from "../../../components/columns/ColumnBodyStyled";
import { SpacedContainerStyled } from "../../../components/styled/SpacedContainerStyled";
import { postRoomChanges } from "../../../store/associate/thunks/postRoomChanges";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

export const RoomEditor = () => {
  const dispatch = useAppDispatch();
  const [note, setNote] = useState<string>("");
  const items = useAppSelector((s) => s.associate.tezTargets);
  const isSaving = useAppSelector((s) => s.associate.loadingKeys.includes(`room`));

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const sourceNotes = items.map((itm) => itm?.note?.trim() ?? "").filter((note) => note.length > 0);

  const saveEnabled = sourceNotes.join("") !== note.trim();

  const onSavePress = () => {
    dispatch(postRoomChanges({ localIds: items.map((itm) => itm?.local_id ?? itm.id), note }));
  };

  return (
    <ColumnBodyStyled minHeight={24} marginBottom={8}>
      <h3>
        {sourceNotes.length === 0
          ? "Добавить примечание"
          : items.length > 1
          ? "Изменить примечание для:"
          : "Изменить примечание"}
      </h3>

      {sourceNotes.length > 0 && items.length > 1 ? (
        <small>
          {items.map((itm, i) => (
            <p key={`note${i}`}>
              <strong>{itm.name}: </strong>
              {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                itm.note
              }
            </p>
          ))}
        </small>
      ) : null}
      <TextField
        label={"Новое примечание"}
        variant={"filled"}
        size={"small"}
        fullWidth
        value={note.length === 0 ? sourceNotes[0] ?? note : note}
        onChange={(e) => setNote(e.target.value ?? "")}
      />
      <SpacedContainerStyled>
        <LoadingButton
          fullWidth={true}
          loading={isSaving}
          disabled={!saveEnabled || isSaving}
          variant={"contained"}
          onClick={onSavePress}
        >
          Сохранить для выбранных
        </LoadingButton>
      </SpacedContainerStyled>
    </ColumnBodyStyled>
  );
};
