import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import { IJobActionParams } from "../../../api/types/jobs/IJobActionParams";
import { IJobResponse } from "../../../api/types/jobs/IJobResponse";

export const deleteJob = createAsyncThunk<IJobResponse, IJobActionParams>(
  "jobs/deleteJob",
  async (params, thunkAPI) => {
    const response = await endpoints.job.remove(params, thunkAPI.signal);
    if (response.error) throw response.error;
    if (response.status === "OK") return response;
    else throw new Error("Invalid response from the server.");
  },
);
