import { NightType } from "../types/NightType";

export const hasCurrentDate = (
  data: Record<string, NightType[]>,
  nights: NightType,
  date: string,
): boolean => {
  const row = data[date];
  if (!row) return false;
  return row.includes(nights);
};
