import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../rootState";
import { endpoints } from "../../../api/endpoints";
import { ApiAction } from "../../../api/ApiAction";
import { IOperator } from "../../../api/types/directory/IOperator";

export const fetchOperators = createAsyncThunk<IOperator[], void, { state: RootState }>(
  "directory/getOperators",
  async (_param, thunkAPI) => {
    const result = await endpoints.directory.get(
      {
        action: ApiAction.getOperators,
      },
      thunkAPI.signal,
    );

    return result as IOperator[];
  },
);
