import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchMarginInfo } from "../thunks/fetchMarginInfo";
import { IServicesState } from "../types/IServicesState";

export const addFetchMarginInfo = (
  builder: ActionReducerMapBuilder<IServicesState>,
) => {
  builder.addCase(fetchMarginInfo.pending, (state) => {
    state.loadingKeys = [...state.loadingKeys, `margin`];
  });
  builder.addCase(fetchMarginInfo.rejected, (state) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `margin`);
  });
  builder.addCase(fetchMarginInfo.fulfilled, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `margin`);
    action.payload.forEach((item) => {
      state.margin[item.hotels] = item;
    });
  });
};
