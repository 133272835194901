import React from "react";
import { useNavigate } from "react-router-dom";
import { TemplateEditor } from "../../components/template/TemplateEditor";

export const CreateTemplate = () => {
  const nav = useNavigate();

  const onClose = () => {
    nav(-1);
  };

  return <TemplateEditor mode={"create"} onClose={onClose} />;
};
