import { IJobStats } from "../../../api/types/jobs/IJobStats";

export const jobStatsToGridSource = (stats?: IJobStats[]): Record<string, number[]> | undefined => {
  if (!stats) return undefined;
  const result: Record<string, number[]> = {};
  for (const stat of stats) {
    if (typeof stat.nights !== "number") {
      result[stat.date] = stat.nights.split(",").map((n) => parseInt(n, 10));
    } else {
      result[stat.date] = [stat.nights];
    }
  }
  return result;
};
