import { createAsyncThunk } from "@reduxjs/toolkit";
import { IJobInfo } from "../../../api/types/jobs/IJobInfo";
import { JobStatus } from "../../../api/types/jobs/JobStatus";
import { AppDispatch } from "../../appDispatch";
import { createJobFromTemplateID } from "./createJobFromTemplateID";
import { resumeJob } from "./resumeJob";
import { stopJob } from "./stopJob";

export const toggleJobStatus = createAsyncThunk<void, IJobInfo, { dispatch: AppDispatch }>(
  "jobs/startByID",
  async (param, thunkAPI) => {
    const status = parseInt(param.status, 10);

    if (status === JobStatus.template) {
      thunkAPI.dispatch(createJobFromTemplateID(param));
    } else if (status === JobStatus.finished) {
      // TODO: - check if this can be used here
      // thunkAPI.dispatch(restartJob(param.id))
      thunkAPI.dispatch(createJobFromTemplateID(param));
    } else if (status === JobStatus.stopped) {
      thunkAPI.dispatch(resumeJob(param.id));
    } else if (status === JobStatus.active) {
      thunkAPI.dispatch(stopJob(param.id));
    } else if (status === JobStatus.pending) {
      thunkAPI.dispatch(stopJob(param.id));
    }
  },
);
