import { ApiAction } from "../ApiAction";
import { DirectoryItem, IDirectoryResponseJSON } from "../types/directory/IResponse";
import { apiActionToResultKey } from "./ApiActionToResultKey";

export function getDataForAction(type: ApiAction, data: IDirectoryResponseJSON): DirectoryItem[] {
  if (data.error) throw new Error(data.error);
  const resultKey = apiActionToResultKey(type);
  const result = data[resultKey];
  if (!result) throw new Error(`Missing response data for ${type}`);
  return result;
}
