import styled from "@emotion/styled";
import { Color } from "../../constants/colors";
import { globalRadius, hPaddingValue, vPaddingValue } from "../../constants/sizes";

export const BodyContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  background-color: ${Color.background};
  border-radius: ${globalRadius}px;
  margin: ${hPaddingValue}px ${vPaddingValue}px;

  margin-top: ${90 + vPaddingValue}px;
`;
