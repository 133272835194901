export enum DirectoryCreateAction {
  /**
   * @deprecated use createRegion instead
   */
  createTourFilter = "createTourFilter",
  createRegion = "createRegion",
  createTour = "createTour",
  createTransfer = "createTransfer",
  createInsurance = "createInsurance",
  createMargin = "createMargin",
}
