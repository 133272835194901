import styled from "@emotion/styled";
import { Color } from "../../constants/colors";
import { baseSpacingValue, vPaddingValue } from "../../constants/sizes";

export const SpacedContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  align-content: flex-end;
  column-gap: ${baseSpacingValue}px;
  row-gap: ${baseSpacingValue}px;
  padding-top: ${vPaddingValue / 2}px;
  padding-bottom: ${vPaddingValue / 2}px;
`;

export const SpacedFixedContainerStyled = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  align-content: flex-end;
  column-gap: ${baseSpacingValue}px;
  row-gap: ${baseSpacingValue}px;
  padding-top: ${vPaddingValue / 2}px;
  padding-bottom: ${vPaddingValue / 2}px;
  padding-left: ${vPaddingValue}px;
  padding-right: ${vPaddingValue}px;
  background-color: ${Color.secondaryBackground};
`;

export const RightAlignedContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
`;
