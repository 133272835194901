export const Color = {
  background: "#fbfdf9",
  secondaryBackground: "#dee5df",
  brand: "#2c6862",
  text: { onLight: "#1a1c19", onDark: "#fff" },
  secondaryText: "#42493e",
  border: "#727974",
  inputBackground: "#fff",
  danger: "#ff3b2f",
  warning: "#ff9d0c",
};
