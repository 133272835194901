export enum ApiAction {
  getOperators = "getOperators",
  getDepartureCities = "getDepartureCities",
  getDestinationCountries = "getDestinationCountries",
  getDestinationCities = "getDestinationCities",
  getResorts = "getResorts",
  getTourTypes = "getTourTypes",
  getHotelCategories = "getHotelCategories",
  getPansions = "getPansions",
  getHotels = "getHotels",
  getCurrency = "getCurrency",
  getCountries = "getCountries",
  getRooms = "getRooms",
  editHotel = "editHotel",
  getTourFilters = "getTourFilters",
  getRegions = "getRegions",
  getCosts = "getCosts",
  getToursByTez = "getToursByTez",
  getTransfer = "getTransfer",
  getInsurance = "getInsurance",
  getMargin = "getMargin",
  getNotes = "getNotes",
  getGross = "getGross",
}

export enum CreateApiAction {
  createGross = "createGross",
}

export enum EditApiAction {
  editGross = "editGross",
  editRoom = "editRoom",
  editNotes = "editNotes",
  editHotel = "editHotel",
  editRegion = "editRegion",
  editTour = "editTour",
}

export enum DeleteApiAction {
  deleteGross = "deleteGross",
}
