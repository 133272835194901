import styled from "@emotion/styled";

export const ControlButtonContainerStyled = styled.div`
  width: 100%;
  height: 100%;
  min-width: 136px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  column-gap: 4px;
  row-gap: 4px;
  flex-wrap: wrap;
`;
