import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiAction } from "../../../api/ApiAction";
import { endpoints } from "../../../api/endpoints";
import { IDepartureCity } from "../../../api/types/directory/IDepartureCity";
import { RootState } from "../../rootState";

interface Params {
  operator?: number;
}

export const fetchServiceDepartureCities = createAsyncThunk<
  IDepartureCity[],
  Params,
  { state: RootState }
>("services/getDepartureCities", async (param, thunkAPI) => {
  const result = await endpoints.directory.get(
    {
      action: ApiAction.getDepartureCities,
      operator: param.operator,
    },
    thunkAPI.signal,
  );

  return result as IDepartureCity[];
});
