import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiAction } from "../../../api/ApiAction";
import { endpoints } from "../../../api/endpoints";
import { IResort } from "../../../api/types/directory/IResort";
import { RootState } from "../../rootState";

interface Params {
  operator: number
  departureCity: number
  destinationCountry: number
}

export const fetchDestinationResorts = createAsyncThunk<IResort[], Params, { state: RootState }>(
  "directory/getDestinationResorts",
  async (param, thunkAPI) => {
    const result = await endpoints.directory.get(
      {
        action: ApiAction.getResorts,
        operator: param.operator,
        departure: param.departureCity,
        country: param.destinationCountry,
      },
      thunkAPI.signal,
    );

    return result as IResort[];
  },
);
