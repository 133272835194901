import { IJobInfo } from "../types/jobs/IJobInfo";
import { IJobParams } from "../types/jobs/IJobParams";

export const mapJobParamsToJobInfo = (info: IJobInfo, params: IJobParams): IJobInfo => {
  const updated: IJobInfo = { ...info };
  updated.name = params.name;
  updated.type = params.type;
  updated.operator_id = params.operator;
  updated.city_from = params.city_from;
  updated.country = params.country;
  updated.city_to = params.city_to;
  updated.tour_types = params.tour_types;
  updated.dates = params.dates;
  updated.nights = params.nights;
  updated.resort = params.resort;
  updated.DBL = params.DBL;
  updated.CHD = params.CHD;
  updated.schedule_time = params.schedule_time;
  updated.special_order = params.special_order;
  updated.hotels = params.hotels;

  return updated;
};
