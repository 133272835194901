import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import { IEditUserParams } from "../../../api/types/user/ICreateUserParams";
import { IUserResponse } from "../../../api/types/user/IUserResponse";

export const editUser = createAsyncThunk<IUserResponse, IEditUserParams>(
  "user/edit",
  async (param, thunkAPI) => {
    return await endpoints.user.edit(param, thunkAPI.signal);
  },
);
