import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IDirectoryState } from "../directorySlice";
import { fetchHotels } from "../thunks/fetchHotels";

export const addFetchHotels = (builder: ActionReducerMapBuilder<IDirectoryState>) => {
  builder.addCase(fetchHotels.pending, (state) => {
    state.loadingKeys = [...state.loadingKeys, "hotels"];
  });
  builder.addCase(fetchHotels.rejected, (state) => {
    state.loadingKeys = [...state.loadingKeys.filter((i) => i !== "hotels")];
  });
  builder.addCase(fetchHotels.fulfilled, (state, action) => {
    state.hotels = [...action.payload];
    state.loadingKeys = [...state.loadingKeys.filter((i) => i !== "hotels")];
  });
};
