import {
  Button,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import { MdOutlineGroupAdd } from "react-icons/md";
import { Navigate } from "react-router-dom";
import { StyledTableCell } from "../../components/styled/TableCell.styled";
import { UserListHeaderContainer } from "../../components/users/UserListHeaderContainer";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { showModal } from "../../store/modals/modalsSlice";
import { fetchUsers } from "../../store/user/thunks/fetchUsers";
import { UserRow } from "./UserRow";

export const Users = () => {
  const dispatch = useAppDispatch();

  const users = useAppSelector((s) => s.user.users ?? []);
  const loadingKeys = useAppSelector((s) => s.user.loadingKeys ?? []);
  const isLoading = useAppSelector((s) => s.user.isLoading);

  const isAdmin = useAppSelector((s) => s.user?.info?.type === "admin");

  const usersUpdated = useAppSelector(
    (s) => s.user.usersUpdated,
    (lhs, rhs) => lhs.join(",") === rhs.join(","),
  );

  useEffect(() => {
    dispatch(fetchUsers());
  }, [usersUpdated]);

  const onAddUser = () => {
    dispatch(
      showModal({
        type: "addUser",
        id: -1,
        kind: "user",
      }),
    );
  };

  const isEmptyResult = !isLoading && users.length === 0;
  const showSpinner = isLoading || isEmptyResult;

  return (
    <Grid container spacing={1} rowSpacing={2} alignItems={"flex-end"}>
      {!isAdmin ? <Navigate to="/" replace={true} /> : null}
      <Grid item xs={12}>
        <UserListHeaderContainer>
          <h1>Пользователи</h1>
          <Button onClick={onAddUser} startIcon={<MdOutlineGroupAdd />}>
            Новый пользователь
          </Button>
        </UserListHeaderContainer>
      </Grid>

      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="Пользователи">
            <TableHead>
              <TableRow>
                <StyledTableCell>ID # - Логин</StyledTableCell>
                <StyledTableCell>Имя</StyledTableCell>
                <StyledTableCell>Телефон</StyledTableCell>
                <StyledTableCell>E-mail</StyledTableCell>
                <StyledTableCell>Роль</StyledTableCell>
                <StyledTableCell>Создан</StyledTableCell>
                <StyledTableCell>Статус</StyledTableCell>
                <StyledTableCell align={"right"}>Управление</StyledTableCell>
              </TableRow>
              {showSpinner ? (
                <TableRow>
                  <StyledTableCell colSpan={8}>
                    {isEmptyResult ? "Нет пользователей" : <LinearProgress />}
                  </StyledTableCell>
                </TableRow>
              ) : null}
            </TableHead>
            <TableBody>
              {users.map((user, index) => (
                <UserRow
                  key={`user${index}_${user.id}`}
                  disabled={loadingKeys.includes(user.id) || isLoading}
                  user={user}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
