import { ReportStatus } from "../../../store/reports/types/reportStatus";
import { ISelectItem, SelectValue } from "../../select/SelectField";
import { titleForReportStatus } from "./titleForReportStatus";

export const reportStatusSelectItems: ISelectItem[] = [
  -1,
  ...Object.values(ReportStatus),
]
  .filter(
    (status) =>
      status !== ReportStatus.template &&
      status !== ReportStatus.deleted &&
      typeof status === "number",
  )
  .map((status) => ({
    name: titleForReportStatus(status as ReportStatus),
    id: status as SelectValue,
  }));
