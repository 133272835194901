import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiAction } from "../../../api/ApiAction";
import { endpoints } from "../../../api/endpoints";
import { ITourFilter } from "../../../api/types/directory/ITourFilter";
import { RootState } from "../../rootState";

interface Params {
  operator: number;
  departureCity?: number | string;
  destinationCountry: number | string;
}

export const fetchServiceTourFilters = createAsyncThunk<
  ITourFilter[],
  Params,
  { state: RootState }
>("services/getTourFilters", async (param, thunkAPI) => {
  const result = await endpoints.directory.get(
    {
      action: ApiAction.getTourFilters,
      operator: param.operator,
      departure: param.departureCity,
      country: param.destinationCountry,
    },
    thunkAPI.signal,
  );

  return result as ITourFilter[];
});
