import styled from "@emotion/styled";
import { Color } from "../../../constants/colors";
import { adjustColor } from "../../../utils/adjustColor";

interface Props {
  selectable?: boolean
  selected?: boolean
  noPadding?: boolean
}

export const GridTextCellStyled = styled.div<Props>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  padding: ${(props) => (props.noPadding ? "2px" : "16px 16px")};
  font-size: 12px;
  font-weight: 500;

  color: ${(props) => (props.selected ? Color.text.onDark : Color.text.onLight)};
  background-color: ${(props) => (props.selected ? Color.brand : Color.secondaryBackground)};
  text-transform: capitalize;
  box-sizing: border-box;

  transition: all 0.1s ease-in-out;
  white-space: nowrap;

  &:hover,
  &:focus,
  &:active {
    cursor: ${(props) => (props.selectable ? "pointer" : "auto")};
    background-color: ${(props) =>
      props.selectable
        ? adjustColor(props.selected ? Color.brand : Color.secondaryBackground, -10)
        : Color.secondaryBackground};
  }

  &:first-of-type {
    flex-grow: 0;
    background-color: ${Color.secondaryBackground};
  }
`;
