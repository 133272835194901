import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import { RootState } from "../../rootState";
import { ApiAction } from "../../../api/ApiAction";
import { IGross } from "../types/gross";

export const fetchGross = createAsyncThunk<
  IGross[],
  number,
  { state: RootState }
>("services/getGross", async (operator, thunkAPI) => {
  const result = await endpoints.directory.getGross(
    {
      action: ApiAction.getGross,
      operator,
    },
    thunkAPI.signal,
  );

  return result.gross;
});
