import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IDirectoryState } from "../directorySlice";
import { fetchOperators } from "../thunks/fetchOperators";

export const addFetchOperators = (builder: ActionReducerMapBuilder<IDirectoryState>) => {
  builder.addCase(fetchOperators.pending, (state) => {
    state.loadingKeys = [...state.loadingKeys, "operators"];
  });
  builder.addCase(fetchOperators.rejected, (state) => {
    state.loadingKeys = [...state.loadingKeys.filter((i) => i !== "operators")];
  });
  builder.addCase(fetchOperators.fulfilled, (state, action) => {
    state.operators = [...action.payload];
    state.loadingKeys = [...state.loadingKeys.filter((i) => i !== "operators")];
  });
};
