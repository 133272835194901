import { Grid } from "@mui/material";
import React from "react";
import { IHotelExtended } from "../../../api/types/directory/IHotel";
import { IOperator } from "../../../api/types/directory/IOperator";
import { RowStyled } from "../../../components/columns/Row.styled";
import { AssociateType } from "../../../components/filter/types/AssociateType";
import { ControlledSelectList } from "../../../components/selectList/ControlledSelectList";
import { useAppSelector } from "../../../store/hooks";
import { AssociateFieldEmptyState } from "./AssociateFieldEmptyState";
import { HotelEditor } from "./HotelEditor";

const tez: IOperator = { id: 10, name: "TezTour" };
const stl = { marginBottom: "auto", paddingBottom: "60px" };
export const AssociateEditor = ({ type }: { type: AssociateType }) => {
  const operators = useAppSelector((s) => s.associate.operators);

  const hotelToEdit = useAppSelector((s) => {
    if (type !== AssociateType.hotels) return undefined;
    const targets = s.associate.tezTargets;
    const count = targets.length;
    if (count < 1) return undefined;
    return s.associate.tezTargets[count - 1] as IHotelExtended;
  });

  const isHotels = type === AssociateType.hotels;

  const country = useAppSelector((s) => s.associate.country);

  const filterSelected = operators.length > 0 && country !== null;

  if (!filterSelected) return <AssociateFieldEmptyState />;
  return (
    <>
      {isHotels ? <HotelEditor hotel={hotelToEdit} /> : null}
      <Grid item xs={isHotels ? 10 : 12} sx={stl}>
        <RowStyled>
          <ControlledSelectList
            operatorID={tez.id}
            operatorName={tez.name}
            country={country}
            type={type}
          />

          {operators.map((item) => (
            <ControlledSelectList
              key={`operator.${item.id}`}
              operatorID={item.id}
              operatorName={item.name}
              country={country}
              type={type}
            />
          ))}
        </RowStyled>
      </Grid>
    </>
  );
};
