import { TableRow } from "@mui/material";
import React from "react";
import { IReportInfo } from "../../../api/types/reports/IReportInfo";
import { StyledTableCell } from "../../../components/styled/TableCell.styled";
import { dateToComponents } from "../../../components/template/row/utils/DateToComponents";
import { truncateString } from "../../../components/template/row/utils/TruncateString";
import { templateTypeDataSource } from "../../../constants/TemplateTypeDataSource";
import { useAppSelector } from "../../../store/hooks";
import { ReportRowControlButtons } from "../common/ReportRowControlButtons";

interface Params {
  info: IReportInfo;
}
export function ReportTemplateRow({ info }: Params) {
  const operators = useAppSelector((s) => {
    const ids = info.operators.split(",").map((id) => parseInt(id, 10));
    return (
      s.directory.operators
        ?.filter((o) => ids.includes(o.id))
        .map((o) => o.name) ?? []
    );
  });

  const disabled = useAppSelector((s) =>
    s.reports.loadingReportKeys.includes(`${info.id}`),
  );

  return (
    <TableRow hover sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <StyledTableCell sx={{ minWidth: "150px" }}>
        <strong>{info.name}</strong> <br />
        <small>{info.fullname}</small> <br />
        <small>
          {templateTypeDataSource.find((itm) => itm.id == info.type)?.name ??
            "Авиа"}
        </small>
        <br />
        <small>ID: {info.id}</small>
      </StyledTableCell>
      <StyledTableCell>{info.country_string}</StyledTableCell>
      <StyledTableCell>{info.city_from_string}</StyledTableCell>
      <StyledTableCell>{operators.join(", ")}</StyledTableCell>
      <StyledTableCell>
        <small>
          <strong>Ночей:</strong>{" "}
          {truncateString(info.nights.split(",").join(", "), 50)}; <br />
          <strong>Даты:</strong>{" "}
          {truncateString(info.dates.split(",").join(", "), 50)}
        </small>
      </StyledTableCell>
      <StyledTableCell>
        <small>
          {dateToComponents(info.last_update)[0]}
          <br />
          {dateToComponents(info.last_update)[1]}
          <br />
          ---
          <br />
          {dateToComponents(info.next_date)[0]}
          <br />
          {dateToComponents(info.next_date)[1]}
        </small>
      </StyledTableCell>
      <StyledTableCell align={"right"}>
        <ReportRowControlButtons
          disabled={disabled}
          isStopped={true}
          isTemplate={true}
          info={info}
        />
      </StyledTableCell>
    </TableRow>
  );
}
