import { createJobFromTemplate } from "../thunks/createJobFromTemplate";
import { IActionErrorInfo } from "../types/IActionErrorInfo";
import { JobsExtraBuilder } from "./types/jobsExtraBuilder";

export const addCreateJobFromTemplate = (builder: JobsExtraBuilder) => {
  builder.addCase(createJobFromTemplate.pending, (state) => {
    state.jobCreating = true;
  });
  builder.addCase(createJobFromTemplate.rejected, (state, action) => {
    state.jobCreating = false;
    const error: IActionErrorInfo = {
      id: Date.now(),
      message: `Ошибка Запуска задания! "${action.meta.arg.name}": ${action.error.message}`,
    };
    state.jobErrors = state.jobErrors ? [...state.jobErrors, error] : [error];
  });
  builder.addCase(createJobFromTemplate.fulfilled, (state, action) => {
    state.jobCreating = false;
    if (action.payload.jobID) {
      state.jobsUpdated = [
        ...state.jobsUpdated,
        {
          id: action.payload.jobID,
          message: `Задача из "${action.meta.arg.name}" создана`,
        },
      ];
    }
  });
};
