import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AssociateType } from "../../../components/filter/types/AssociateType";
import { fetchAssociateRoomTypes } from "../thunks/fetchAssociateRoomTypes";
import { IAssociateState } from "../types/IAssociateState";
import { selectMatchingItems } from "../utils/selectMatchingItems";
import { updateSuggestedOnFetchComplete } from "./utils/updateSuggestedOnFetchComplete";

export const addFetchAssociateRoomTypes = (builder: ActionReducerMapBuilder<IAssociateState>) => {
  builder.addCase(fetchAssociateRoomTypes.pending, (state, action) => {
    state.loadingKeys = [...state.loadingKeys, `${action.meta.arg.operator}`];
    state.rooms[action.meta.arg.operator] = [];
  });
  builder.addCase(fetchAssociateRoomTypes.rejected, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `${action.meta.arg.operator}`);
  });
  builder.addCase(fetchAssociateRoomTypes.fulfilled, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `${action.meta.arg.operator}`);
    state.rooms[action.meta.arg.operator] = [...action.payload];
    if (action.meta.arg.noSuggestions !== true) {
      selectMatchingItems(
        state.operators.map((itm) => itm.id),
        state,
        state.tezTargets,
        action.meta.arg.operator,
        AssociateType.roomTypes,
      );
      updateSuggestedOnFetchComplete(state, action.meta.arg.operator, action.payload);
    }
  });
};
