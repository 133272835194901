import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IActionErrorInfo } from "../../jobs/types/IActionErrorInfo";
import { deleteReport } from "../thunks/deleteReport";
import { IReportsState } from "../types/IReportsState";

function removeLoadingKey(key: string, state: IReportsState) {
  if (state.loadingReportKeys) {
    state.loadingReportKeys = state.loadingReportKeys.filter((id) => id != key);
  }
}
export const addDeleteReport = (
  builder: ActionReducerMapBuilder<IReportsState>,
) => {
  builder.addCase(deleteReport.pending, (state, action) => {
    state.loadingReportKeys = [
      ...state.loadingReportKeys,
      `${action.meta.arg.id}`,
    ];
  });
  builder.addCase(deleteReport.rejected, (state, action) => {
    removeLoadingKey(`${action.meta.arg.id}`, state);

    const error: IActionErrorInfo = {
      id: Date.now(),
      message: `Ошибка удаления шаблона/задачи ID: ${action.meta.arg.id}!: ${action.error.message}`,
    };
    state.errors = state.errors ? [...state.errors, error] : [error];
  });
  builder.addCase(deleteReport.fulfilled, (state, action) => {
    removeLoadingKey(`${action.meta.arg.id}`, state);
    state.reports = state.reports.filter((j) => j.id !== action.meta.arg.id);
    state.templates = state.templates.filter(
      (j) => j.id !== action.meta.arg.id,
    );
  });
};
