import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import { AppDispatch } from "../../appDispatch";
import { RootState } from "../../rootState";
import { fetchUser } from "./fetchUser";

export const deleteUser = createAsyncThunk<
  number,
  number,
  { state: RootState; dispatch: AppDispatch }
>("user/delete", async (param, thunkAPI) => {
  const result = await endpoints.user.delete(param, thunkAPI.signal);
  if (result.status === "OK") return param;
  else {
    const state = thunkAPI.getState();
    const userID = state.user?.info?.id;
    if (userID) {
      // Check if our access right are still the same
      thunkAPI.dispatch(fetchUser(userID));
    }
    if (result.error) throw new Error(result.error);
    else throw new Error(`Не удалось удалить пользователя ID:${param}. Неожиданный ответ сервера.`);
  }
});
