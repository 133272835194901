import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useEffect } from "react";
import { IJobActionParams } from "../../api/types/jobs/IJobActionParams";
import { IJobInfo } from "../../api/types/jobs/IJobInfo";
import { IRestartJobParams } from "../../api/types/jobs/IRestartJobParams";
import { JobAction } from "../../api/types/jobs/JobAction";
import { IReportActionParams } from "../../api/types/reports/IReportActionParams";
import { IReportInfo } from "../../api/types/reports/IReportInfo";
import { ReportAction } from "../../api/types/reports/ReportAction";
import { IUser } from "../../api/types/user/IUser";
import { UserAction } from "../../api/types/users/UserAction";
import { UserEditor } from "../../pages/admin/UserEditor";
import { ModifyReportTemplate } from "../../pages/reports/ModifyReportTemplate";
import { ModifyTemplate } from "../../pages/templates/ModifyTemplate";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { deleteJob } from "../../store/jobs/thunks/deleteJob";
import { restartJob } from "../../store/jobs/thunks/restartJob";
import {
  closeAlert,
  closeModal,
  resetModals,
} from "../../store/modals/modalsSlice";
import { IAlertPressAction } from "../../store/modals/types/IAlertPressAction";
import { deleteReport } from "../../store/reports/thunks/deleteReport";
import { deleteUser } from "../../store/user/thunks/deleteUser";
import { EditorMode } from "../template/types/EditorMode";
import { UserEditorMode } from "../template/types/UserEditorMode";
import { titleForMode } from "../template/utils/TitleForMode";

export const ModalProvider = () => {
  const dispatch = useAppDispatch();

  const isLoggedIn = useAppSelector((s) => s.user.isLoggedIn);

  const currentModal = useAppSelector((s) => s.modals.modals.find(() => true));
  const currentAlert = useAppSelector((s) => s.modals.alerts.find(() => true));

  const dismissAlert = () => {
    if (currentAlert) dispatch(closeAlert(currentAlert.id));
  };
  const dismissModal = () => {
    if (currentModal) dispatch(closeModal(currentModal.id));
  };

  const handleAlertAction = (info: IAlertPressAction) => {
    if (info.action === JobAction.deleteJob)
      dispatch(deleteJob(info.params as IJobActionParams));
    if (info.action === JobAction.restartJob)
      dispatch(restartJob(info.params as IRestartJobParams));
    if (info.action === UserAction.deleteUser)
      dispatch(deleteUser(info.params.id as number));
    if (info.action === ReportAction.deleteReport) {
      dispatch(deleteReport(info.params as IReportActionParams));
    }
    if (info.action === ReportAction.generateReport) {
      // TODO: - generate report
    }
  };

  useEffect(() => {
    if (!isLoggedIn) dispatch(resetModals());
  }, [isLoggedIn]);

  return (
    <div>
      <Dialog open={currentAlert !== undefined}>
        <DialogTitle>{currentAlert?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{currentAlert?.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {currentAlert?.actions.map((action, i) => (
            <Button
              key={`action${i}`}
              color={action.kind === "danger" ? "error" : "primary"}
              autoFocus={i === 0}
              onClick={
                action.onPress !== undefined
                  ? () => handleAlertAction(action.onPress as IAlertPressAction)
                  : dismissAlert
              }
            >
              {action.title}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
      <Dialog
        open={currentModal !== undefined}
        fullWidth={true}
        maxWidth={currentModal && currentModal.kind === "user" ? "xs" : "md"}
        onClose={dismissModal}
        scroll={"body"}
      >
        {currentModal ? (
          <DialogTitle id="scroll-dialog-title">
            {titleForMode(currentModal?.type)}
          </DialogTitle>
        ) : null}
        {currentModal &&
        currentModal.kind === "job" &&
        currentModal.info !== undefined ? (
          <ModifyTemplate
            mode={currentModal.type as EditorMode}
            source={currentModal.info as IJobInfo}
            onClose={dismissModal}
          />
        ) : null}
        {currentModal &&
        currentModal.kind === "report" &&
        currentModal.info !== undefined ? (
          <ModifyReportTemplate
            mode={currentModal.type as EditorMode}
            source={currentModal.info as IReportInfo}
            onClose={dismissModal}
          />
        ) : null}
        {currentModal && currentModal.kind === "user" ? (
          <UserEditor
            mode={currentModal.type as UserEditorMode}
            source={currentModal.info as IUser | undefined}
            onClose={dismissModal}
          />
        ) : null}
      </Dialog>
    </div>
  );
};
