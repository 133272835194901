import { NightType } from "../types/NightType";

export const hasWarning = (
  nights: NightType,
  date: string,
  previousData?: Record<string, NightType[]>,
): boolean => {
  if (!previousData) return false;
  const nightRow = previousData[date];
  if (!nightRow) return true;
  return !nightRow.includes(nights);
};
