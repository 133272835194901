import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import { ICreateReportResponse } from "../../../api/types/reports/ICreateReportResponse";
import { IReportParams } from "../../../api/types/reports/IReportParams";

export const createReportTemplate = createAsyncThunk<ICreateReportResponse, IReportParams>(
  "reports/createTemplate",
  async (param, thunkAPI) => {
    const result = await endpoints.report.create(param, thunkAPI.signal);
    console.log("createReportTemplate", result);
    if (result.reportID) {
      return result;
    } else {
      throw new Error(result.error ?? `Не удалось создать шаблон "${param.name}"`);
    }
  },
);
