export enum AssociateType {
  hotels = 1,
  // currency = 2,
  pansions = 3,
  /*
   Типы номеров в отеле
   Похоже сопоставление на Отели, но тут в фильтре еще нужно будет указывать
   отель TEZ, который уже сопоставлен с отелем конкурента и сопоставлять уже
   типы номеров.
   */
  roomTypes = 4,
  /*
   Регионы
   В данном сопоставлении необходимо будет сопоставлять Регионы SPO
   */
  regions = 5,
  /*
   Туры
   В данном сопоставлении необходимо будет сравнивать деревни (резорты),
   которые сформированы в Туры в сопоставлении ниже (Фильтр для туров)
   */
  tours = 6,
  /*
   Фильтр для туров
   В данном сопоставлении мы можем для группы деревень(резортов)
   создать собственное название Тура, например у АБС есть деревни
   Анталия, Алания, Сиде. Я могу объединить их и назвать
   Анатолийское побережье. Данное объединение должно быть сохранено
   в справочнике Туров для дальнейшего сопоставления с TEZ
   */
  createTours = 7,

  createRegions = 8,
  cities = 9,
}

export const titleForAssociateType = (type: AssociateType): string => {
  switch (type) {
    /*case AssociateType.currency:
      return "Валюта"*/
    case AssociateType.hotels:
      return "Отели";
    case AssociateType.roomTypes:
      return "Типы номеров";
    case AssociateType.tours:
      return "Туры (группы регионов)";
    case AssociateType.pansions:
      return "Пансионы";
    case AssociateType.createTours:
      return "Создание туров";
    case AssociateType.regions:
      return "Регионы (группы курортов)";
    case AssociateType.createRegions:
      return "Создание регионов";
    case AssociateType.cities:
      return "Города назначения";
    default:
      return `${type} не поддерживается`;
  }
};
