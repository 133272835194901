/**
 * Converts object of request params to a get string. Ignores nested objects.
 * @param obj
 */
export const paramsObjectToString = (obj: Record<string, unknown>): string => {
  const result: string[] = [];

  for (const [key, value] of Object.entries(obj)) {
    const allowedTypes = ["boolean", "number", "string"];
    if (Array.isArray(value)) {
      const param = value.join(",");
      if (param.length > 0) result.push(`${key}=${param}`);
    } else if (allowedTypes.includes(typeof value)) {
      // if (typeof value === "string" && value.length === 0) continue
      result.push(`${key}=${value}`);
    }
  }

  return encodeURI(result.join("&"));
};
