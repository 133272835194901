import { TableRow } from "@mui/material";
import React from "react";
import { MdClear, MdCreate } from "react-icons/md";
import { IUser } from "../../api/types/user/IUser";
import { UserAction } from "../../api/types/users/UserAction";
import { ControlButtonStyled } from "../../components/styled/ControlButton.styled";
import { ControlButtonContainerStyled } from "../../components/styled/ControlButtonContainer.styled";
import { StyledDangerCellContent, StyledTableCell } from "../../components/styled/TableCell.styled";
import { Color } from "../../constants/colors";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { showAlert, showModal } from "../../store/modals/modalsSlice";
import { adjustColor } from "../../utils/adjustColor";

interface UserRowProps {
  disabled: boolean
  user: IUser
}

export function UserRow({ user, disabled }: UserRowProps) {
  const dispatch = useAppDispatch();
  const isBlocked = user.status === "blocked";

  const isCurrentUser = useAppSelector((s) => s.user?.info?.id === user.id);

  const onEdit = () => {
    dispatch(
      showModal({
        type: "editUser",
        info: user,
        id: user.id,
        kind: "user",
      }),
    );
  };

  const onDelete = () => {
    dispatch(
      showAlert({
        title: `Удалить ${user.username}?`,
        message: `Пользователь ${user.username} будет удален безвозвратно. Это действие нельзя будет отменить.`,
        id: user.id,
        actions: [
          { title: "Отмена", kind: "standard" },
          {
            title: "Удалить",
            kind: "danger",
            onPress: {
              action: UserAction.deleteUser,
              params: {
                action: UserAction.deleteUser,
                id: user.id,
              },
            },
          },
        ],
      }),
    );
  };

  return (
    <TableRow
      hover
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        backgroundColor: isBlocked ? adjustColor(Color.danger, 180) : "transparent",
      }}
    >
      <StyledTableCell>
        <small>ID: {user.id}</small> -{" "}
        <strong>
          {user.username}
          {isCurrentUser ? (
            <sup style={{ color: isCurrentUser ? Color.brand : undefined }}> ВЫ</sup>
          ) : null}
        </strong>
      </StyledTableCell>
      <StyledTableCell>{user.fullname}</StyledTableCell>
      <StyledTableCell>{user.phone}</StyledTableCell>
      <StyledTableCell>{user.email}</StyledTableCell>
      <StyledTableCell>
        <small>{user.type === "admin" ? <strong>Администратор</strong> : "Пользователь"}</small>
      </StyledTableCell>

      <StyledTableCell>
        <small>{user.created}</small>
      </StyledTableCell>
      <StyledTableCell>
        <small>
          {isBlocked ? <StyledDangerCellContent>Заблокирован</StyledDangerCellContent> : "Активный"}
        </small>
      </StyledTableCell>
      <StyledTableCell align={"right"}>
        <ControlButtonContainerStyled>
          <ControlButtonStyled title={"Редактировать"} onClick={onEdit} disabled={disabled}>
            <MdCreate />
          </ControlButtonStyled>

          <ControlButtonStyled
            variant={"danger"}
            title={"Удалить"}
            disabled={disabled || isCurrentUser}
            onClick={onDelete}
          >
            <MdClear />
          </ControlButtonStyled>
        </ControlButtonContainerStyled>
      </StyledTableCell>
    </TableRow>
  );
}
