import { createSlice } from "@reduxjs/toolkit";
import { IHotel } from "../../api/types/directory/IHotel";
import { IOperator } from "../../api/types/directory/IOperator";
import { ITourFilter } from "../../api/types/directory/ITourFilter";
import { IReportData } from "../../api/types/reports/IReportInfo";
import { fetchDirectoryDataForReportInfo } from "../directory/thunks/fetchDirectoryDataForReportInfo";
import { closeModal } from "../modals/modalsSlice";
import { editReportTemplate } from "../reports/thunks/editReportTemplate";

interface IReportDataState {
  data?: IReportData;
  id: number;
}

const initialState: IReportDataState = {
  id: 0,
};

const reportDataSlice = createSlice({
  name: "reportData",
  initialState,
  reducers: {
    resetReportData: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchDirectoryDataForReportInfo.fulfilled,
      (state, action) => {
        const info = action.meta.arg;
        const data: IReportData = {
          ...info,
          sOperators: [],
          sTourFilter: [],
          sHotel: [],
        };
        const operators = action.payload.operators;
        const tours = action.payload.tourFilters;
        const hotels = action.payload.hotels;
        if (operators) {
          data.sOperators = info.operators
            .split(",")
            .map((id) => operators.find((o) => `${o.id}` === id))
            .filter((v) => v !== undefined) as IOperator[];
        }
        if (tours) {
          data.sTourFilter = info.tour_id
            .split(",")
            .map((id) => tours.find((o) => `${o.id}` === id))
            .filter((v) => v !== undefined) as ITourFilter[];
        }
        if (hotels) {
          data.sHotel = info.hotels
            .split(",")
            .map((id) => hotels.find((o) => `${o.id}` === id))
            .filter((v) => v !== undefined) as IHotel[];
        }
        state.data = data;
        state.id = info.id;
      },
    );
    builder.addCase(closeModal, (state, action) => {
      if (state.id == action.payload) {
        state.id = 0;
        state.data = undefined;
      }
    });
    builder.addCase(editReportTemplate.fulfilled, (state, action) => {
      if (state.id == action.meta.arg.id) {
        state.id = 0;
        state.data = undefined;
      }
    });
  },
});

export const { resetReportData } = reportDataSlice.actions;
export default reportDataSlice.reducer;
