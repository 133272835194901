import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import {
  IGetReportsFilter,
  IGetReportsParams,
} from "../../../api/types/reports/IGetReportsParams";
import { IReportInfo } from "../../../api/types/reports/IReportInfo";
import { ReportAction } from "../../../api/types/reports/ReportAction";
import { AppDispatch } from "../../appDispatch";
import { RootState } from "../../rootState";
import { ReportStatus } from "../types/reportStatus";

export const allStatusList = [
  ReportStatus.pending,
  ReportStatus.active,
  ReportStatus.stopped,
  ReportStatus.finished,
];

interface Params {
  filter?: IGetReportsFilter;
  mode: "reports" | "templates";
}
export const getReportList = createAsyncThunk<
  IReportInfo[],
  Params,
  { state: RootState }
>("reports/getList", async (param, thunkAPI) => {
  const state = thunkAPI.getState();
  if (state.user.isLoggedIn === false)
    throw new Error("Время сессии авторизации истекло");
  if (state.user.info === null) return [];
  const currentFilter: IGetReportsFilter = param.filter ?? {
    operator: state.reports.filters.operators,
    status:
      param.mode === "templates"
        ? ReportStatus.template
        : state.reports.filters.status === -1
        ? allStatusList
        : state.reports.filters.status,
  };
  const params: IGetReportsParams = {
    action: ReportAction.getReports,
    ...currentFilter,
  };
  const response = await endpoints.report.list(params, thunkAPI.signal);
  if (response.error) throw response.error;
  if (response.reports) return response.reports;
  else throw new Error("Invalid response from the server.");
});

export const refreshReportList = createAsyncThunk<
  void,
  void,
  { state: RootState; dispatch: AppDispatch }
>("reports/refresh", async (param, thunkAPI) => {
  const state = thunkAPI.getState();
  if (state.modals.modals.length > 0) return;
  console.log("refreshing report list");
  thunkAPI.dispatch(getReportList({ mode: "reports" }));
});
