import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addCreateReportFromTemplate } from "./extraReducers/addCreateReportFromTemplate";
import { addCreateReportTemplate } from "./extraReducers/addCreateReportTemplate";
import { addDeleteReport } from "./extraReducers/addDeleteReport";
import { addEditReportTemplate } from "./extraReducers/addEditReportTemplate";
import { addGetReportList } from "./extraReducers/addGetReportList";
import { initialState } from "./initialState";
import { IReportFilters } from "./types/IReportFilters";

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    resetReportsState: () => initialState,
    resetReportFilters: (state) => {
      state.filters = {
        status: -1,
      };
    },
    clearOperatorReportFilterDependencies: (state) => {
      state.filters.city = undefined;
      state.filters.country = undefined;
    },
    clearCityReportFilterDependencies: (state) => {
      state.filters.country = undefined;
    },
    removeUpdatedReport: (state, action: PayloadAction<number>) => {
      state.updated = state.updated.filter((j) => j.id !== action.payload);
    },
    removeReportError: (state, action: PayloadAction<number>) => {
      state.errors = state.errors.filter((j) => j.id != action.payload);
    },
    setReportFilters: (state, action: PayloadAction<IReportFilters>) => {
      state.filters.city = action.payload.city;
      state.filters.country = action.payload.country;
      state.filters.status = action.payload.status;
      state.filters.operators = action.payload.operators;
    },
  },
  extraReducers: (builder) => {
    addCreateReportTemplate(builder);
    addCreateReportFromTemplate(builder);
    addEditReportTemplate(builder);
    addGetReportList(builder);
    addDeleteReport(builder);
  },
});

export const {
  resetReportsState,
  resetReportFilters,
  clearOperatorReportFilterDependencies,
  clearCityReportFilterDependencies,
  removeUpdatedReport,
  removeReportError,
  setReportFilters,
} = reportsSlice.actions;
export default reportsSlice.reducer;
