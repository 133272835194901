import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IActionErrorInfo } from "../../jobs/types/IActionErrorInfo";
import { getReportList } from "../thunks/getReportList";
import { IReportsState } from "../types/IReportsState";

export const addGetReportList = (
  builder: ActionReducerMapBuilder<IReportsState>,
) => {
  builder.addCase(getReportList.pending, (state) => {
    state.loadingReports = true;
  });
  builder.addCase(getReportList.rejected, (state, action) => {
    state.loadingReports = false;
    if (
      action.error.message !== "Aborted" &&
      action.error.name !== "TypeError"
    ) {
      const error: IActionErrorInfo = {
        id: Date.now(),
        message: `Не удалось получить список ${
          action.meta.arg.mode === "templates" ? "шаблонов" : "отчетов"
        }!: ${action.error.message}`,
      };
      state.errors = [...state.errors, error];
    }
  });
  builder.addCase(getReportList.fulfilled, (state, action) => {
    state.loadingReports = false;
    if (action.meta.arg.mode === "templates") {
      state.templates = [...action.payload];
    }
    if (action.meta.arg.mode === "reports") {
      state.reports = [...action.payload];
    }
  });
};
