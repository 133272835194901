import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import { RootState } from "../../rootState";
import { ApiAction } from "../../../api/ApiAction";
import { MarginNotes } from "../types/marginNotes";

interface MarginNoteParams {
  operator: number;
  country: string;
}

export const fetchMarginNotes = createAsyncThunk<
  MarginNotes[],
  MarginNoteParams,
  { state: RootState }
>(
  "services/editMarginNotes",
  async ({ operator, country }: MarginNoteParams, thunkAPI) => {
    const result = await endpoints.directory.getMarginNotes(
      {
        action: ApiAction.getNotes,
        operator,
        country,
      },
      thunkAPI.signal,
    );

    return result.notes;
  },
);
