import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSessionToken, getUserInfo } from "../../api/api";
import { IUser } from "../../api/types/user/IUser";
import { IActionErrorInfo } from "../jobs/types/IActionErrorInfo";
import { createUser } from "./thunks/createUser";
import { deleteUser } from "./thunks/deleteUser";
import { editUser } from "./thunks/editUser";
import { fetchUser } from "./thunks/fetchUser";
import { fetchUsers } from "./thunks/fetchUsers";
import { loginUser } from "./thunks/loginUser";
import { logoutUser } from "./thunks/logoutUser";

interface ICredentials {
  username: string;
  password: string;
}

interface IUserState {
  isLoggedIn: boolean;
  username?: string;
  password?: string;
  info: IUser | null;
  users: IUser[];
  error?: string;
  isLoading: boolean;
  loadingKeys: number[];
  errors: IActionErrorInfo[];
  usersUpdated: number[];
}

const initialState: IUserState = {
  isLoggedIn: getSessionToken() !== null,
  users: [],
  isLoading: false,
  info: getUserInfo(),
  loadingKeys: [],
  errors: [],
  usersUpdated: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUsername: (state, action: PayloadAction<ICredentials>) => {
      state.username = action.payload.username;
      state.password = action.payload.password;
    },
    resetLoginError: (state) => {
      state.error = undefined;
    },
    triggerUserLogout: (state) => {
      state.isLoading = false;
      state.isLoggedIn = false;
      state.info = null;
      state.error = undefined;
      state.users = [];
      state.password = undefined;
      state.usersUpdated = [];
    },
    removeUserError: (state, action: PayloadAction<number>) => {
      state.errors = state.errors.filter((j) => j.id != action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.error = undefined;
      state.isLoading = true;
    });
    builder.addCase(loginUser.rejected, (state) => {
      state.isLoading = false;
      state.error = `Неверные имя пользователя и/или пароль!`;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = undefined;
      state.isLoggedIn = true;
      state.info = { ...action.payload };
      state.username = action.meta.arg.account;
      state.password = action.meta.arg.password;
    });

    builder.addCase(logoutUser.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(logoutUser.rejected, (state, action) => {
      state.isLoading = false;
      state.errors.push({
        id: Date.now(),
        message: `Не удалось выйти из аккаунта! Ошибка: ${action.error.message}`,
      });
    });
    builder.addCase(logoutUser.fulfilled, (state) => {
      state.isLoading = false;
      state.isLoggedIn = false;
      state.info = null;
      state.error = undefined;
      state.errors = [];
      state.users = [];
      state.password = undefined;
      state.usersUpdated = [];
    });

    // userList
    builder.addCase(fetchUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUsers.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.users = [...action.payload.users];
      state.usersUpdated = [];
    });

    // Delete user
    builder.addCase(deleteUser.pending, (state, action) => {
      state.loadingKeys = [...state.loadingKeys, action.meta.arg];
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.loadingKeys = state.loadingKeys.filter((id) => id !== action.meta.arg);
      state.errors = [
        ...state.errors,
        {
          id: action.meta.arg,
          message:
            action.error?.message ?? `Не удалось удалить пользователя ID: ${action.meta.arg}`,
        },
      ];
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.loadingKeys = state.loadingKeys.filter((id) => id !== action.meta.arg);
      state.users = state.users.filter((user) => user.id !== action.payload);
    });

    builder.addCase(fetchUser.fulfilled, (state, action) => {
      if (action.payload.user) state.info = action.payload.user;
    });

    // EDIT
    builder.addCase(editUser.pending, (state, action) => {
      state.isLoading = true;
      state.loadingKeys = [...state.loadingKeys, action.meta.arg.id];
    });
    builder.addCase(editUser.rejected, (state, action) => {
      state.loadingKeys = state.loadingKeys.filter((id) => id !== action.meta.arg.id);
      state.isLoading = false;
      state.errors = [
        ...state.errors,
        {
          id: action.meta.arg.id,
          message: `Не удалось сохранить изменения для прользователя ID:${action.meta.arg.id}. ${action.error.message}`,
        },
      ];
    });
    builder.addCase(editUser.fulfilled, (state, action) => {
      state.loadingKeys = state.loadingKeys.filter((id) => id !== action.meta.arg.id);
      state.isLoading = false;
      if (action.payload.status === "OK")
        state.usersUpdated = [...state.usersUpdated, action.meta.arg.id];
      else
        state.errors = [
          ...state.errors,
          {
            id: action.meta.arg.id,
            message: `Не удалось сохранить изменения для прользователя ID:${action.meta.arg.id}. ${action.payload.error}`,
          },
        ];
    });

    // Create
    builder.addCase(createUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = [
        ...state.errors,
        { id: -1, message: `Не удалось создать пользователя: ${action.error.message}` },
      ];
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.id) {
        state.usersUpdated = [...state.usersUpdated, action.payload.id];
      } else {
        state.errors = [
          ...state.errors,
          { id: -1, message: `Не удалось создать пользователя: ${action.payload.error}` },
        ];
      }
    });
  },
});

export const { setUsername, resetLoginError, triggerUserLogout, removeUserError } =
  userSlice.actions;
export default userSlice.reducer;
