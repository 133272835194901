import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IDirectoryState } from "../directorySlice";
import { fetchDestinationResorts } from "../thunks/fetchDestinationResorts";

export const addFetchDestinationResorts = (builder: ActionReducerMapBuilder<IDirectoryState>) => {
  builder.addCase(fetchDestinationResorts.pending, (state) => {
    state.loadingKeys = [...state.loadingKeys, "resorts"];
  });
  builder.addCase(fetchDestinationResorts.rejected, (state) => {
    state.loadingKeys = [...state.loadingKeys.filter((i) => i !== "resorts")];
  });
  builder.addCase(fetchDestinationResorts.fulfilled, (state, action) => {
    state.resorts = [...action.payload];
    state.loadingKeys = [...state.loadingKeys.filter((i) => i !== "resorts")];
  });
};
