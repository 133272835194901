import {
  Container,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { BiSelectMultiple } from "react-icons/bi";
import { TbBoxMultiple } from "react-icons/tb";
import { MdClear } from "react-icons/md";
import { ControlButtonStyled } from "../../../../components/styled/ControlButton.styled";
import { ControlButtonContainerStyled } from "../../../../components/styled/ControlButtonContainer.styled";
import { StyledTableCell } from "../../../../components/styled/TableCell.styled";
import { fetchOperators } from "../../../../store/directory/thunks/fetchOperators";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  setServiceDestinationCountry,
  updateMarginNote,
} from "../../../../store/services/servicesSlice";
import { editMarginNotes } from "../../../../store/services/thunks/editMarginNotes";
import { fetchMarginNotes } from "../../../../store/services/thunks/fetchMarginNotes";
import { fetchServiceDestinationCountries } from "../../../../store/services/thunks/fetchServiceDestinationCountries";
import { MarginNotes } from "../../../../store/services/types/marginNotes";
import { AssociateFieldEmptyState } from "../../associate/AssociateFieldEmptyState";
import { MarginNoteFilter } from "./MarginNoteFilter";

export const MarginEditor = () => {
  const dispatch = useAppDispatch();
  const operator = useAppSelector((s) => s.services.selection.operator);

  const country = useAppSelector((s) => s.services.selection.country);

  const marginNotes = useAppSelector((s) => s.services.marginNotes);

  const resetOperatorDeps = () => {
    dispatch(setServiceDestinationCountry(null));
  };

  useEffect(() => {
    const token = dispatch(fetchOperators());
    return () => token.abort();
  }, []);

  useEffect(() => {
    resetOperatorDeps();
    if (operator) {
      const token = dispatch(
        fetchServiceDestinationCountries({
          operator: operator.id,
        }),
      );
      return () => token.abort();
    }
  }, [operator]);

  useEffect(() => {
    if (operator && country) {
      dispatch(
        fetchMarginNotes({
          operator: operator.id,
          country: country.id,
        }),
      );
    }
  }, [operator, country]);

  const handleOnChangeRate = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    id: string,
  ) => {
    const marginNote = marginNotes.find((el) => el.id === id) as MarginNotes;

    dispatch(
      updateMarginNote({
        ...marginNote,
        rate: event.target.value,
      }),
    );
  };
  const handleOnBlurRate = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    id: string,
  ) => {
    const value = event.target.value;
    const marginNote = marginNotes.find((el) => el.id === id) as MarginNotes;

    dispatch(
      editMarginNotes({
        ...marginNote,
        rate: !event.target.value.length ? "0" : value,
      }),
    );
  };

  const handleIgnoreCheck = (id: string) => {
    const marginNote = marginNotes.find((el) => el.id === id) as MarginNotes;
    const active = marginNote.active === "0" ? "1" : "0";
    dispatch(editMarginNotes({ ...marginNote, active }));
  };
  const handleRemoveNote = (id: string) => {
    const marginNote = marginNotes.find((el) => el.id === id) as MarginNotes;
    dispatch(editMarginNotes({ ...marginNote, rate: "0" }));
  };

  const filterSelected = operator && country;

  return (
    <Container maxWidth="xl" component={"div"}>
      <Grid container spacing={1} rowSpacing={2} alignItems={"flex-end"}>
        <Grid item xs={12}>
          <h2>Наценки</h2>
        </Grid>
        <Grid item xs={12}>
          <MarginNoteFilter />
        </Grid>

        <Grid item xs={12}>
          {filterSelected ? (
            <TableContainer component={Paper} sx={{ marginTop: "24px" }}>
              <Table sx={{ minWidth: 650 }} size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Примечания</StyledTableCell>
                    <StyledTableCell>Берем\Игнор</StyledTableCell>
                    <StyledTableCell>Стоимость</StyledTableCell>
                    <StyledTableCell align={"right"}>
                      Управление
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {marginNotes.length === 0 && (
                    <TableRow>
                      <StyledTableCell colSpan={6} align={"center"}>
                        Ничего не найдено
                      </StyledTableCell>
                    </TableRow>
                  )}
                  {marginNotes.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <StyledTableCell>
                        <span>{row.text}</span>
                      </StyledTableCell>
                      <StyledTableCell>
                        <IconButton onClick={() => handleIgnoreCheck(row.id)}>
                          {row.active === "1" ? (
                            <BiSelectMultiple />
                          ) : (
                            <TbBoxMultiple />
                          )}
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          size="small"
                          type="number"
                          variant="standard"
                          value={row.rate}
                          onChange={(event) =>
                            handleOnChangeRate(event, row.id)
                          }
                          onBlur={(event) => {
                            handleOnBlurRate(event, row.id);
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align={"right"}>
                        <ControlButtonContainerStyled>
                          <ControlButtonStyled
                            variant={"danger"}
                            title={"Удалить"}
                            onClick={() => handleRemoveNote(row.id)}
                          >
                            <MdClear />
                          </ControlButtonStyled>
                        </ControlButtonContainerStyled>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <AssociateFieldEmptyState />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
