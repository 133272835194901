import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch } from "../../appDispatch";
import { RootState } from "../../rootState";
import { fetchUser } from "./fetchUser";

export const refreshUserInfo = createAsyncThunk<
  void,
  void,
  { state: RootState; dispatch: AppDispatch }
>("user/refresh", async (param, thunkAPI) => {
  const state = thunkAPI.getState();
  const userID = state.user.info?.id;
  if (!userID) return;
  thunkAPI.dispatch(fetchUser(userID));
});
