import { IconButton, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { ChangeEvent } from "react";
import { MdRemove } from "react-icons/md";
import { IOperator } from "../../../../api/types/directory/IOperator";
import { GrossData, KEYS } from "./types";

const RenderOperatorRows: React.FC<Props> = ({
  grossData,
  onChange,
  onRemove,
  operator,
}) => {
  const { data, ids } = grossData[operator.id] ?? {};

  if (!data || !ids) {
    return null;
  }

  return (
    <>
      {ids.map((grossId) => {
        const { keywords, rate } = data[grossId];

        return (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1.5fr 0.5fr auto",
              columnGap: 1,
            }}
            key={grossId}
          >
            <TextField
              label="Ключевые слова"
              variant="filled"
              size="small"
              value={keywords ?? ""}
              onChange={(e) => onChange(e, operator.id, grossId, KEYS.KEYWORD)}
            />
            <TextField
              label="Брутто"
              variant="filled"
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              size="small"
              value={rate ?? ""}
              onChange={(e) => onChange(e, operator.id, grossId, KEYS.RATE)}
              required
            />

            <IconButton
              onClick={() => onRemove(operator.id, grossId)}
              color="primary"
              sx={{ alignSelf: "center" }}
              size="medium"
              title="Удалить строку"
            >
              <MdRemove />
            </IconButton>
          </Box>
        );
      })}
    </>
  );
};

type Event = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
type Props = {
  grossData: GrossData;
  onChange: (e: Event, operatorId: number, grossId: string, key: KEYS) => void;
  onRemove: (operatorId: number, grossId: string) => void;
  operator: IOperator;
};

export default RenderOperatorRows;
