import axios, { AxiosResponse } from "axios";
import {
  postLogoutEvent,
  postUserStatusEvent,
} from "../publishers/AuthStatusPublisher";
import { apiPath, baseURL } from "./baseURL";
import { IUser } from "./types/user/IUser";
import { paramsObjectToString } from "./utils/paramsObjectToString";

const API = axios.create({
  baseURL,
  timeout: 20000,
  headers: { "X-Client-Version": "0.1" },
});

let sessionToken: string | undefined;
const TOKEN_KEY = "token";
const USER_KEY = "user";

let userInfo: IUser | undefined;

export const getSessionToken = (): string | null => {
  if (sessionToken) return sessionToken;
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) sessionToken = token;
  return token;
};

export const getUserInfo = (): IUser | null => {
  if (userInfo) return userInfo;
  const user = localStorage.getItem(USER_KEY);
  try {
    if (user) {
      userInfo = JSON.parse(user) as IUser;
      return userInfo;
    } else {
      return null;
    }
  } catch (e) {
    console.log("Failed to get userInfo", e);
    return null;
  }
};

export const saveSessionToken = (token: string, user: IUser) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(USER_KEY, JSON.stringify(user, null, 0));
  sessionToken = token;
  userInfo = user;
};

export const removeSessionToken = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER_KEY);
  sessionToken = undefined;
  postLogoutEvent();
};

const handleErrorStatus = (code: number) => {
  if (code === 401) {
    removeSessionToken();
  }
  if (code === 403) {
    postUserStatusEvent();
  }
};

API.interceptors.request.use(
  (config) => {
    // Add auth headers
    const token = getSessionToken();
    if (token) {
      config.headers["Session"] = token;
    }

    return config;
  },
  (error) => {
    // Handle on logout
    handleErrorStatus(error.response.status);
  },
);

API.interceptors.response.use(
  (response) => response,
  (error) => {
    handleErrorStatus(error.response.status);
  },
);

export async function tezPOST<Type>(
  path: string,
  data: Record<string, unknown>,
  signal?: AbortSignal,
): Promise<Type> {
  const response: AxiosResponse<Type> = await API.post(
    `${apiPath}${path}`,
    { ...data },
    {
      signal,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
  return response.data;
}

export async function tezGET<Type>(
  params: Record<string, unknown>,
  signal?: AbortSignal,
): Promise<Type> {
  const url = `${apiPath}?${paramsObjectToString(params)}`;
  const response: AxiosResponse<Type> = await API.get(url, { signal });
  return response.data;
}

export async function tezDownload(
  params: Record<string, unknown>,
  signal?: AbortSignal,
): Promise<void> {
  const url = `${apiPath}?${paramsObjectToString(params)}`;
  const response: AxiosResponse = await API.get(url, {
    signal,
    responseType: "blob",
  });
  if (!response.data) {
    throw new Error(`no server response when downloading file ${url}`);
  }

  const fileURL = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = fileURL;
  const disposition = response.headers["Content-Disposition"];
  const components = disposition.split("filename=");
  const originalFileName =
    components.length > 1 ? components[components.length - 1] : "report.csv";
  console.log("filename:", originalFileName);
  link.setAttribute("download", originalFileName);
  document.body.appendChild(link);
  link.click();
}

export async function tezPUT<Type>(
  params: Record<string, unknown>,
  signal?: AbortSignal,
): Promise<Type> {
  const response: AxiosResponse<Type> = await API.put(apiPath, params, {
    signal,
  });
  return response.data;
}

export async function tezDELETE<Type>(
  params: Record<string, unknown>,
  signal?: AbortSignal,
): Promise<Type> {
  const response: AxiosResponse<Type> = await API.delete(apiPath, {
    data: params,
    signal,
  });
  return response.data;
}
