import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiAction } from "../../../api/ApiAction";
import { endpoints } from "../../../api/endpoints";
import { ITourFilter } from "../../../api/types/directory/ITourFilter";

interface Params {
  operator: number | string;
  country: number | string;
  getToursByTez?: boolean; // Possibly not needed
}
export const fetchTourFilters = createAsyncThunk<ITourFilter[], Params>(
  "directory/tourFilters",
  async (params, thunkAPI) => {
    const result = await endpoints.directory.get(
      {
        action: params.getToursByTez
          ? ApiAction.getToursByTez
          : ApiAction.getTourFilters,
        operator: params.operator,
        country: params.country,
      },
      thunkAPI.signal,
    );

    return result as ITourFilter[];
  },
);
