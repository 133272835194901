import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { ColumnBodyStyled } from "../../../../components/columns/ColumnBodyStyled";
import {
  SpacedContainerStyled,
  SpacedFixedContainerStyled,
} from "../../../../components/styled/SpacedContainerStyled";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { GROSS_KEY } from "../../../../store/services/extraReducers/addFetchGross";
import {
  resetGrossData,
  setGrossOperators,
} from "../../../../store/services/servicesSlice";
import { createGross } from "../../../../store/services/thunks/createGross";
import { deleteGross } from "../../../../store/services/thunks/deleteGross";
import { editGross } from "../../../../store/services/thunks/editGross";
import { IGross } from "../../../../store/services/types/gross";
import RenderOperatorRows from "./RenderOperatorRows";
import { GrossData, KEYS } from "./types";

const getRandomNumber = () => `${Math.floor(Math.random() * 90000)}`;

const GrossContent = () => {
  const dispatch = useAppDispatch();

  const [newGrossData, setNewGrossData] = useState<GrossData>({});

  const isLoading = useAppSelector((s) =>
    s.services.loadingKeys.includes(GROSS_KEY),
  );
  const { data: serverGrossData, selectedOperators } = useAppSelector(
    (s) => s.services.gross,
  );

  useEffect(() => {
    const newState = selectedOperators.reduce((state, item) => {
      const operatorId = item.id;
      const defaultId = getRandomNumber();

      if (!state[operatorId]) {
        state[operatorId] = {
          data: {},
          ids: [],
        };
      }

      if (serverGrossData[operatorId]) {
        serverGrossData[operatorId].forEach((itm) => {
          const grossId = itm.id;

          state[operatorId].data[grossId] = itm;
          state[operatorId].ids.push(itm.id);
        });
      } else if (!newGrossData[operatorId]?.data[defaultId]) {
        state[operatorId].data[defaultId] = {
          id: defaultId,
          keywords: "",
          operator_id: operatorId,
          rate: "",
        };
        state[operatorId].ids.push(defaultId);
      }

      return state;
    }, {} as GrossData);

    setNewGrossData((prev) => ({
      ...prev,
      ...newState,
    }));
  }, [serverGrossData, selectedOperators]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    operatorId: number,
    grossId: string,
    key: KEYS,
  ) => {
    const value = e.target.value;

    setNewGrossData((prev) => ({
      ...prev,
      [operatorId]: {
        ...prev[operatorId],
        data: {
          ...prev[operatorId].data,
          [grossId]: {
            ...prev[operatorId].data[grossId],
            [key]: value,
          },
        },
      },
    }));
  };

  const handleAddRow = (operatorId: number) => {
    const index = getRandomNumber();

    const newGross = {
      id: `${index}`,
      keywords: "",
      operator_id: operatorId,
      rate: "",
    };

    setNewGrossData((prev) => ({
      ...prev,
      [operatorId]: {
        data: {
          ...prev[operatorId].data,
          [newGross.id]: newGross,
        },
        ids: [...prev[operatorId].ids, newGross.id],
      },
    }));
  };

  const handleRemoveRow = (operatorId: number, grossId: string) => {
    if (serverGrossData[operatorId]?.find((i) => i.id === grossId)) {
      dispatch(deleteGross(grossId));
    }

    setNewGrossData((prev) => {
      const objProp = `${grossId}`;

      return {
        ...prev,
        [operatorId]: {
          data: (({ [objProp]: prop, ...object }) => object)(
            prev[operatorId].data,
          ),
          ids: prev[operatorId].ids.filter((item) => item !== grossId),
        },
      };
    });
  };

  const renderInputs = () =>
    selectedOperators.map((operator) => (
      <ColumnBodyStyled minHeight={64} marginBottom={8} key={operator.id}>
        <h3>{`${operator.name} [${operator.id}]`}</h3>

        <RenderOperatorRows
          grossData={newGrossData}
          onChange={handleInputChange}
          onRemove={handleRemoveRow}
          operator={operator}
        />

        <SpacedContainerStyled>
          <Button
            variant="text"
            onClick={() => handleAddRow(operator.id)}
            size="small"
          >
            Добавить строку
          </Button>
        </SpacedContainerStyled>
      </ColumnBodyStyled>
    ));

  const saveGross = () => {
    const resetFunc = () => {
      setNewGrossData({});
      dispatch(setGrossOperators([]));
      dispatch(resetGrossData());
    };

    selectedOperators.forEach((operator) => {
      const grossIdsMap = serverGrossData[operator.id]?.reduce((map, i) => {
        map[i.id] = i;
        return map;
      }, {} as { [id: string]: IGross });

      newGrossData[operator.id].ids.forEach((grossId) => {
        const oldData = grossIdsMap?.[grossId];
        const newData = newGrossData[operator.id].data[grossId];

        if (!oldData && (newData.keywords || newData.rate)) {
          dispatch(createGross(newData));
          resetFunc();
        } else if (
          oldData &&
          (oldData.keywords !== newData.keywords ||
            oldData.rate !== newData.rate)
        ) {
          dispatch(editGross(newData));
          resetFunc();
        }
      });
    });
  };

  const renderBottomButton = () => {
    return (
      <SpacedFixedContainerStyled>
        <LoadingButton
          loading={isLoading}
          disabled={isLoading}
          variant="contained"
          size="small"
          onClick={saveGross}
        >
          Сохранить
        </LoadingButton>
      </SpacedFixedContainerStyled>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          columnGap: 1,
          marginTop: 2,
        }}
      >
        {renderInputs()}
      </Box>

      {renderBottomButton()}
    </>
  );
};

export default GrossContent;
