import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IDirectoryState } from "../directorySlice";
import { fetchTourFilters } from "../thunks/fetchTourFilters";

export const addFetchTourFilters = (builder: ActionReducerMapBuilder<IDirectoryState>) => {
  builder.addCase(fetchTourFilters.pending, (state) => {
    state.loadingKeys = [...state.loadingKeys, "tourFilters"];
  });
  builder.addCase(fetchTourFilters.rejected, (state) => {
    state.loadingKeys = [...state.loadingKeys.filter((i) => i !== "tourFilters")];
  });
  builder.addCase(fetchTourFilters.fulfilled, (state, action) => {
    state.tourFilters = [...action.payload];
    state.loadingKeys = [...state.loadingKeys.filter((i) => i !== "tourFilters")];
  });
};
