import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import { IGetUserResponse } from "../../../api/types/user/IGetUserResponse";

export const fetchUser = createAsyncThunk<IGetUserResponse, number>(
  "user/fetch",
  async (param, thunkAPI) => {
    return await endpoints.user.get(param, thunkAPI.signal);
  },
);
