import { Button } from "@mui/material";
import React from "react";
import { MdClear } from "react-icons/md";
import DatePicker, { DateObject, Value } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "./css/green.css";
import { DateWrapper } from "./styled/DateWrapper";

interface Props {
  value: DateObject[];
  label: string;
  onChange: (val: DateObject[]) => void;
  required?: boolean;
  loading?: boolean;
  disabled?: boolean;
}

const weekDays = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
const months = [
  "Янв",
  "Фев",
  "Мар",
  "Апр",
  "Май",
  "Июн",
  "Июл",
  "Авг",
  "Сен",
  "Окт",
  "Ноя",
  "Дек",
];

export const MultiDateSelect = ({ label, value, onChange }: Props) => {
  const handleChange = (val: Value) => {
    const updated = val ? (val as DateObject[]) : [];
    onChange(updated);
  };
  return (
    <DateWrapper>
      <DatePicker
        multiple
        weekDays={weekDays}
        weekStartDayIndex={1}
        months={months}
        className="green"
        value={value}
        onChange={handleChange}
        placeholder={label}
        format="DD.MM.YYYY"
        plugins={[<DatePanel key={"datePlugin"} />]}
        containerStyle={{
          display: "flex",
          flexGrow: 1,
          width: "100%",
          border: "none",
        }}
        style={{
          display: "flex",
          width: "97%",
          fontWeight: "400",
          fontSize: "1rem",
          lineHeight: "1.4375em",
          letterSpacing: "0.00938em",
          color: "rgba(0, 0, 0, 0.87)",
          padding: "4px 5px",
          paddingLeft: 0,
          boxSizing: "content-box",
          background: "transparent",
          height: "1.4375em",
          margin: 0,
          border: "none",
          borderRadius: 0,
          borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
        }}
      />
      <Button
        variant={"outlined"}
        size={"medium"}
        onClick={() => handleChange([])}
      >
        <MdClear />
      </Button>
    </DateWrapper>
  );
};
