import { EditorMode } from "../types/EditorMode";
import { UserEditorMode } from "../types/UserEditorMode";

export const titleForMode = (mode: EditorMode | UserEditorMode): string => {
  if (mode === "create") return "Новый шаблон";
  if (mode === "edit") return "Редактировать";
  if (mode === "clone") return "Клонировать";
  if (mode === "editUser") return "Редактировать";
  if (mode === "addUser") return "Новый пользователь";
  if (mode === "view") return "Импортированная задача (только чтение)";
  return "Новый";
};
