import styled from "@emotion/styled";

export const RowStyled = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;

  overflow-x: auto;
`;
