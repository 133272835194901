import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import React, { useState } from "react";

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

import { getSessionToken } from "../../api/api";
import { baseURL } from "../../api/baseURL";
import { SelectField } from "../../components/select/SelectField";
import { templateTypeDataSource } from "../../constants/TemplateTypeDataSource";

// Register the plugin with FilePond
registerPlugin(FilePondPluginFileValidateType);

export const ImportAndManageData = () => {
  const [templateType, setTemplateType] = useState(0);
  return (
    <Container maxWidth="md" component={"div"}>
      <Grid container spacing={1} rowSpacing={2} alignItems={"flex-end"}>
        <Grid item xs={12}>
          <h2>Импорт данных</h2>
        </Grid>
        <Grid item xs={3}>
          <SelectField
            items={templateTypeDataSource}
            required
            label={"Тип"}
            value={templateType}
            onSelect={(val) => setTemplateType(val as number)}
          />
        </Grid>
        <Grid item xs={12}>
          <FilePond
            allowMultiple={true}
            allowRevert={false}
            allowFileTypeValidation={true}
            maxParallelUploads={1}
            instantUpload={false}
            server={`${baseURL}api.php?action=import&type=${templateType}&session=${
              getSessionToken() ?? ""
            }`}
            labelIdle={
              'Перетащите XLSX файл или <span class="filepond--label-action"> Выберите файл</span>'
            }
            checkValidity={true}
            labelFileLoading={"Открываю..."}
            labelFileProcessing={"Выгружаю..."}
            acceptedFileTypes={[
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ]}
            name={"import_file"}
            labelButtonRemoveItem={"Удалить"}
            labelButtonAbortItemProcessing={"Отмена"}
            labelTapToCancel={"Нажмите для отмены"}
            labelFileProcessingComplete={"Готово"}
            labelFileLoadError={"Ошибка"}
            labelFileProcessingError={"Ошибка выгрузки"}
            labelTapToRetry={"Нажмите чтобы повторить"}
            allowReplace={false}
            itemInsertLocation={"before"}
            credits={false}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
