import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../../api/endpoints";
import { ICreateUserParams } from "../../../api/types/user/ICreateUserParams";
import { IUserResponse } from "../../../api/types/user/IUserResponse";

export const createUser = createAsyncThunk<IUserResponse, ICreateUserParams>(
  "user/create",
  async (param, thunkAPI) => {
    return await endpoints.user.create(param, thunkAPI.signal);
  },
);
