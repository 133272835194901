import { TableHead, TableRow } from "@mui/material";
import React from "react";
import { StyledTableCell } from "../../../components/styled/TableCell.styled";

export function ReportsHeader() {
  return (
    <TableHead>
      <TableRow>
        <StyledTableCell>
          Название отчета
          <br />
          <small>Тип</small>
        </StyledTableCell>
        <StyledTableCell>Страна</StyledTableCell>
        <StyledTableCell>Город вылета</StyledTableCell>
        <StyledTableCell>Операторы</StyledTableCell>
        <StyledTableCell>Описание</StyledTableCell>
        <StyledTableCell sx={{ width: "100px" }}>Статус</StyledTableCell>
        <StyledTableCell align={"right"}>Управление</StyledTableCell>
      </TableRow>
    </TableHead>
  );
}
