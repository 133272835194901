import { Alert, CircularProgress, IconButton, TableRow } from "@mui/material";
import React from "react";
import { MdLeaderboard, MdRestartAlt } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import { DateObject } from "react-multi-date-picker";
import { endpoints } from "../../../api/endpoints";
import { IJobInfo } from "../../../api/types/jobs/IJobInfo";
import { JobAction } from "../../../api/types/jobs/JobAction";
import { TemplateListMode } from "../../../pages/types/TemplateListMode";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { showAlert } from "../../../store/modals/modalsSlice";
import { titleForStatus } from "../../filter/utils/titleForStatus";
import { GridCellStyled } from "../../picker/styled/GridCell.styled";
import { GridColumnStyled } from "../../picker/styled/GridColumn.styled";
import { GridPickerContainerStyled } from "../../picker/styled/GridPickerContainer.styled";
import { dateToString } from "../../picker/utils/dateToString";
import { StyledTableCell } from "../../styled/TableCell.styled";
import { InfoCellStyled } from "./styled/InfoCell.styled";

interface Props {
  job: IJobInfo
  mode: TemplateListMode
  colSpan: number
}

export const JobInfoRow = ({ job, mode, colSpan }: Props) => {
  const dispatch = useAppDispatch();

  const id = useAppSelector((s) => s.jobStats.id);
  const stats = useAppSelector((s) => s.jobStats.stats);
  const loading = useAppSelector((s) => s.jobStats.loading);
  const error = useAppSelector((s) => s.jobStats.error);

  const askToRestart = (date: string) => {
    dispatch(
      showAlert({
        title: `Перезапустить?`,
        message: `Сбор данных для ${date} будет начат заново.`,
        id: job.id,
        actions: [
          { title: "Отмена", kind: "standard" },
          {
            title: "Продолжить",
            kind: "standard",
            onPress: {
              action: JobAction.restartJob,
              params: {
                action: JobAction.restartJob,
                id: job.id,
                date,
              },
            },
          },
        ],
      }),
    );
  };

  const canShow = mode === "jobs" || mode === "imported";

  return id == job.id && canShow ? (
    <TableRow>
      <StyledTableCell colSpan={colSpan}>
        {error ? (
          <Alert sx={{ marginBottom: "8px" }} severity="error">
            {error}
          </Alert>
        ) : null}
        <GridPickerContainerStyled>
          <GridColumnStyled>
            <GridCellStyled>
              Ночей{" "}
              <IconButton size={"small"} disabled={true} title={"Перезапустить"}>
                <MdLeaderboard />
              </IconButton>
            </GridCellStyled>
            {job.nights.split(",").map((itm, index) => (
              <GridCellStyled key={`night_cell_${index}`}>{itm}</GridCellStyled>
            ))}
          </GridColumnStyled>
          {job.dates.split(",").map((d, index) => {
            const dObj = new DateObject();
            dObj.setFormat("DD.MM.YYYY");
            const resD = dObj.parse(d);
            return (
              <GridColumnStyled key={`column${d}_${index}`}>
                <GridCellStyled>
                  {dateToString(resD)}

                  <IconButton
                    size={"small"}
                    href={endpoints.job.getCSVLink({
                      action: JobAction.getToursXLSX,
                      job: job.id,
                      operator: job.operator_id,
                      date: d,
                    })}
                    download
                    target={"_blank"}
                    rel="noreferrer"
                    title={"Скачать отчет"}
                    color={"primary"}
                    sx={{ marginLeft: "4px" }}
                  >
                    <RiFileExcel2Fill />
                  </IconButton>
                  <IconButton
                    size={"small"}
                    disabled={mode === "imported"}
                    onClick={() => askToRestart(d)}
                    title={"Перезапустить"}
                  >
                    <MdRestartAlt />
                  </IconButton>
                </GridCellStyled>
                {loading ? <CircularProgress color="inherit" /> : null}
                {!loading &&
                  job.nights.split(",").map((itm, index) => {
                    const stat = stats.find((st) => {
                      const stDateComponents = st.date.split("-");
                      const stDate =
                        stDateComponents[0].length === 4
                          ? stDateComponents.reverse().join(".")
                          : stDateComponents.join(".");
                      return stDate == d && st.nights == itm;
                    });
                    return (
                      <GridCellStyled key={`cell_${index}`}>
                        <InfoCellStyled>
                          {titleForStatus(parseInt(job.status, 10))}
                          <br />({stat?.count ?? "Нет" + " данных"})
                        </InfoCellStyled>
                      </GridCellStyled>
                    );
                  })}
              </GridColumnStyled>
            );
          })}
        </GridPickerContainerStyled>
      </StyledTableCell>
    </TableRow>
  ) : null;
};
