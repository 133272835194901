import { getJobList } from "../thunks/getJobList";
import { IActionErrorInfo } from "../types/IActionErrorInfo";
import { JobsExtraBuilder } from "./types/jobsExtraBuilder";

export const addGetJobList = (builder: JobsExtraBuilder) => {
  builder.addCase(getJobList.pending, (state) => {
    state.loadingJobs = true;
  });
  builder.addCase(getJobList.rejected, (state, action) => {
    state.loadingJobs = false;
    console.log("getJobList.rejected", action.error);
    if (
      action.error.message !== "Aborted" &&
      action.error.name !== "TypeError"
    ) {
      const error: IActionErrorInfo = {
        id: Date.now(),
        message: `Не удалось получить список шаблонов!: ${action.error.message}`,
      };
      state.jobErrors = state.jobErrors ? [...state.jobErrors, error] : [error];
    }
  });
  builder.addCase(getJobList.fulfilled, (state, action) => {
    state.loadingJobs = false;
    state.allJobs = [...action.payload];
  });
};
