import styled from "@emotion/styled";
import { Color } from "../../../constants/colors";

export const GridRowStyled = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  flex-wrap: nowrap;

  border-bottom: 1px solid ${Color.border};
  &:last-child {
    border-bottom: none;
  }
`;
