import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IDirectoryState } from "../directorySlice";
import { DirectoryTarget } from "../thunks/directoryTarget";
import { fetchDestinationCountries } from "../thunks/fetchDestinationCountries";

export const addFetchDestinationCountries = (builder: ActionReducerMapBuilder<IDirectoryState>) => {
  builder.addCase(fetchDestinationCountries.pending, (state) => {
    state.loadingKeys = [...state.loadingKeys, "destinationCountries"];
  });
  builder.addCase(fetchDestinationCountries.rejected, (state) => {
    state.loadingKeys = [...state.loadingKeys.filter((i) => i !== "destinationCountries")];
  });
  builder.addCase(fetchDestinationCountries.fulfilled, (state, action) => {
    if (action.meta.arg.target === DirectoryTarget.filter) {
      state.filterData.destinationCountries = [...action.payload];
    } else if (action.meta.arg.target === DirectoryTarget.associateFilter) {
      state.associateData.countries = [...action.payload];
    } else {
      state.destinationCountries = [...action.payload];
    }

    state.loadingKeys = [...state.loadingKeys.filter((i) => i !== "destinationCountries")];
  });
};
