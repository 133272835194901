import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AssociateType } from "../../../components/filter/types/AssociateType";
import { fetchAssociateHotels } from "../thunks/fetchAssociateHotels";
import { IAssociateState } from "../types/IAssociateState";
import { selectMatchingItems } from "../utils/selectMatchingItems";
import { setMatchingItemFilter } from "../utils/setMatchingItemFilter";
import { updateSuggestedOnFetchComplete } from "./utils/updateSuggestedOnFetchComplete";

export const addFetchAssociateHotels = (builder: ActionReducerMapBuilder<IAssociateState>) => {
  builder.addCase(fetchAssociateHotels.pending, (state, action) => {
    state.loadingKeys = [...state.loadingKeys, `${action.meta.arg.operator}`];
  });
  builder.addCase(fetchAssociateHotels.rejected, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `${action.meta.arg.operator}`);
  });
  builder.addCase(fetchAssociateHotels.fulfilled, (state, action) => {
    state.loadingKeys = state.loadingKeys.filter((itm) => itm !== `${action.meta.arg.operator}`);
    state.hotels[action.meta.arg.operator] = [...action.payload];
    if (action.meta.arg.noSuggestions !== true) {
      selectMatchingItems(
        state.operators.map((itm) => itm.id),
        state,
        state.tezTargets,
        action.meta.arg.operator,
        AssociateType.hotels,
      );
      updateSuggestedOnFetchComplete(state, action.meta.arg.operator, action.payload);
    } else {
      const hotelFilter = state.filterHotel[10];
      const currentFilter = state.filterHotel[action.meta.arg.operator];
      if (hotelFilter && !currentFilter) {
        setMatchingItemFilter(10, hotelFilter, state, "hotel");
      }
    }
  });
};
