import { Alert, Grid } from "@mui/material";
import React from "react";

export const AssociateFieldEmptyState = () => {
  return (
    <Grid item xs={12}>
      <Alert severity={"info"}>Выберите необходимые параметры для редактирования выше</Alert>
    </Grid>
  );
};
