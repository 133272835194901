import { createSlice } from "@reduxjs/toolkit";
import { IJobStats } from "../../api/types/jobs/IJobStats";
import { fetchJobStats } from "./thunks/fetchJobStats";

interface IJobSliceState {
  id: number
  stats: IJobStats[]
  loading: boolean
  error?: string
}

const initialState: IJobSliceState = {
  id: -1,
  stats: [],
  loading: false,
  error: undefined,
};

export const jobStatsSlice = createSlice({
  name: "jobStats",
  initialState,
  reducers: {
    resetJobStats: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchJobStats.pending, (state, action) => {
      state.loading = true;
      state.id = action.meta.arg;
      state.stats = [];
      state.error = undefined;
    });
    builder.addCase(fetchJobStats.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(fetchJobStats.fulfilled, (state, action) => {
      state.loading = false;
      state.error = undefined;
      state.stats = [...action.payload];
    });
  },
});

export const { resetJobStats } = jobStatsSlice.actions;
export default jobStatsSlice.reducer;
