import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch } from "../../appDispatch";
import { RootState } from "../../rootState";
import { resetJobStats } from "../jobStatsSlice";
import { fetchJobStats } from "./fetchJobStats";

export const toggleJobStats = createAsyncThunk<
  void,
  number,
  { state: RootState; dispatch: AppDispatch }
>("jobStats/toggle", async (param, thunkAPI) => {
  const state = thunkAPI.getState();
  if (state.jobStats.id === param) {
    thunkAPI.dispatch(resetJobStats());
  } else {
    thunkAPI.dispatch(fetchJobStats(param));
  }
});
