import styled from "@emotion/styled";
import { ButtonBase } from "@mui/material";
import { Color } from "../../constants/colors";
import { globalRadius } from "../../constants/sizes";
import { adjustColor } from "../../utils/adjustColor";

type ButtonStyles = "danger" | "default" | "light";
interface Props {
  variant?: "danger";
}

const styleToColor = (stl?: ButtonStyles): string => {
  if (stl === "danger") return adjustColor(Color.danger, -35);
  return adjustColor(Color.brand, -28);
};

export const ControlButtonStyled = styled(ButtonBase)<Props>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px;
  min-width: 30px;
  min-height: 30px;
  border-radius: ${globalRadius}px;
  color: ${(props) => styleToColor(props.variant)};
  background-color: ${Color.text.onDark};
  box-sizing: border-box;
  border: 1px solid transparent;

  transition: 0.1s all ease-in-out;

  &:hover,
  &:focus {
    background-color: ${Color.background};
  }

  &:disabled {
    color: ${Color.secondaryBackground};
  }
`;
