import styled from "@emotion/styled";
import { Color } from "../../constants/colors";

export const IconBadgeStyled = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  font-size: 12px;
  background-color: ${Color.border};
  color: ${Color.text.onDark};
  text-align: center;

  transform: translate(30%, -30%);

  z-index: 5;

  padding: 4px;
  svg {
    display: flex;
    margin: 0 !important;
  }
`;
